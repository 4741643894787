export enum FieldType {
    EMAIL = 'email',
    TEXT = 'text',
    NUMBER = 'number',
    RADIO = 'radio',
    CHECKBOX = 'checkbox',
    TEXTAREA = 'textarea',
    PASSWORD = 'password',
    SELECT = 'select',
    FILE = 'file',
    MULTI = 'multi',
    DATE = 'date',
    TIME = 'time',
    DATETIME = 'datetime-local',
    SUBMIT = 'submit',
    BUTTON = 'button',
    NUMBER_WITH_CONTROLS = 'number_with_controls',
    TEL = 'tel',
}

export const FIELD_RADIO_NONE = 'none';
