import { NotificationType } from "Store/Notification/Notification.type"
import { RmaDetailsDataSource, RmaDetailsDataSourceItem, RmaDetailsScreenType } from "../RmaDetails/RmaDetails.type"
import { Customer } from "Query/MyAccount.type"
import { Device } from "Type/Device.type"
import { RmaStatuses } from "../../query/Rma.type"

export enum RmaFormMode { 
  RETURN = 'return',
  complaint = 'complaint' 
}

export type RmaFormDataSource = RmaDetailsDataSource

export type RmaFormFormData = {
  products: Record<string, { requestQty?: number, note?: string, attachments?: RmaFormFormDataAttachment[] }>
  payment: Record<'bank_name'| 'name_surname' | 'account_no', string>
}

export type RmaFormFormDataAttachment = {
  name: string
  base64_encoded_file: string | ArrayBuffer | null
}

export type RmaFormContainerPropsMapState = {
  customer: Partial<Customer>
  device: Device
}

export type RmaFormContainerPropsMapDispatch = {
  showNotification: (type: NotificationType, msg: string) => void
}

export type RmaFormContainerProps = {
  dataSource: RmaFormDataSource | null
  readOnly?: boolean
} & RmaFormContainerPropsMapState & RmaFormContainerPropsMapDispatch

export type RmaFormContainerState = {
  formData: RmaFormFormData
  isLoading: boolean
  mode: RmaFormMode
}

export type RmaFormComponentProps = { 
  dataSource: RmaFormDataSource | null
  device: Device
  formData: RmaFormFormData
  mode: RmaFormMode
  isLoading: boolean
  isSubmitEnabled: boolean
  readOnly?: boolean
  handleEditProductData: (productId: string, type: 'selected' | 'requestQty' | 'note' | 'attachments' | null, value: number | string | boolean | RmaFormFormDataAttachment[] | null) => void
  handleEditPaymentData: (fieldName: 'bank_name' | 'name_surname' | 'account_no', value: string) => void
  handleSwitchMode: (mode: RmaFormMode) => void
  isSelected: (item: RmaDetailsDataSourceItem) => boolean
  onSubmit: () => void
  onBack: () => void
}