import { Reducer } from 'redux';

/**
 * @param store
 * @param reducers
 */
export default function injectToReducers<S, T extends ModifiedReduxStore<S>>(store: T, reducers: Record<string, Reducer>): void {
    Object.keys(reducers).forEach((key) => {
        if (store.asyncReducers && store.injectReducer && !Reflect.has(store.asyncReducers, key)) {
            // eslint-disable-next-line no-param-reassign
            store.asyncReducers[key] = reducers[key];
            store.injectReducer(key, reducers[key]);
        }
    });
}
