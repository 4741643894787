import { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { MyAccountTab } from 'Route/MyAccount/MyAccount.type';
import MyAccountDispatcher from 'Store/MyAccount/MyAccount.dispatcher';
import { ReactElement } from 'Type/Common.type';
import { isSignedIn } from 'Util/Auth/IsSignedIn';
import { noopFn } from 'Util/Common';
import { RootState } from 'Util/Store/Store.type';

import { CONTRACTOR_USERS_TAB_ID } from '../../../packages/contractors-contractor/src/plugin/MyAccount.plugin';
import { CONTRACTOR_DOCS_TAB_ID } from '../../../packages/contractors-docs/src/plugin/MyAccount.plugin';
import { CONTRACTOR_SUBSTRUCTURES_TAB_ID } from '../../../packages/contractors-structures/src/plugin/MyAccount.plugin';
import MyAccountTabList from './MyAccountTabList.component';
import {
    MyAccountTabListComponentProps,
    MyAccountTabListContainerDispatchProps,
    MyAccountTabListContainerFunctions,
    MyAccountTabListContainerMapStateProps,
    MyAccountTabListContainerProps,
    MyAccountTabListContainerState,
} from './MyAccountTabList.type';

/** @namespace PlugAndSell2/Component/MyAccountTabList/Container/mapStateToProps */
export const mapStateToProps = (state: RootState): MyAccountTabListContainerMapStateProps => ({
    customer: state.MyAccountReducer.customer,
});

/** @namespace PlugAndSell2/Component/MyAccountTabList/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch: Dispatch): MyAccountTabListContainerDispatchProps => ({
    logout: () => MyAccountDispatcher.logout(false, true, dispatch),
});

/** @namespace PlugAndSell2/Component/MyAccountTabList/Container */
export class MyAccountTabListContainer extends PureComponent<MyAccountTabListContainerProps, MyAccountTabListContainerState> {
    static defaultProps: Partial<MyAccountTabListContainerProps> = {
        onSignOut: noopFn,
    };

    state: MyAccountTabListContainerState = {
        isContentExpanded: false,
    };

    containerFunctions: MyAccountTabListContainerFunctions = {
        handleLogout: this.handleLogout.bind(this),
        onTabClick: this.onTabClick.bind(this),
        toggleExpandableContent: this.toggleExpandableContent.bind(this),
    };

    containerProps(): Pick<MyAccountTabListComponentProps, 'tabMap' | 'activeTab' | 'isContentExpanded'> {
        const { tabMap, activeTab } = this.props;
        const { isContentExpanded } = this.state;

        return {
            tabMap: this.filterTabs(tabMap),
            activeTab,
            isContentExpanded,
        };
    }

    filterTabs(tabMap: Record<string, MyAccountTab>): Record<string, MyAccountTab> {
        const { customer } = this.props;

        if (!tabMap) {
            return {};
        }

        const contractorTabs = [CONTRACTOR_DOCS_TAB_ID, CONTRACTOR_SUBSTRUCTURES_TAB_ID, CONTRACTOR_USERS_TAB_ID];

        return Object.entries(tabMap)
            .filter(([key]) => !(contractorTabs.includes(key) && !customer.is_b2b))
            .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});
    }

    handleLogout(): void {
        const { onSignOut, logout } = this.props;

        logout();
        onSignOut();
    }

    onTabClick(key: string): void {
        const { changeActiveTab } = this.props;

        if (!isSignedIn()) {
            return;
        }

        this.toggleExpandableContent();
        changeActiveTab(key);
    }

    toggleExpandableContent(): void {
        this.setState(({ isContentExpanded }) => ({ isContentExpanded: !isContentExpanded }));
    }

    render(): ReactElement {
        return <MyAccountTabList {...this.containerProps()} {...this.containerFunctions} />;
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MyAccountTabListContainer);
