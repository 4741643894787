import { AnyAction } from 'redux';

import { PopupPayload, ShowPopupAction } from 'Store/Popup/Popup.type';

export enum OverlayActionType {
    TOGGLE_OVERLAY = 'TOGGLE_OVERLAY',
    HIDE_ACTIVE_OVERLAY = 'HIDE_ACTIVE_OVERLAY',
    HIDE_ACTIVE_POPUP = 'HIDE_ACTIVE_POPUP',
}

export interface ToggleOverlayByKeyAction extends AnyAction {
    type: OverlayActionType.TOGGLE_OVERLAY;
    overlayKey: string;
}

export interface HideActiveOverlayAction extends AnyAction {
    type: OverlayActionType.HIDE_ACTIVE_OVERLAY;
}

export interface HideActivePopupAction extends AnyAction {
    type: OverlayActionType.HIDE_ACTIVE_POPUP;
    payload: boolean;
}

export type OverlayAction = ToggleOverlayByKeyAction | HideActiveOverlayAction | HideActivePopupAction | ShowPopupAction<string, unknown>;

export interface OverlayStore {
    activeOverlay: keyof PopupPayload | '';
    areOtherOverlaysOpen: boolean;
}

declare module 'Util/Store/Store.type' {
    export interface RootState {
        OverlayReducer: OverlayStore;
    }
}
