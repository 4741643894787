import { Reducer } from 'redux';

import { formatOrders } from 'Util/Orders';

import { OrderAction, OrderActionType, OrderStore } from './Order.type';

/** @namespace PlugAndSell2/Store/Order/Reducer/getInitialState */
export const getInitialState = (): OrderStore => ({
    orderList: {},
    isLoading: true,
});

/** @namespace PlugAndSell2/Store/Order/Reducer/OrderReducer */
export const OrderReducer: Reducer<OrderStore, OrderAction> = (state = getInitialState(), action) => {
    const { type } = action;

    switch (type) {
        case OrderActionType.GET_ORDER_LIST: {
            const { orderList, status } = action;
            const { items = [], page_info } = orderList;
            const formattedOrders = formatOrders(items);

            return {
                ...state,
                isLoading: status,
                orderList: { items: formattedOrders, pageInfo: page_info },
            };
        }

        case OrderActionType.SET_ORDER_LOADING_STATUS: {
            const { status } = action;

            return {
                ...state,
                isLoading: status,
            };
        }

        default:
            return state;
    }
};

export default OrderReducer;
