import { Mutation } from '@tilework/opus';
import SourceMyAccountQuery from 'Query/MyAccount.query';
import { CustomerOutput, GQLCreateContractorCustomerInput, GQLCreateContractorInput } from './MyAccount.type';

export class MyAccountQuery { 
  getCreateContractorMutation(customerInput: GQLCreateContractorCustomerInput, contractorInput: GQLCreateContractorInput): Mutation<'createContractor', CustomerOutput> {
    // @ts-ignore
    return new Mutation<'createContractor', CustomerOutput>('createContractor')
        .addArgument('customerInput', 'ContractorCustomerInput!', customerInput)
        .addArgument('contractorInput', 'ContractorInput!', contractorInput)
        .addField(SourceMyAccountQuery._getCustomerField());
  }
}

export default new MyAccountQuery()