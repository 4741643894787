import { PureComponent } from 'react';

import ClickOutside from 'Component/ClickOutside';
import Loader from 'Component/Loader';
import MobilePopup from 'Component/MobilePopup';
import MyAccountConfirmEmail from 'Component/MyAccountConfirmEmail';
import MyAccountCreateAccount from 'Component/MyAccountCreateAccount';
import MyAccountForgotPassword from 'Component/MyAccountForgotPassword';
import MyAccountForgotPasswordSuccess from 'Component/MyAccountForgotPasswordSuccess';
import MyAccountSignIn from 'Component/MyAccountSignIn';
import Overlay from 'Component/Overlay';
import { Children, ReactElement } from 'Type/Common.type';
import { noopFn } from 'Util/Common';

import { CUSTOMER_ACCOUNT_OVERLAY_KEY, MyAccountPageState } from './MyAccountOverlay.config';
import { MyAccountOverlayComponentProps, MyAccountOverlayRenderMap } from './MyAccountOverlay.type';

import './MyAccountOverlay.style';

/** @namespace PlugAndSell2/Component/MyAccountOverlay/Component */
export class MyAccountOverlayComponent<Props extends MyAccountOverlayComponentProps = MyAccountOverlayComponentProps> extends PureComponent<Props> {
    static defaultProps: Partial<MyAccountOverlayComponentProps> = {
        isCheckout: false,
        onSignIn: noopFn,
    };

    renderMap: Record<MyAccountPageState, MyAccountOverlayRenderMap> = {
        [MyAccountPageState.STATE_SIGN_IN]: {
            render: () => this.renderSignIn(),
            title: __('Sign In'),
            icon: 'user',
        },
        [MyAccountPageState.STATE_FORGOT_PASSWORD]: {
            render: () => this.renderForgotPassword(),
            title: __('Forgot Your Password?'),
            icon: 'user',
        },
        [MyAccountPageState.STATE_FORGOT_PASSWORD_SUCCESS]: {
            render: () => this.renderForgotPasswordSuccess(),
            title: __('Forgot Your Password?'),
            icon: 'user',
        },
        [MyAccountPageState.STATE_CREATE_ACCOUNT]: {
            render: () => this.renderCreateAccount(),
            title: __('Create an Account'),
            icon: 'user',
        },
        [MyAccountPageState.STATE_LOGGED_IN]: {
            render: () => null,
        },
        [MyAccountPageState.STATE_CONFIRM_EMAIL]: {
            render: () => this.renderConfirmEmail(),
            title: __('Confirm the email'),
            icon: 'user',
        },
    };

    renderClickOutsideWrapper(children: Children): ReactElement {
        const { isOverlayVisible, onMyAccountOutsideClick } = this.props;

        if (isOverlayVisible) {
            return <ClickOutside onClick={onMyAccountOutsideClick || noopFn}>{children}</ClickOutside>;
        }

        return children;
    }

    renderMyAccountPopup(): ReactElement {
        const { state, isOverlayVisible, hideActiveOverlay } = this.props;
        const { render, title, icon } = this.renderMap[state];

        if (state === MyAccountPageState.STATE_LOGGED_IN) {
            return null;
        }

        return (
            <MobilePopup handleMobilePopup={hideActiveOverlay} isOpen={isOverlayVisible} title={title} icon={icon}>
                {render()}
            </MobilePopup>
        );
    }

    renderMyAccount(): ReactElement {
        const { state } = this.props;
        const { render, title } = this.renderMap[state];

        return (
            <div block="MyAccountOverlay" elem="Action">
                <p block="TypographyHeader" mods={{ variant: 'normal' }}>
                    {title}
                </p>
                {render()}
            </div>
        );
    }

    renderConfirmEmail(): ReactElement {
        const { state, handleSignIn } = this.props;

        return <MyAccountConfirmEmail state={state} handleSignIn={handleSignIn} />;
    }

    renderForgotPassword(): ReactElement {
        const { state, onFormError, handleSignIn, handleCreateAccount, setSignInState, setLoadingState, isCheckout, isOverlayVisible } = this.props;

        return (
            <MyAccountForgotPassword
                state={state}
                onFormError={onFormError}
                handleSignIn={handleSignIn}
                handleCreateAccount={handleCreateAccount}
                setLoadingState={setLoadingState}
                setSignInState={setSignInState}
                isCheckout={isCheckout}
                isOverlayVisible={isOverlayVisible}
            />
        );
    }

    renderForgotPasswordSuccess(): ReactElement {
        const { state, handleSignIn } = this.props;

        return <MyAccountForgotPasswordSuccess state={state} handleSignIn={handleSignIn} />;
    }

    renderCreateAccount(isLandingPage = false): ReactElement {
        const { state, handleSignIn, setSignInState, setLoadingState, onSignIn } = this.props;

        return (
            <MyAccountCreateAccount
                state={state}
                handleSignIn={handleSignIn}
                setLoadingState={setLoadingState}
                setSignInState={setSignInState}
                onSignIn={onSignIn}
                isLandingPage={isLandingPage}
            />
        );
    }

    renderSignIn(): ReactElement {
        const { state, onFormError, handleForgotPassword, handleCreateAccount, isCheckout, setLoadingState, onSignIn } = this.props;

        return (
            <MyAccountSignIn
                state={state}
                onFormError={onFormError}
                handleForgotPassword={handleForgotPassword}
                handleCreateAccount={handleCreateAccount}
                isCheckout={isCheckout}
                setLoadingState={setLoadingState}
                onSignIn={onSignIn}
            />
        );
    }

    render(): ReactElement {
        const {
            device: { isMobile, isTablet },
            isCheckout,
            isLoading,
            onVisible,
        } = this.props;

        return !isMobile && !isTablet ? (
            <Overlay
                id={CUSTOMER_ACCOUNT_OVERLAY_KEY}
                mix={{ block: 'MyAccountOverlay' }}
                showBackground
                onVisible={onVisible}
                isStatic={!isCheckout && isMobile}
            >
                <Loader isLoading={isLoading} />
                {this.renderClickOutsideWrapper(this.renderMyAccount())}
            </Overlay>
        ) : (
            this.renderMyAccountPopup()
        );
    }
}

export default MyAccountOverlayComponent;
