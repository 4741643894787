import { lazy, PureComponent, Suspense, ElementType } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { withRouter } from 'react-router-dom';
import { ContractorUserActions } from './ContractorUser.config'
import Loader from 'Component/Loader';
import { RootState } from 'Util/Store/Store.type';
import {
    ContractorUserContainerMapDispatchProps,
    ContractorUserContainerProps,
} from './ContractorUser.type';
export const NoMatchDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/NoMatch/NoMatch.dispatcher'
);

export const ContractorUserCreateForm = lazy(() =>
    import(
        /* webpackMode: "lazy", webpackChunkName: "account-contractor-user-create" */
        '../ContractorUserCreateForm'
    )
);

export const ContractorUserUpdateForm = lazy(() =>
    import(
        /* webpackMode: "lazy", webpackChunkName: "account-contractor-user-edit" */
        '../ContractorUserUpdateForm'
    )
);
export const ContractorUsersList = lazy(() =>
    // @ts-ignore
    import(
        /* webpackMode: "lazy", webpackChunkName: "account-contractor-user-list" */
        '../ContractorUsersList'
    )
);

/** @namespace Pwa/Component/ContractorUser/Container/mapStateToProps */
export const mapStateToProps = (state: RootState) => ({});

/** @namespace Pwa/Component/ContractorUser/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch: Dispatch): ContractorUserContainerMapDispatchProps => ({
    updateNoMatch: (options) => {
        NoMatchDispatcher.then(({ default: dispatcher }) => dispatcher.updateNoMatch(dispatch, options));
    },
});


/** @namespace Pwabb/Component/ContractorUser/Container */
export class ContractorUserContainer extends PureComponent<ContractorUserContainerProps> {
    static renderMap = {
        [ContractorUserActions.LIST]: ContractorUsersList,
        [ContractorUserActions.CREATE]: ContractorUserCreateForm,
        [ContractorUserActions.UPDATE]: ContractorUserUpdateForm,
    } as unknown as Record<string, ElementType>;

    getCurrentComponent(): ElementType {
        const {
            match: { params: { action = '' } = {} },
            updateNoMatch,
        } = this.props;

        updateNoMatch({
            noMatch: !(action in ContractorUserContainer.renderMap),
        });

        return ContractorUserContainer.renderMap[action];
    }

    render() {
        const Component = this.getCurrentComponent();

        if (!Component) {
            return <Loader />;
        }

        return (
            <Suspense fallback={<Loader />}>
                <Component />
            </Suspense>
        );
    }
}
//@ts-ignore
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ContractorUserContainer));
