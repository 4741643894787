import { Dispatch } from 'redux';
import { fetchQuery } from 'Util/Request/Query';
import ContractorsContractorQuery from '../../query/Contractors/Contractors.query';
import { ContractorStructureCreateMutationInput, ContractorStructureDeleteMutationInput, ContractorStructureUpdateMutationInput, StructureFilterInput } from '../../query/Contractors/Contractors.type';
import { fetchMutation } from 'Util/Request/Mutation';

/**
 * @namespace ContractorsContractor/Store/Contractor/Dispatcher */
export class ContractorsStructuresDispatcher {
    name = 'ContractorStructures'

    getContractorSubstructures(_dispatch: Dispatch, currentPage: number, pageSize: number, filter: StructureFilterInput) {
        try {
          return fetchQuery(ContractorsContractorQuery.getContractorSubstructuresQuery(currentPage, pageSize))
        } catch (e: unknown) {
          console.error(this.name, 'Error while fetching contractor\'s users:', JSON.stringify(e))
        } finally {}
    }

    createContractorStructure(input: ContractorStructureCreateMutationInput) {
      return fetchMutation(ContractorsContractorQuery.getContractorStructureCreateMutation(input))
    }

    updateContractorStructure(input: ContractorStructureUpdateMutationInput) {
      return fetchMutation(ContractorsContractorQuery.getContractorStructureUpdateMutation(input))
    }

    deleteContractorStructure(input: ContractorStructureDeleteMutationInput) {
      return fetchMutation(ContractorsContractorQuery.getContractorStructureDeleteMutation(input))
    }
}

export default new ContractorsStructuresDispatcher();
