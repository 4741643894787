/* eslint-disable prettier/prettier */


import { ElementType } from 'react';
import { Reducer } from 'redux';

import injectReducers from 'Util/DynamicReducer/Helper';
import { getStore } from 'Util/Store';

import { Props, WithReducersResult } from './DynamicReducer.type';

/** @namespace PlugAndSell2/Util/DynamicReducer/Index/withReducers */
export const withReducers =
    (reducers: Record<string, Reducer>) =>
        (WrappedComponent: ElementType): WithReducersResult => {
            const injectAndExecute = (props: Props) => {
                injectReducers(getStore(), reducers);

                return <WrappedComponent {...props} />;
            };

            return injectAndExecute;
        };

export default { withReducers };
