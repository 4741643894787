import { PureComponent } from 'react';
import { RmaAvailableTableComponentProps } from './RmaAvailableTable.type';
import { ReactElement } from 'Type/Common.type';

import { ButtonVariant } from 'Component/Button/Button.type';
import Button from 'Component/Button';
import { OrderItem } from 'Query/Order.type';
import { formatPrice } from 'Util/Price';

import Table from '../Table';

import './RmaAvailableTable.style'

export class RmaAvailableTable extends PureComponent<RmaAvailableTableComponentProps> {
  renderError(): ReactElement {
    const { error } = this.props
    
    return (
      <div block="RmaAvailableTable" elem="Error">
        {error}
      </div>
    )
  }

  renderMobileTable(): ReactElement {
    const { rmaAvailableData } = this.props;
    return (
      <div block="RmaAvailableTable" elem="MobileTable">
        {rmaAvailableData.map(item => (
          <div block="RmaAvailableTable" elem="MobileTableItem">
            <dl>
              <dt>{__('Order')}:</dt>
              <dd>#{item.increment_id}</dd>
              <dt>{__('Date')}:</dt>
              <dd>#{item.order_date}</dd>
              <dt>{__('Status')}:</dt>
              <dd>{item.status}</dd>
              <dt>{__('Value')}:</dt>
              <dd>{formatPrice(Number(item.total?.grand_total.value), item.total?.grand_total.currency)}</dd>
              <dt>{__('Actions')}:</dt>
              <dd>{this.renderRowActions(item)}</dd>
            </dl>
          </div>
        ))}
      </div>
    )
  }

  renderNoItems(): ReactElement {
    return (
      <div block="RmaAvailableTable" elem="NoItems">
        {__('There are no possible returns to display.')}
      </div>
    )
  }

  renderRowActions(data: Partial<OrderItem>): ReactElement {
    const { onCreateReturnClick } = this.props;

    return (
      <div block="RmaAvailableTable" elem="Actions">
        <Button variant={ButtonVariant.LIKE_LINK} onClick={() => onCreateReturnClick(data || '')}>
          {__('Return products')}
        </Button>
      </div>
    )
  }

  renderTable(): ReactElement {
    const { device, isLoading, rmaAvailableData } = this.props;

    if (rmaAvailableData.length === 0 && !isLoading) {
      return this.renderNoItems()
    }
    
    if (device.isMobile) {
      return this.renderMobileTable();
    }

    return (
      <Table
        data={rmaAvailableData}
        isLoading={isLoading}
        columns={[
            {
                key: 'increment_id',
                label: __('Order'),
                component: (increment_id: number) => {
                    return <span>#{increment_id}</span>
                }
            },
            {
                key: 'order_date',
                label: __('Date'),
                component: (order_date: number) => {
                    return <span>{order_date}</span>
                }
            },
            {
                key: 'status',
                label: __('Status'),
                component: (role_id: number) => {
                    return <span>{role_id}</span>
                }
            },
            {
                key: 'value',
                label: __('Value'),
                component: (_: any, data: Partial<OrderItem>) => {
                    return (
                      <span>
                        {formatPrice(Number(data.total?.grand_total.value), data.total?.grand_total.currency)}
                      </span>
                    )
                },
            },
            {
                key: 'id',
                label: '',
                mix: { block: 'RmaAvailableTable', elem: 'ActionsColumn' },
                component: (_: any, data: Partial<OrderItem>) => this.renderRowActions.call(this, data),
            },
        ]}
      />
    )
  }

  render() {
    return (
      <div block="RmaAvailableTable">
          {this.renderTable()}
          {this.renderError()}
      </div>
    );
  }
}


export default RmaAvailableTable;