import { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import MyAccountQuery from 'Query/MyAccount.query';
import { ChangeCustomerPasswordOptions, SignInOptions } from 'Query/MyAccount.type';
import { CUSTOMER } from 'Route/MyAccount/MyAccount.config';
import { updateCustomerDetails } from 'Store/MyAccount/MyAccount.action';
import { goToPreviousNavigationState } from 'Store/Navigation/Navigation.action';
import { NavigationType } from 'Store/Navigation/Navigation.type';
import { showNotification } from 'Store/Notification/Notification.action';
import { NotificationType } from 'Store/Notification/Notification.type';
import { hideActiveOverlay } from 'Store/Overlay/Overlay.action';
import { NetworkError } from 'Type/Common.type';
import { GQLCustomerUpdateInput } from 'Type/Graphql.type';
import { isSignedIn } from 'Util/Auth/IsSignedIn';
import BrowserDatabase from 'Util/BrowserDatabase';
import { ONE_MONTH_IN_SECONDS } from 'Util/Request/Config';
import { getErrorMessage } from 'Util/Request/Error';
import { fetchMutation } from 'Util/Request/Mutation';
import { RootState } from 'Util/Store/Store.type';

import MyAccountCustomerPopup from './MyAccountCustomerPopup.component';
import { MyAccountCustomerPopupAction } from './MyAccountCustomerPopup.config';
import {
    MyAccountCustomerPopupComponentProps,
    MyAccountCustomerPopupContainerFunctions,
    MyAccountCustomerPopupContainerMapDispatchProps,
    MyAccountCustomerPopupContainerMapStateProps,
    MyAccountCustomerPopupContainerProps,
    MyAccountCustomerPopupContainerState,
    MyAccountCustomerPopupPayload,
} from './MyAccountCustomerPopup.type';

/** @namespace PlugAndSell2/Component/MyAccountCustomerPopup/Container/mapStateToProps */
export const mapStateToProps = (state: RootState): MyAccountCustomerPopupContainerMapStateProps => ({
    payload: (
        state.PopupReducer.popupPayload as {
            [MyAccountCustomerPopupAction.CUSTOMER_POPUP_ID]: MyAccountCustomerPopupPayload;
        }
    )[MyAccountCustomerPopupAction.CUSTOMER_POPUP_ID],
});

/** @namespace PlugAndSell2/Component/MyAccountCustomerPopup/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch: Dispatch): MyAccountCustomerPopupContainerMapDispatchProps => ({
    updateCustomer: (customer) => dispatch(updateCustomerDetails(customer)),
    goToPreviousHeaderState: () => dispatch(goToPreviousNavigationState(NavigationType.TOP_NAVIGATION_TYPE)),
    showErrorNotification: (error) => dispatch(showNotification(NotificationType.ERROR, getErrorMessage(error))),
    showSuccessNotification: (message) => dispatch(showNotification(NotificationType.SUCCESS, message)),
    hideActiveOverlay: () => dispatch(hideActiveOverlay()),
});

/** @namespace PlugAndSell2/Component/MyAccountCustomerPopup/Container */
export class MyAccountCustomerPopupContainer extends PureComponent<MyAccountCustomerPopupContainerProps, MyAccountCustomerPopupContainerState> {
    state = {
        isLoading: false,
        showEmailChangeField: false,
        showPasswordChangeField: false,
    };

    containerFunctions: MyAccountCustomerPopupContainerFunctions = {
        onCustomerSave: this.onCustomerSave.bind(this),
        onPasswordChange: this.onPasswordChange.bind(this),
        handleChangeEmailCheckbox: this.handleChangeEmailCheckbox.bind(this),
        handleChangePasswordCheckbox: this.handleChangePasswordCheckbox.bind(this),
    };

    containerProps(): Pick<MyAccountCustomerPopupComponentProps, 'payload' | 'isLoading' | 'showEmailChangeField' | 'showPasswordChangeField'> {
        const { payload } = this.props;
        const { isLoading, showEmailChangeField, showPasswordChangeField } = this.state;

        return {
            payload,
            isLoading,
            showEmailChangeField,
            showPasswordChangeField,
        };
    }

    onError = (error: NetworkError | NetworkError[]) => {
        const { showErrorNotification } = this.props;
        this.setState({ isLoading: false });
        showErrorNotification(error);
    };

    onCustomerSave(customer: ChangeCustomerPasswordOptions & SignInOptions & GQLCustomerUpdateInput): Promise<void> {
        const { updateCustomer, hideActiveOverlay, goToPreviousHeaderState } = this.props;

        if (!isSignedIn()) {
            return null as any;
        }

        const mutation = MyAccountQuery.getUpdateInformationMutation(customer);
        this.setState({ isLoading: true });

        return fetchMutation(mutation).then(
            /** @namespace PlugAndSell2/Component/MyAccountCustomerPopup/Container/MyAccountCustomerPopupContainer/onCustomerSave/fetchMutation/then */
            ({ updateCustomerV2: { customer = {} } }) => {
                BrowserDatabase.setItem(customer, CUSTOMER, ONE_MONTH_IN_SECONDS);
                updateCustomer(customer);
                this.setState({ isLoading: false }, () => {
                    hideActiveOverlay();
                    goToPreviousHeaderState();
                });
            },
            this.onError
        );
    }

    onPasswordChange(passwords: ChangeCustomerPasswordOptions) {
        const { showSuccessNotification, hideActiveOverlay, goToPreviousHeaderState } = this.props;

        if (!isSignedIn()) {
            return null;
        }

        const mutation = MyAccountQuery.getChangeCustomerPasswordMutation(passwords);
        this.setState({ isLoading: true });

        return fetchMutation(mutation).then(
            /** @namespace PlugAndSell2/Component/MyAccountCustomerPopup/Container/MyAccountCustomerPopupContainer/onPasswordChange/fetchMutation/then */
            () => {
                showSuccessNotification(__('Your password was successfully updated!'));
                this.setState({ isLoading: false }, () => {
                    hideActiveOverlay();
                    goToPreviousHeaderState();
                });
            },
            this.onError
        );
    }

    handleChangePasswordCheckbox(): void {
        this.setState(({ showPasswordChangeField }) => ({ showPasswordChangeField: !showPasswordChangeField }));
    }

    handleChangeEmailCheckbox(): void {
        this.setState(({ showEmailChangeField }) => ({ showEmailChangeField: !showEmailChangeField }));
    }

    render() {
        return <MyAccountCustomerPopup {...this.containerProps()} {...this.containerFunctions} />;
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MyAccountCustomerPopupContainer);
