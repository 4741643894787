import { Field, Query, Mutation } from '@tilework/opus';
import { GetRmaRequestItem, GetRmaRequestListByCustomerIdOutput, GetRmaRequestOutput, PlaceRmaRequestOutput, RmaOptimizedProductImage, RmaRequestInput, RmaRequestItem, RmaRequestItemImage, RmaRequestItemImageInput } from './Rma.type';
import { OrderItem, OrderItemProduct, OrderTotal } from 'Query/Order.type';
import { Money } from 'Query/ProductList.type';
import { OrderCustomerAddress } from 'Query/Checkout.type';
import ProductListQuery from 'Query/ProductList.query';

/** @namespace Pwa/Query/Rma/Query/RmaQuery */
export class RmaQuery {
    getPlaceRequestMutation(input: RmaRequestInput): Mutation<'placeRmaRequest', PlaceRmaRequestOutput> {
        return new Mutation<'placeRmaRequest', PlaceRmaRequestOutput>('placeRmaRequest')
          .addArgument('rma_request', 'RmaRequestInput!', input)
          .addFieldList([new Field<'rma_request_id', number>('rma_request_id')]);
    }

    getOrderList(): Query<'customer', { orders: { items: Partial<OrderItem>[] } }> {
        return new Query<'customer', { orders: { items: Partial<OrderItem>[] } }>('customer')
          .addFieldList([this._getCustomerOrderField()]);
    }

    getOrder(orderId: number): Query<'customer', { orders: { items: Partial<OrderItem>[] } }> {
      return new Query<'customer', { orders: { items: Partial<OrderItem>[] } }>('customer')
        .addFieldList([this._getCustomerOrderField({ isSingleOrder: true, orderId })]);
    }

    getRequestListQuery(): Query<'getRmaRequestListByCustomerId', GetRmaRequestListByCustomerIdOutput> {
        const query = new Query<'getRmaRequestListByCustomerId', GetRmaRequestListByCustomerIdOutput>('getRmaRequestListByCustomerId').addFieldList(this._getRequestItemsFields());
        // @ts-ignore
        return query;
    }

    getRequestQuery(rmaId: string): Query<'getRmaRequest', GetRmaRequestOutput> {
        // @ts-ignore
        return new Query<'getRmaRequest', GetRmaRequestOutput>('getRmaRequest')
          .addArgument('rma_request_id', 'Int!', rmaId)
          .addFieldList(this._getRequestItemsFields(true));
    }

    getStatusListQuery(): Query<'getRmaStatuses', string> {
        return new Query<'getRmaStatuses', string>('getRmaStatuses');
    }

    _getRequestItemsFields(isSingleOrder: boolean = false): Array<
      | Field<'id', string>
      | Field<'status', string>
      | Field<'decision', string>
      | Field<'increment_id', string>
      | Field<'order_id', string>
      | Field<'date_created', string>
      | Field<'name_surname', string>
      | Field<'account_no', string>
      | Field<'admin_name', string>
      | Field<'phone', string>
      | Field<'request_items', GetRmaRequestItem, true>
    > {
        let fields: Array<
        | Field<'id', string>
        | Field<'status', string>
        | Field<'increment_id', string>
        | Field<'order_id', string>
        | Field<'date_created', string>
        | Field<'request_items', GetRmaRequestItem, true>
        | Field<'name_surname', string>
        | Field<'account_no', string>
        | Field<'admin_name', string>
        | Field<'phone', string>
        | Field<'decision', string>
      > = [
          new Field<'id', string>('id'), 
          new Field<'status', string>('status'), 
          new Field<'increment_id', string>('increment_id'), 
          new Field<'order_id', string>('order_id'), 
          new Field<'date_created', string>('date_created'),
        ];

        if (isSingleOrder) {
            fields = [
                ...fields,
                new Field<'name_surname', string>('name_surname'),
                new Field<'account_no', string>('account_no'),
                new Field<'admin_name', string>('admin_name'),
                new Field<'phone', string>('phone'),
                new Field<'decision', string>('decision'),
                new Field<'request_items', GetRmaRequestItem, true>('request_items').addFieldList([
                    new Field<'id', string>('id'),
                    new Field<'sku', string>('sku'),
                    new Field<'name', string>('name'),
                    new Field<'request_id', string>('request_id'),
                    new Field<'qty', string>('qty'),
                    new Field<'reason', string>('reason'),
                    new Field<'request_qty', string>('request_qty'),
                    new Field<'price', Money>('price').addFieldList([
                      new Field<'value', string>('value'), 
                      new Field<'currency', string>('currency')
                    ]),
                    ProductListQuery._getProductThumbnailField(),
                    new Field<'images', RmaRequestItemImageInput, true>('images').addFieldList([
                        new Field<'id', string>('id'),
                        new Field<'rma_request_item_id', string>('rma_request_item_id'),
                        new Field<'value', string>('value'),
                    ]),
                ])
            ]
        }

        return fields;
    }

    _getCustomerOrderField({ isSingleOrder, orderId }:  { isSingleOrder?: boolean, orderId?: number } = {}) {
        let filter = {};

        if (orderId) {
            filter = {
                entity_id: { eq: orderId },
            };
        }

        return new Field('orders')
          .addArgument('filter', 'CustomerOrdersFilterInput', filter)
          .addFieldList([this._getCustomerOrderItemsField(!!isSingleOrder)])
    }

    _getCustomerOrderItemsField(isSingleOrder: boolean) {
        return new Field<'items', OrderItem, true>('items').addFieldList(this._getCustomerOrderItemsFields(isSingleOrder));
    }

    _getCustomerOrderItemsFields(isSingleOrder = false) {
        const fields: Array<
          | Field<'id', string>
          | Field<'increment_id', string>
          | Field<'order_date', string>
          | Field<'status', string>
          | Field<'total', OrderTotal>
          | Field<'id', string>
          | Field<'items', OrderItemProduct, true>
          | Field<'shipping_address', OrderCustomerAddress>
        > = [
            new Field<'id', string>('id'),
            new Field<'increment_id', string>('increment_id'),
            new Field<'order_date', string>('order_date'),
            new Field<'status', string>('status'),
            new Field<'total', OrderTotal>('total').addFieldList([new Field('grand_total').addFieldList([
              new Field<'value', string>('value'), 
              new Field<'currency', string>('currency'),
            ])]),
        ];

        if (isSingleOrder) {
            fields.push(
                new Field<'items', OrderItemProduct, true>('items').addFieldList([
                    new Field<'id', string>('id'),
                    new Field<'product_name', string>('product_name'),
                    new Field<'product_sku', string>('product_sku'),
                    new Field('product_small_image').addFieldList(ProductListQuery._getProductThumbnailFields()),
                    new Field<'quantity_ordered', string>('quantity_ordered'),
                    new Field<'product_sale_price', Money>('product_sale_price').addFieldList([
                      new Field<'currency', string>('currency'), 
                      new Field<'value', string>('value')
                    ]),
                ])
            );

            fields.push(
                new Field<'shipping_address', OrderCustomerAddress>('shipping_address').addFieldList([
                  new Field<'firstname', string>('firstname'), 
                  new Field<'lastname', string>('lastname'), 
                  new Field<'street', string>('street'), 
                  new Field<'postcode', string>('postcode'), 
                  new Field<'telephone', string>('telephone')
                ])
            );
        }

        return fields;
    }

    _getThumbnailField(): Field<'thumbnail', RmaOptimizedProductImage>  {
        return new Field<'thumbnail', RmaOptimizedProductImage>('thumbnail').addFieldList([
          new Field<'url', string>('url'),
          new Field<'label', string>('label')
        ]);
    }

    _getPrice(): Field<'price', Money> {
        return new Field<'price', Money>('price').addFieldList([
          new Field<'value', string>('value'), 
          new Field<'currency', string>('currency')
        ]);
    }
}

export default new RmaQuery();
