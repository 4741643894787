import { PureComponent } from 'react';
import { connect } from 'react-redux';

import Icons from './Icons.component';
import * as IconsConfig from './Icons.config';
import { IconsComponentProps, IconsContainerProps } from './Icons.type';

/** @namespace PlugAndSell2/Component/Icons/Container/mapStateToProps */
export const mapStateToProps = () => ({});

/** @namespace PlugAndSell2/Component/Icons/Container/mapDispatchToProps */
export const mapDispatchToProps = () => ({});

/** @namespace PlugAndSell2/Component/Icons/Container */
export class IconsContainer extends PureComponent<IconsContainerProps> {
    static defaultProps: Partial<IconsContainerProps> = {
        fill: '#000',
        width: '30',
        height: '30',
    };

    containerProps(): Pick<IconsComponentProps, 'icon'> {
        const { name, ...rest } = this.props;

        return {
            icon: this._getIconByName(name, rest),
        };
    }

    _getIconByName(name: string, props: object) {
        if (name) {
            const nameUpperCase = name.toUpperCase();

            // @ts-ignore
            if (typeof IconsConfig[nameUpperCase] === 'function') {
                // @ts-ignore
                return IconsConfig[nameUpperCase](props);
            }
        }

        return '';
    }

    render() {
        return <Icons {...this.containerProps()} />;
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(IconsContainer);
