import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import Icons from 'Component/Icons';
import { IconName } from 'Component/Icons/Icons.type';
import Loader from 'Component/Loader';
import Pagination from 'Component/Pagination';
import Popup from 'Component/Popup';
import { formatPrice } from 'Util/Price';
import PageSize from '../PageSize'
import Tooltip from '../Tooltip';

import { ORDER_PREVIEW_POPUP } from './FinancialDocs.config';

import './FinancialDocs.style';

import Filters from '../Filters';
import { isEmpty } from 'Util/Common';
import { FieldType } from 'Component/Field/Field.config';

import { FinancialDocsComponentProps, FinancialDocsFilters } from './FinancialDocs.type'
import { FinancialDoc } from '../../query/FinancialDocs.type';
import { OrderItem, OrderItemProduct } from 'Query/Order.type';

/** @namespace PlugAndSell2/Component/FinancialDocs/Component/FinancialDocs */
export class FinancialDocs extends PureComponent<FinancialDocsComponentProps> {
  static propTypes = {
    financialDocs: PropTypes.shape({
      items: PropTypes.arrayOf(PropTypes.object),
      page_info: PropTypes.shape({
        current_page: PropTypes.number,
        page_size: PropTypes.number,
        total_pages: PropTypes.number,
      }),
      total_count: PropTypes.number,
    }),
    handleColumnSorting: PropTypes.func,
    onDetailsClick: PropTypes.func,
  };

  static defaultProps = {
    financialDocs: {
      items: [],
      page_info: {
        current_page: null,
        page_size: null,
        total_pages: null,
      },
      total_count: null,
    },
    handleColumnSorting: () => { },
    onDetailsClick: () => { },
  };

  renderDetailsButton = (doc: FinancialDoc) => {
    const { onDetailsClick } = this.props;

    return (
      <div block="FinancialDocs" elem="OrderBtnWrapper">
        <button
          disabled={!doc.order_id}
          onClick={() => onDetailsClick(doc)}
        >{doc.number || '--'}</button>
      </div>
    );
  };

  renderDownloadButton = (doc: FinancialDoc) => {
    const { onDownloadClick } = this.props;

    return (
      <div block="FinancialDocs" elem="DownloadWrapper">
        <button
          disabled={!doc.reference}
          onClick={() => onDownloadClick(doc)}
        >
          <Icons name={IconName.DOWNLOAD_PDF} width="15px" />
        </button>
      </div>
    );
  };

  renderFilters = () => {
    const { onFilterChange, isMobile } = this.props;

    return (
      <Filters<FinancialDocsFilters>
        isMobile={isMobile}
        onChange={onFilterChange}
        filters={[
          {
            type: FieldType.TEXT,
            label: __('Invoice'),
            attr: {
              id: 'number',
              name: 'number',
              placeholder: __('Invoice'),
            }
          },
          {
            type: FieldType.TEXT,
            label: __('Total (tax incl.)'),
            attr: {
              id: 'grand_total',
              name: 'grand_total',
              placeholder: __('from'),
            }
          },
          {
            type: FieldType.TEXT,
            attr: {
              id: 'grand_total_to',
              name: 'grand_total_to',
              placeholder: __('to'),
            }
          },
          {
            type: FieldType.DATE,
            attr: {
              id: 'doc_date',
              name: 'doc_date',
              placeholder: __('from'),
            },
            label: __('Data invoice'),
          },
          {
            type: FieldType.DATE,
            attr: {
              id: 'doc_date_to',
              name: 'doc_date_to',
              placeholder: __('to'),
            },
          },
          {
            type: FieldType.DATE,
            attr: {
              id: 'payment_date',
              name: 'payment_date',
              placeholder: __('from'),
              selectPlaceholder: __('from'),
            },
            label: __('Date of payment'),
          },
          {
            type: FieldType.DATE,
            attr: {
              id: 'payment_date_to',
              name: 'payment_date_to',
              placeholder: __('to'),
            },
          },
        ]}
      />
    );
  };

  renderPopup() {
    const { orderPreviewPopupPayload } = this.props;

    if (!orderPreviewPopupPayload) {
      return null;
    }

    return (
      <Popup
        id={ORDER_PREVIEW_POPUP}
        mix={{ block: 'FinancialDocsPopup', elem: 'Popup' }}
      >
        {this.renderPopupContent()}
      </Popup>
    );
  }

  renderOrderList = (order: { items: OrderItemProduct[] }) => {
    const { items } = order;

    if (isEmpty(items)) {
      return <p>{__('No products')}</p>;
    }

    return (
      <section block="FinancialDocsPopup" elem="ProductList">
        <h6 block="FinancialDocsPopup" elem="ProductListHeader">
          <span
            block="FinancialDocsPopup"
            elem="ProductListHeaderCol"
            mods={{ type: 'info' }}
          >
            {__('Name')}
          </span>
          <span
            block="FinancialDocsPopup"
            elem="ProductListHeaderCol"
            mods={{ type: 'qty' }}
          >
            {__('Quantity')}
          </span>
          <span
            block="FinancialDocsPopup"
            elem="ProductListHeaderCol"
            mods={{ type: 'row_total_excl' }}
          >
            {__('Price net')}
          </span>
          <span
            block="FinancialDocsPopup"
            elem="ProductListHeaderCol"
            mods={{ type: 'net_worth' }}
          >
            {__('Net value')}
          </span>
        </h6>
        <ul>
          {items.map((item) => {
            const {
              row_subtotal: {
                currency,
                value
              },
            } = item;

            return (
              <li
                key={`${item.product_sku}-order-item`}
                block="FinancialDocsPopup"
                elem="ProductListItem"
              >
                <div
                  block="FinancialDocsPopup"
                  elem="ProductListItemCol"
                  mods={{ type: 'info' }}
                >
                  {item.product_name}
                  <span>{item.product_sku}</span>
                </div>
                <div
                  block="FinancialDocsPopup"
                  elem="ProductListItemCol"
                  mods={{ type: 'qty' }}
                >
                  {item.quantity_ordered}
                </div>
                <div
                  block="FinancialDocsPopup"
                  elem="ProductListItemCol"
                  mods={{ type: 'row_total' }}
                >
                  {formatPrice(Number(value), currency)}
                </div>
                <div
                  block="FinancialDocsPopup"
                  elem="ProductListItemCol"
                  mods={{ type: 'row_total_excl' }}
                >
                  {formatPrice(Number(value), currency)}
                </div>
              </li>
            );
          })}
        </ul>
      </section>
    );
  };

  renderShippingRow = (order: OrderItem) => {
    const {
      total
    } = order;

    return (
      <section block="FinancialDocsPopup" elem="RowShipping">
        <span>
          {__('Shipping')} ({order.shipping_method})
        </span>
        <span>
          {formatPrice(
            Number(total.shipping_handling.amount_including_tax.value),
            total.shipping_handling.amount_including_tax.currency
          )}
        </span>
        <span>
          {formatPrice(
            Number(total.shipping_handling.amount_excluding_tax.value),
            total.shipping_handling.amount_excluding_tax.currency
          )}
        </span>
      </section>
    );
  };

  renderRowTotals = (order: OrderItem) => (
    <section block="FinancialDocsPopup" elem="RowTotals">
      <div block="FinancialDocsPopup" elem="RowTotalsRow">
        <span>{__('Total')}</span>
        <span>
          {formatPrice(
            Number(order.total.grand_total.value),
            order.total.grand_total.currency
          )}
        </span>
        <span>
          {formatPrice(
            Number(order.total.grand_total.value),
            order.total.grand_total.currency
          )}
        </span>
      </div>
    </section>
  );

  renderPageSize = () => {
    const { onPageSizeSelected, selectedPageSize } = this.props;

    return (
      <PageSize
        options={[
          { label: '10', value: 10 },
          { label: '25', value: 25 },
          { label: '50', value: 50 },
        ]}
        onPageSizeSelected={onPageSizeSelected}
        defaultValue={selectedPageSize}
      />
    );
  };

  renderPopupContent() {
    const { orderPreviews, orderPreviewPopupPayload } = this.props;
    const { doc } = orderPreviewPopupPayload;

    const order = orderPreviews[doc?.order_id || ''];

    if (isEmpty(order)) {
      return <Loader isLoading />;
    }

    return (
      <div block="FinancialDocsPopup" elem="Content">
        <h3 block="FinancialDocsPopup" elem="Header">
          {order.increment_id}
        </h3>
        <h4 block="FinancialDocsPopup" elem="SubHeader">
          {__('Status')}: {order.status}
        </h4>
        <div block="FinancialDocsPopup" elem="Info">
          <p block="FinancialDocsPopup" elem="InfoBox">
            {__('Created at: ')} <span>{order.order_date}</span>
          </p>
          <p block="FinancialDocsPopup" elem="InfoBox">
            {__('Sale date: ')} <span>{doc.sale_date}</span>
          </p>
          <p block="FinancialDocsPopup" elem="InfoBox">
            {__('Document date')} <span>{doc.doc_date}</span>
          </p>
          <p block="FinancialDocsPopup" elem="InfoBox">
            {__('Number')}: <span>{doc.number}</span>
          </p>
          <p block="FinancialDocsPopup" elem="InfoBox">
            {__('Paid')}: <span>{formatPrice(Number(doc.paid), doc.currency)}</span>
          </p>
        </div>
        {this.renderOrderList(order)}
        {this.renderShippingRow(order)}
        {this.renderRowTotals(order)}
      </div>
    );
  }

  renderRow = (doc: FinancialDoc) => (
    <li key={`financial-doc-${doc.id}`} block="FinancialDocs" elem="TableRow">
      <div block="FinancialDocs" elem="TableRowContent">
        <span
          block="FinancialDocs"
          elem="TableRowContentColumn"
          mods={{ type: 'doc_date' }}
        >
          {doc.doc_date || '--'}
        </span>
        <span
          block="FinancialDocs"
          elem="TableRowContentColumn"
          mods={{ type: 'payment_date' }}
        >
          {doc.payment_date || '--'}
        </span>
        <span
          block="FinancialDocs"
          elem="TableRowContentColumn"
          mods={{ type: 'delay_days', isDelayed: !!doc.delay_days }}
        >
          {!!doc.delay_days ?
            // @ts-ignore
            <Tooltip
              reference={
                <span block="FinancialDocs" elem="Tooltip">
                  <Icons name={IconName.WARNING_FILL} />
                  {doc.delay_days}
                </span>
              }
            >
              <span block="FinancialDocs" elem="Message">
                {__(
                  'Your time for payment has expired, please pay as soon as possible settle the invoice'
                )}
              </span>
            </Tooltip> : 0}
        </span>
        <span
          block="FinancialDocs"
          elem="TableRowContentColumn"
          mods={{ type: 'number' }}
        >
          {this.renderDetailsButton(doc)}
        </span>
        <span
          block="FinancialDocs"
          elem="TableRowContentColumn"
          mods={{ type: 'value' }}
        >
          {formatPrice(Number(doc.value), doc.currency)}
        </span>
        <span
          block="FinancialDocs"
          elem="TableRowContentColumn"
          mods={{ type: 'amount_due' }}
        >
          {formatPrice(Number(doc.difference), doc.currency)}
        </span>
      </div>
      <div block="FinancialDocs" elem="TableRowActions">
        {this.renderDownloadButton(doc)}
      </div>
    </li>
  );

  renderPagination = () => {
    const { financialDocs, onPageSelect } = this.props;

    if (isEmpty(financialDocs.items)) {
      return null;
    }

    return (
      <div block="FinancialDocs" elem="BottomActions">
        <div block="FinancialDocs" elem="Pagination">
          <Pagination
            totalPages={financialDocs.page_info.total_pages}
            onNavigate={onPageSelect}
            isLoading={false}
          />
        </div>
      </div>
    );
  };

  renderTable = () => {
    const { financialDocs, isLoading } =
      this.props;

    return (
      <div block="FinancialDocs" elem="TableWrapper">
        <div block="FinancialDocs" elem="Table">
          <div block="FinancialDocs" elem="TableHeader">
            <span
              block="FinancialDocs"
              elem="TableHeaderColumn"
              mods={{
                type: 'doc_date',
              }}
            >
              {__('Date invoice')}
            </span>
            <span
              block="FinancialDocs"
              elem="TableHeaderColumn"
              mods={{
                type: 'payment_date',
              }}
            >
              {__('Date of payment')}
            </span>
            <span
              block="FinancialDocs"
              elem="TableHeaderColumn"
              mods={{
                type: 'delay_days',
              }}
            >
              {__('Day past due')}
            </span>
            <span
              block="FinancialDocs"
              elem="TableHeaderColumn"
              mods={{
                type: 'number',
              }}
            >
              {__('Invoice')}
            </span>
            <span
              block="FinancialDocs"
              elem="TableHeaderColumn"
              mods={{
                type: 'value',
              }}
            >
              {__('Total (tax incl.)')}
            </span>
            <span
              block="FinancialDocs"
              elem="TableHeaderColumn"
              mods={{
                type: 'amount_due',
              }}
            >
              {__('Amount due')}
            </span>
            <span
              block="FinancialDocs"
              elem="TableHeaderColumn"
              mods={{
                type: 'download',
              }}
            >
              {__('Download')}
            </span>
          </div>
          <ul block="FinancialDocs" elem="TableList">
            {isEmpty(financialDocs.items) && !isLoading && (
              <p block="FinancialDocs" elem="NoDocs">
                {__('No documents')}
              </p>
            )}
            {financialDocs.items?.map(this.renderRow)}
          </ul>
        </div>
      </div>
    );
  };

  render() {
    const { isLoading } = this.props;

    return (
      <div block="FinancialDocs">
        {this.renderFilters()}
        {this.renderTable()}
        {this.renderPagination()}
        <Loader isLoading={isLoading} />
        {this.renderPopup()}
      </div>
    );
  }
}

export default FinancialDocs;
