import { Field, Query } from '@tilework/opus';
import { isEmpty } from 'Util/Common';
import { FinancialDoc, FinancialDocSortInput, FinancialDocFilterInput, GetFinancialDocsOutput, PageInfo } from './FinancialDocs.type';


export class FinancialDocsQuery {
  getFinancialDocsQuery(
    filter: FinancialDocFilterInput,
    sorting: Partial<FinancialDocSortInput>,
    currentPage: number = 1,
    pageSize: number = 5
  ):Query<'getFinancialDocs', GetFinancialDocsOutput> {
    const field = new Query<'getFinancialDocs', GetFinancialDocsOutput>('getFinancialDocs')
      .addArgument('filter', 'FinancialDocFilterInput', filter)
      .addArgument('pageSize', 'Int', pageSize)
      .addArgument('currentPage', 'Int', currentPage);

    if (!isEmpty(sorting)) {
      field.addArgument('sort', 'FinancialDocSortInput', sorting);
    }

    field.addFieldList(this._getFinacialDocsFields());

    return field;
  }

  _getFinacialDocsFields(): Array<Field<'items', FinancialDoc, true> | Field<'page_info', PageInfo>> {
    return [this._getFinancialDocsItemsField(), this._getPageInfoField()];
  }

  _getFinancialDocsItemsField(): Field<'items', FinancialDoc, true> {
    // @ts-ignore
    return new Field<'items', FinancialDoc, true>('items').addFieldList(this._getFinancialDocsItemsFields());
  }

  _getFinancialDocsItemsFields(): Array<
    | Field<"id", number>
    | Field<"contractor_id", number>
    | Field<"order_id", number>
    | Field<"type", string>
    | Field<"number", string>
    | Field<"reference", string>
    | Field<"unit", string>
    | Field<"order_number", string>
    | Field<"value", number>
    | Field<"currency", string>
    | Field<"paid", number>
    | Field<"difference", number>
    | Field<"status", string>
    | Field<"sale_date", string>
    | Field<"payment_date", string>
    | Field<"delay_days", number>
    | Field<"doc_date", string>
  > {
    return ([
      new Field<"id", number>("id"),
      new Field<"contractor_id", number>("contractor_id"),
      new Field<"order_id", number>("order_id"),
      new Field<"type", string>("type"),
      new Field<"number", string>("number"),
      new Field<"reference", string>("reference"),
      new Field<"unit", string>("unit"),
      new Field<"order_number", string>("order_number"),
      new Field<"value", number>("value"),
      new Field<"currency", string>("currency"),
      new Field<"paid", number>("paid"),
      new Field<"difference", number>("difference"),
      new Field<"status", string>("status"),
      new Field<"sale_date", string>("sale_date"),
      new Field<"payment_date", string>("payment_date"),
      new Field<"delay_days", number>("delay_days"),
      new Field<"doc_date", string>("doc_date"),
    ])
  }

  _getPageInfoField(): Field<'page_info', PageInfo> {
    return new Field('page_info').addFieldList(this._getPageInfoFields())
  }

  _getPageInfoFields(): Array<
    | Field<'current_page', number>
    | Field<'page_size', number>
    | Field<'total_pages', number>
  > {
    return [
      new Field<'current_page', number>('current_page'),
      new Field<'page_size', number>('page_size'),
      new Field<'total_pages', number>('total_pages'),
    ]
  }
}

export default new FinancialDocsQuery();