/* eslint-disable spaced-comment */

import { PureComponent } from 'react';

import { ReactElement } from 'Type/Common.type';

import { FieldGroupComponentProps } from './FieldGroup.type';

import './FieldGroup.style';

/**
 * Field Group
 * @class FieldGroup
 * @namespace PlugAndSell2/Component/FieldGroup/Component */
export class FieldGroupComponent extends PureComponent<FieldGroupComponentProps> {
    static defaultProps: Partial<FieldGroupComponentProps> = {
        validationResponse: null,
    };

    renderError(message: string, key: string) {
        return (
            <div block="Field" elem="ErrorMessage" key={key}>
                {message}
            </div>
        );
    }

    //#region LABEL/TEXT RENDER
    // Renders validation error messages under group
    renderErrorMessage(message: string): ReactElement {
        return (
            <div block="Field" key={message} elem="ErrorMessage">
                {message}
            </div>
        );
    }

    renderErrorMessages(): ReactElement {
        const { showErrorAsLabel, validationResponse, name } = this.props;

        if (!showErrorAsLabel || !validationResponse || validationResponse === true) {
            return null;
        }

        const { errorMessages } = validationResponse;

        if (!errorMessages) {
            return null;
        }

        return (
            <div block="Field" elem="ErrorMessages">
                {errorMessages.map((message, index) => this.renderError.call(this, message, name + index))}
            </div>
        );
    }

    // Renders group label above field
    renderLabel(): ReactElement {
        const { label } = this.props;

        if (!label) {
            return null;
        }

        return label;
    }

    // Renders group label under field
    renderSubLabel(): ReactElement {
        const { subLabel } = this.props;

        if (!subLabel) {
            return null;
        }

        return subLabel;
    }
    //#endregion

    render(): ReactElement {
        const { validationResponse, children, setRef, attr, events, mods } = this.props;

        return (
            <div block="FieldGroup" elem="Wrapper" mods={mods}>
                {this.renderLabel()}
                <div
                    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction
                    {...attr}
                    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction, @typescript-eslint/no-explicit-any
                    {...(events as any)}
                    ref={(elem) => setRef(elem)}
                    block="FieldGroup"
                    mods={{
                        isValid: validationResponse === true,
                        hasError: validationResponse !== true && Object.keys(validationResponse || {}).length !== 0,
                    }}
                >
                    {children}
                </div>
                {this.renderErrorMessages()}
                {this.renderSubLabel()}
            </div>
        );
    }
}

export default FieldGroupComponent;
