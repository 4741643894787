/** * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa-theme
 * @link https://github.com/scandipwa/scandipwa-theme
 */

import { KevinBank } from 'Query/Checkout.type';

import {
    CheckoutActionType,
    UpdateEmailAction,
    UpdateEmailAvailableAction,
    UpdateKevinBanksList,
    UpdateKevinSelectedBankAction,
    UpdateShippingFieldsAction,
} from './Checkout.type';

/** @namespace PlugAndSell2/Store/Checkout/Action/updateShippingFields */
export const updateShippingFields = (shippingFields: Record<string, unknown>): UpdateShippingFieldsAction => ({
    type: CheckoutActionType.UPDATE_SHIPPING_FIELDS,
    shippingFields,
});

/** @namespace PlugAndSell2/Store/Checkout/Action/updateEmail */
export const updateEmail = (email: string): UpdateEmailAction => ({
    type: CheckoutActionType.UPDATE_EMAIL,
    email,
});

/** @namespace PlugAndSell2/Store/Checkout/Action/updateEmailAvailable */
export const updateEmailAvailable = (isEmailAvailable: boolean): UpdateEmailAvailableAction => ({
    type: CheckoutActionType.UPDATE_EMAIL_AVAILABLE,
    isEmailAvailable,
});

/** @namespace PlugAndSell2/Store/Checkout/Action/updateKevinBanksList */
export const updateKevinBanksList = (banks: KevinBank[] | null): UpdateKevinBanksList => ({
    type: CheckoutActionType.UPDATE_KEVIN_BANKS_LIST,
    banks,
});

/** @namespace PlugAndSell2/Store/Checkout/Action/updateKevinSelectedBank */
export const updateKevinSelectedBank = (bank: KevinBank | null): UpdateKevinSelectedBankAction => ({
    type: CheckoutActionType.UPDATE_KEVIN_SELECTED_BANK,
    bank,
});

/** @namespace PlugAndSell2/Store/Checkout/Action/updateOrderButtonEnableStatus */
export const updateOrderButtonEnableStatus = (isOrderButtonEnabled: boolean) => ({
    type: CheckoutActionType.UPDATE_ORDER_BUTTON_ENABLE_STATUS,
    isOrderButtonEnabled,
});
