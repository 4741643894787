import { Field, Query } from '@tilework/opus';

import { BrandItems, GQLMageplazaBrandsFilterInput, GQLMageplazaBrandsOutput } from 'Query/Brands.type';

/** @namespace PlugAndSell2/Query/Brands/Query */
export class BrandsQuery {
    getBrandsQuery(filter: GQLMageplazaBrandsFilterInput, id: string, pageSize: number): Query<'mpbrand', GQLMageplazaBrandsOutput> {
        return new Query<'mpbrand', GQLMageplazaBrandsOutput>('mpbrand')
            .addArgument('filter', 'MageplazaBrandsFilterInput', filter)
            .addArgument('pageSize', 'Int', pageSize)
            .addArgument('storeId', 'Int', id)
            .addField(this._getBrandsItemField());
    }

    _getBrandsItemField(): Field<'items', BrandItems, true> {
        return new Field<'items', BrandItems, true>('items').addFieldList(this._getBrandsItemFieldList());
    }

    _getBrandsItemFieldList(): Array<
        Field<'brand_id', number> | Field<'image', string> | Field<'url_key', string> | Field<'value', string> | Field<'product_quantity', number>
        // eslint-disable-next-line prettier/prettier
        > {
        return [
            new Field<'brand_id', number>('brand_id'),
            new Field<'image', string>('image'),
            new Field<'url_key', string>('url_key'),
            new Field<'value', string>('value'),
            new Field<'product_quantity', number>('product_quantity'),
        ];
    }
}

export default new BrandsQuery();
