import React, { ElementType } from "react";
import ContractorStructuresList from "../component/ContractorStructuresList";

export const CONTRACTOR_SUBSTRUCTURES_TAB_ID = 'company-structure'

export const ContractorsDispatcher = import(
  /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
  '../store/Contractors/Contractors.dispatcher'
);

export const MyAccountTabList_containerProps = (originalMember: Object) => {
  return ({
    ...originalMember,
    [CONTRACTOR_SUBSTRUCTURES_TAB_ID]: {
      url: '/company-structure',
      tabName: 'Company structure',
      isFullUrl: true,
    }
  })
}

export const MyAccountComponent_renderMap = (originalMember: Record<string, ElementType>) => {
  return ({
    ...originalMember,
    'company-structure': () => <ContractorStructuresList />,
  })
}

export default {
  'PlugAndSell2/Route/MyAccount/Component': {
    'member-property': {
      renderMap: MyAccountComponent_renderMap
    }
  },
  'PlugAndSell2/Route/MyAccount/Container': {
    'static-member': {
      tabMap: MyAccountTabList_containerProps
    }
  }
};
