import { connect } from 'react-redux';

import { RootState } from 'Util/Store/Store.type';

import ExpandableContent from './ExpandableContent.component';
import { ExpandableContentContainerDispatchProps, ExpandableContentContainerMapStateProps } from './ExpandableContent.type';

/** @namespace PlugAndSell2/Component/ExpandableContent/Container/mapStateToProps */
export const mapStateToProps = (state: RootState): ExpandableContentContainerMapStateProps => ({
    device: state.ConfigReducer.device,
});

/** @namespace PlugAndSell2/Component/ExpandableContent/Container/mapDispatchToProps */
export const mapDispatchToProps = (): ExpandableContentContainerDispatchProps => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ExpandableContent);
