/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import Icons from 'Component/Icons';
import Image from 'Component/Image';
import Link from 'Component/Link';
import TextPlaceholder from 'Component/TextPlaceholder';

import { getPostUrl } from '../../util/Posts';
import { MAX_RECENT_POSTS } from './BlogRecentPosts.config';

import './BlogRecentPosts.style';

/** @namespace PlugAndSell/Blog/Component/BlogRecentPosts/Component */
export class BlogRecentPostsComponent extends PureComponent {
    static propTypes = {
        // eslint-disable-next-line react/forbid-prop-types
        posts: PropTypes.array.isRequired,
        isLoaded: PropTypes.bool.isRequired,
    };

    renderContents() {
        const { posts, isLoaded, isRecentCategoryPosts, isSidebar } = this.props;
        const post = {};

        if (!isLoaded) {
            return Array(MAX_RECENT_POSTS)
                .fill()
                .map((_, i) => this.renderCard(post, i, isRecentCategoryPosts, isSidebar));
        }

        return posts.map((post, i) => this.renderCard(post, i, isRecentCategoryPosts, isSidebar));
    }

    renderCard(post, key, isRecentCategoryPost, isSidebar) {
        const { title, first_image } = post || {};
        const postUrl = getPostUrl(post);

        if (isRecentCategoryPost) {
            return (
                <li block="BlogRecentPosts" elem="PostCard" key={key} mods={{isRecentCategoryPost: true}}>
                    <Link to={postUrl} title={title}>
                        <h3 block="BlogRecentPosts" elem="PostTitle">
                            <TextPlaceholder mix={{ block: 'BlogRecentPosts', elem: 'PostTitlePlaceholder' }} content={title} length="short" />
                        </h3>
                    </Link>
                </li>
            );
        }

        if(isSidebar) {
            return (
                <div block="BlogRecentPosts" elem="PostCard" key={key}>
                    <Link to={postUrl} title={title} mix={{ block: 'BlogRecentPosts', elem: 'ImageWrapper' }}>
                        <Image src={first_image} ratio="16x9" />
                    </Link>
                    <div block="BlogRecentPosts" elem="CardWrapper">
                        <Link to={postUrl} title={title}>
                            <h3 block="BlogRecentPosts" elem="PostTitle">
                                <TextPlaceholder mix={{ block: 'BlogRecentPosts', elem: 'PostTitlePlaceholder' }} content={title} length="short" />
                            </h3>
                        </Link>
                    </div>
                </div>
            );    
        }

        return (
            <div block="BlogRecentPosts" elem="PostCard" mods={{ big: key === 0}} key={key}>
                <Link to={postUrl} title={title} mix={{ block: 'BlogRecentPosts', elem: 'ImageWrapper' }}>
                    <Image src={first_image} ratio="16x9" />
                </Link>
                <div block="BlogRecentPosts" elem="CardWrapper">
                    <Link to={postUrl} title={title}>
                        <h3 block="BlogRecentPosts" elem="PostTitle">
                            <TextPlaceholder mix={{ block: 'BlogRecentPosts', elem: 'PostTitlePlaceholder' }} content={title} length="short" />
                        </h3>
                    </Link>
                    <Link to={postUrl} block="BlogRecentPosts" elem="ReadMore">
                        {__('Read more')}
                        <Icons name="arrow_right" />
                    </Link>
                </div>
            </div>
        );
    }

    render() {
        const { isRecentCategoryPosts, categoryTitle, isSidebar } = this.props;

        if (isRecentCategoryPosts) {
            return (
                <ol block="BlogRecentPosts" elem="Wrapper" mods={{isRecentCategoryPosts: true}}>
                    <h3 block="BlogRecentPosts" elem="Title">
                        {__('Last from category %s:', categoryTitle)}
                    </h3>
                    {this.renderContents()}
                </ol>
            );    
        }

        return (
            <>
                <h3 block="BlogRecentPosts" elem="Title">
                    {__('Recent Posts')}
                </h3>
                <div block="BlogRecentPosts" elem="Wrapper" mods={{ isSidebar }}>
                    {this.renderContents()}
                </div>
            </>
        );
    }
}

export default BlogRecentPostsComponent;
