import CartQuery from 'Query/Cart.query';
import { Field } from 'Util/Query';

/** @namespace Advox/Storefront/Query/Consents/Query */
export class ConsentsQuery {
    getConsents(page) {
        return new Field('getConsents')
            .setAlias('consents')
            .addArgument('page', 'PageType!', page)
            .addFieldList(this._getConsentsFields());
    }

    getCustomerConsents() {
        return new Field('getCustomerConsents')
            .setAlias('consents')
            .addFieldList(this._getCustomerConsentsFields());
    }

    setConsentsOnCheckout({ consentIds, quoteId }) {
        const field = new Field('setConsentsOnCheckout').addArgument('consentIds', '[String!]!', consentIds)
            .addField(CartQuery._getCartUpdateField());

        if (quoteId) {
            field.addArgument('guestCartId', 'String', quoteId);
        }

        return field;
    }

    setConsentsForCustomer({ consentsToSet, customerId }) {
        const field = new Field('setConsentsOnCheckout')
            .addArgument('consents_to_set', '[ConsentsToSet!]!', consentsToSet)
            .addField(this._setConsentsForCustomerField());

        if (customerId) {
            field.addArgument('customer_id', 'Int', customerId);
        }

        return field;
    }

    _getConsentsFields() {
        return ['consent_id', 'description', 'is_required', 'is_checkbox'];
    }

    _getCustomerConsentsFields() {
        return ['consent_id', 'description', 'name', 'description'];
    }

    _setConsentsForCustomerField() {
        return new Field('consents').addFieldList(this._setConsentsForCustomerFields());
    }

    _setConsentsForCustomerFields() {
        return [
            'consents_id',
            'is_checked'
        ];
    }
}

export default new ConsentsQuery();
