import { Children } from 'Type/Common.type';

export interface TypographyHeaderProps {
    tag: string;
    variant: Variant;
    fontWeight: FontWeight;
    children: Children | string;
    mix?: object;
    itemProp?: string;
}

export enum Variant {
    EXTRA_LARGE = 'extra_large',
    LARGE = 'large',
    MEDIUM = 'medium',
    NORMAL = 'normal',
    SMALL = 'small',
    EXTRA_SMALL = 'extra_small',
}

export enum FontWeight {
    THIN = 'thin',
    EXTRA_LIGHT = 'extra_light',
    LIGHT = 'light',
    REGULAR = 'regular',
    MEDIUM = 'medium',
    SEMI_BOLD = 'semi_bold',
    BOLD = 'bold',
    EXTRA_BOLD = 'extra_bold',
    BLACK = 'black',
}
