import { PureComponent } from 'react';
import { connect } from 'react-redux';
import { RmaDetailsContainerProps, RmaDetailsContainerState, RmaDetailsDataSource, RmaDetailsScreenType } from './RmaDetails.type';
import RmaDetails from './RmaDetails.component';
import { fetchQuery } from 'Util/Request/Query';
import { getQueryParam } from 'Util/Url';
import history from 'Util/History';
import RmaQuery from '../../query/Rma.query';
import { OrderItem } from 'Query/Order.type';
import { GQLCurrencyEnum } from 'Type/Graphql.type';
import { GetRmaRequest } from '../../query/Rma.type';
import { scrollToTop } from 'Util/Browser';
import { RootState } from 'Util/Store/Store.type';
import { RMA_AVAILABLE_RETURNS_ROUTE, RMA_MY_RETURNS_ROUTE } from '../../plugin/MyAccount.plugin';
import { ReactElement } from 'Type/Common.type';
import { RMA_AVAILABLE_RETURNS_ORDER } from '../RmaMyReturns/RmaMyReturns.config';

export const mapStateToProps = (state: RootState) => ({
  customer: state.MyAccountReducer.customer,  
})

export const mapDispatchToProps = () => ({})  

class RmaDetailsContainer extends PureComponent<RmaDetailsContainerProps, RmaDetailsContainerState> {
  state = {
    order: null,
    request: null,
    requestStatuses: {}
  }

  containerProps(): { dataSource: RmaDetailsDataSource | null, type: RmaDetailsScreenType } {
    const { type } = this.props;

    return ({
      dataSource: this._getDataSource(),
      type, 
    })
  }

  containerFunctions(): { onClose?: () => void } {
    return ({
      onClose: this.onClose.bind(this)
    })
  }

  componentDidMount(): void {
    scrollToTop({behavior: 'smooth'});
    this.fetchProperResource();
  }

  _getDataSource(): RmaDetailsDataSource | null {
    const { order, request, requestStatuses } = this.state
    const { customer, type } = this.props;

    if (order && type === RmaDetailsScreenType.FORM) {
      const assertedOrder = order as Partial<OrderItem> 
      
      return ({
        id: assertedOrder.id || '',
        orderId: assertedOrder.increment_id || '',
        //@ts-ignore
        total: assertedOrder.total?.grand_total,
        purchaser: `${assertedOrder.shipping_address?.firstname} ${assertedOrder.shipping_address?.lastname}`,
        email: customer.email,
        shippingAddress: assertedOrder.shipping_address,
        items: assertedOrder.items?.length ? assertedOrder.items?.map((item) => ({
          itemId: item.id,
          itemSku: item.product_sku,
          itemPrice: { 
            value: Number(item.product_sale_price?.value) || NaN,
            currency: item.product_sale_price?.currency || GQLCurrencyEnum.USD,
          },
          itemQty: item.quantity_ordered,
          itemThumbnail: item.product_small_image
        })) : null
      })
    } else if (request && type === RmaDetailsScreenType.OVERVIEW) {
      const assertedRequest = request as Partial<GetRmaRequest>

      return ({
        id: assertedRequest.id || '',
        orderId: assertedRequest.increment_id || '',
        purchaser: assertedRequest.name_surname || '---',
        dateRequested: assertedRequest.date_created || '---',
        returnAddress: {
          name: assertedRequest.name_surname || '---',
          account_no: assertedRequest.account_no || '---',
          phone_number: assertedRequest.phone || '---',
        },
        status: Object.keys(requestStatuses).length === 0 ? '' : requestStatuses[String(assertedRequest.status) as keyof typeof requestStatuses],
        items: assertedRequest.request_items?.length ? assertedRequest.request_items?.map((item) => ({
          itemId: item.sku,
          itemSku: item.sku,
          itemName: item.name,
          itemDesc: '---',
          itemQty: Number(item.qty),
          itemRequestQty: item.request_qty,
          itemReason: item.reason,
          itemType: assertedRequest.decision,
          itemPrice: item.price,
          itemThumbnail: item.thumbnail,
          itemImages: item.images
        }
        )) : null
      })
    }

    return null;
  }

  async fetchProperResource() {
    const { type } = this.props;

    if (type === RmaDetailsScreenType.FORM) {
      const url = decodeURIComponent(getQueryParam('orderId', history.location, { noDecode: true }) || '')
      const orderId = Number(window.atob(url))
      
      if (!orderId && orderId !== 0) {
        return;
      }

      const { customer: { orders: { items } } } = await fetchQuery(RmaQuery.getOrder(orderId))
      this.setState({ order: items[0] })
    } else if (type === RmaDetailsScreenType.OVERVIEW) {
      const rmaId = getQueryParam('rmaId', history.location)
      
      if (!rmaId) {
        return;
      }

      const { getRmaRequest }  = await fetchQuery(RmaQuery.getRequestQuery(rmaId))
      const { getRmaStatuses }  = await fetchQuery(RmaQuery.getStatusListQuery())

      this.setState({ request: getRmaRequest, requestStatuses: JSON.parse(getRmaStatuses) })
    }
  }

  onClose(): void {
    const { type } = this.props;

    if (type === RmaDetailsScreenType.FORM) {
      history.push(RMA_AVAILABLE_RETURNS_ROUTE);
    } else if (type === RmaDetailsScreenType.OVERVIEW) {
      history.push(RMA_MY_RETURNS_ROUTE);
    }
  }

  render(): ReactElement {
    return (
      <RmaDetails {...this.containerProps()} {...this.containerFunctions()}/>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RmaDetailsContainer);