import { PureComponent } from 'react';

import Icons from 'Component/Icons';
import { IconName } from 'Component/Icons/Icons.type';
import { MobilePopupComponentProps } from 'Component/MobilePopup/MobilePopup.type';
import { Variant } from 'Component/TypographyHeader/TypographyHeader.type';
import { ReactElement } from 'Type/Common.type';

import TypographyHeader from '../TypographyHeader';

import './MobilePopup.style';

/** @namespace PlugAndSell2/Component/MobilePopup/Component */
export class MobilePopupComponent extends PureComponent<MobilePopupComponentProps> {
    static defaultProps = {
        isSpacing: false,
        icon: '',
    };

    render(): ReactElement {
        const { title, handleMobilePopup, isOpen, icon, children, isSpacing } = this.props;

        return (
            <div block="MobilePopup" mods={{ visible: isOpen }}>
                <div block="MobilePopup" elem="TopBar">
                    <div block="MobilePopup" elem="Title">
                        {icon && <Icons name={IconName[icon.toUpperCase() as keyof typeof IconName]} width="24" height="24" fill="#fff" />}
                        <TypographyHeader variant={Variant.SMALL}>{title}</TypographyHeader>
                    </div>
                    <div block="MobilePopup" elem="CloseBtn" onClick={handleMobilePopup}>
                        <Icons name={IconName.CLOSE} width="30" height="30" fill="#fff" />
                    </div>
                </div>
                <div block="MobilePopup" elem="Content" mods={{ noSpacing: isSpacing }}>
                    {children}
                </div>
            </div>
        );
    }
}

export default MobilePopupComponent;
