import { PureComponent } from 'react';
import Popup from 'Component/Popup';
import { ReactElement } from 'Type/Common.type';
import { CONTRACTOR_STRUCTURE_CREATE_POPUP_ID } from './ContractorStructureCreate.config';
import { ContractorStructureCreateComponentProps } from './ContractorStructureCreate.type';
import ContractorStructureCreateForm from '../ContractorStructureCreateForm';

class ContractorStructureCreate extends PureComponent<ContractorStructureCreateComponentProps> {
  renderPopup(): ReactElement {
    const { onContractorStructureCreated } = this.props;
    
    return (
      <Popup id={CONTRACTOR_STRUCTURE_CREATE_POPUP_ID}>
        <ContractorStructureCreateForm onCreateSuccess={onContractorStructureCreated} />
      </Popup>
    )
  }

  render(): ReactElement {
    const { handleCreateStrctureClick } = this.props;

    return (
      <div elem="ContractorStructureCreate">        
        <button onClick={handleCreateStrctureClick}>Create</button>
        {this.renderPopup()}
      </div>
    );
  }
}

export default ContractorStructureCreate;