import React, { ElementType } from 'react';
import { usePopper } from 'react-popper';

export const withPopper =
    (Component: ElementType, options = {}) =>
        (props = {}) => {
            const [referenceElement, setReferenceElement] = React.useState(null);
            const [popperElement, setPopperElement] = React.useState(null);
            const { placement = 'bottom-start', strategy = 'absolute', modifiers = [], ...rest } = { ...props, ...options };

            const popper = usePopper(referenceElement, popperElement, {
                placement,
                strategy,
                modifiers,
            });

            return (
                <Component
                    {...rest}
                    popper={popper}
                    setReferenceElement={setReferenceElement}
                    setPopperElement={setPopperElement}
                />
            );
        };
