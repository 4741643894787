import { Dispatch } from 'redux';

import { updateNoMatch } from 'Store/NoMatch/NoMatch.action';
/**
 * NoMatch Dispatcher
 * @class NoMatchDispatcher
 * @namespace PlugAndSell2/Store/NoMatch/Dispatcher */
export class NoMatchDispatcher {
    updateNoMatch(dispatch: Dispatch, options: { noMatch: boolean }): void {
        const { noMatch } = options;

        dispatch(updateNoMatch(noMatch));
    }
}

export default new NoMatchDispatcher();
