import { PureComponent } from 'react';

import Icons from 'Component/Icons';
import { IconName } from 'Component/Icons/Icons.type';

import { SliderNextArrowComponentProps } from './SliderNextArrow.type';

import './SliderNextArrow.style.scss';

/** @namespace PlugAndSell2/Component/SliderNextArrow/Component */
export class SliderNextArrowComponent extends PureComponent<SliderNextArrowComponentProps> {
    render() {
        const { onClick, className } = this.props;
        const isDisabled = className?.search('disabled') !== -1;

        return (
            <div block="SliderNextArrow" mods={{ disabled: isDisabled }} onClick={onClick}>
                <Icons name={IconName.ARROW_RIGHT} width="25" />
            </div>
        );
    }
}

export default SliderNextArrowComponent;
