import { PureComponent } from 'react';

import Image from 'Component/Image';
import { ImageRatio } from 'Component/Image/Image.type';
import TextPlaceholder from 'Component/TextPlaceholder';
import { ReactElement } from 'Type/Common.type';
import { formatPrice } from 'Util/Price';
import { getProductUnit } from 'Util/Product';
import { getDrumThreshold } from 'Util/Product/Extract';

import drumImg from '../../assets/images/drum.png';
import { ProductPackagingNoticeComponentProps } from './ProductPackagingNotice.type';

import './ProductPackagingNotice.style.scss';

/** @namespace PlugAndSell2/Component/ProductPackagingNotice/Component */
export class ProductPackagingNoticeComponent extends PureComponent<ProductPackagingNoticeComponentProps> {
    getContent(): ReactElement {
        const { product, packaging } = this.props;

        const drumThreshold = getDrumThreshold(product);
        const isPackagingFree = packaging.price?.minimum_price.final_price.value === 0;

        if (isPackagingFree && !drumThreshold) {
            return (
                <span block="ProductPackagingNotice" elem="Content">
                    <span block="ProductPackagingNotice" elem="ContentPrefix">
                        {__('Free drum')}
                    </span>
                </span>
            );
        }

        if (isPackagingFree && drumThreshold) {
            return (
                <span block="ProductPackagingNotice" elem="Content">
                    <span block="ProductPackagingNotice" elem="ContentPrefix">
                        {__('Free')}
                    </span>{' '}
                    {__('drum over')} {drumThreshold} {getProductUnit(product)}
                </span>
            );
        }

        return (
            <span block="ProductPackagingNotice" elem="Content">
                <span block="ProductPackagingNotice" elem="ContentPrefix">
                    + {formatPrice(packaging.price?.minimum_price.final_price.value || NaN, packaging.price?.minimum_price.final_price.currency)}{' '}
                </span>
                {__('for a drum over')} {drumThreshold} {getProductUnit(product)}
            </span>
        );
    }

    renderInfo(): ReactElement {
        const { packaging } = this.props;

        const isPackagingFree = packaging.price?.minimum_price.final_price.value === 0;

        let title = `${__('Packaging cost')}`;

        if (isPackagingFree) {
            title = __('Packaging is free');
        }

        return <span block="ProductPackagingNotice" elem="Info" title={title} />;
    }

    renderNotice(): ReactElement {
        const { packaging } = this.props;

        if (!packaging.price) {
            return <TextPlaceholder />;
        }

        return (
            <span block="ProductPackagingNotice" elem="Notice">
                <Image height="40px" ratio={ImageRatio.IMG_CUSTOM} src={drumImg} alt="drum icon" />
                {this.getContent()}
                {this.renderInfo()}
            </span>
        );
    }

    render() {
        return <div block="ProductPackagingNotice">{this.renderNotice()}</div>;
    }
}

export default ProductPackagingNoticeComponent;
