import { AnyAction } from 'redux';

import { CustomerOrders, OrderItem, SearchResultPageInfo } from 'Query/Order.type';

export enum OrderActionType {
    GET_ORDER_LIST = 'GET_ORDER_LIST',
    SET_ORDER_LOADING_STATUS = 'SET_ORDER_LOADING_STATUS',
}

export interface OrderList {
    items: OrderItem[];
    pageInfo: SearchResultPageInfo;
}

export interface GetOrderListAction extends AnyAction {
    type: OrderActionType.GET_ORDER_LIST;
    orderList: CustomerOrders;
    status?: boolean;
}

export interface SetLoadingStatusAction extends AnyAction {
    type: OrderActionType.SET_ORDER_LOADING_STATUS;
    status?: boolean;
}

export type OrderAction = GetOrderListAction | SetLoadingStatusAction;

export interface OrderStore {
    orderList: Partial<OrderList>;
    isLoading?: boolean;
}

declare module 'Util/Store/Store.type' {
    export interface RootState {
        OrderReducer: OrderStore;
    }
}
