import { ComponentType, PureComponent } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { goToPreviousNavigationState } from 'Store/Navigation/Navigation.action';
import { NavigationType } from 'Store/Navigation/Navigation.type';
import { hideActiveOverlay } from 'Store/Overlay/Overlay.action';
import { RootState } from 'Util/Store/Store.type';

import OverlayComponent from './Overlay.component';
import {
    OverlayComponentProps,
    OverlayComponentPropsKey,
    OverlayContainerMapDispatchProps,
    OverlayContainerMapStateProps,
    OverlayContainerProps,
} from './Overlay.type';

/** @namespace PlugAndSell2/Component/Overlay/Container/mapStateToProps */
export const mapStateToProps = (state: RootState): OverlayContainerMapStateProps => ({
    activeOverlay: state.OverlayReducer.activeOverlay,
    areOtherOverlaysOpen: state.OverlayReducer.areOtherOverlaysOpen,
    isMobile: state.ConfigReducer.device.isMobile,
});

/** @namespace PlugAndSell2/Component/Overlay/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch: Dispatch): OverlayContainerMapDispatchProps => ({
    hideActiveOverlay: () => dispatch(hideActiveOverlay()),
    goToPreviousNavigationState: () => dispatch(goToPreviousNavigationState(NavigationType.TOP_NAVIGATION_TYPE)),
});

/** @namespace PlugAndSell2/Component/Overlay/Container */
export class OverlayContainer extends PureComponent<OverlayContainerProps> {
    containerProps(): Pick<OverlayComponentProps, OverlayComponentPropsKey> {
        const { hideActiveOverlay, goToPreviousNavigationState } = this.props;

        return {
            ...this.props,
            hideActiveOverlay,
            goToPreviousNavigationState,
        };
    }

    render() {
        return <OverlayComponent {...this.containerProps()} />;
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(OverlayContainer as unknown as ComponentType<OverlayComponentProps>);
