import { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import ContractorQuery from '../../query/Contractors/Contractors.query';
import { showNotification } from 'Store/Notification/Notification.action';
import { getQueryParam } from 'Util/Url';

import ContractorStructuresList from './ContractorStructuresList.component';
import { NotificationType } from 'Store/Notification/Notification.type';
import { RootState } from 'Util/Store/Store.type';
import { ContractorStructuresListComponentProps, ContractorStructuresListContainerProps, ContractorStructuresListContainerState } from './ContractorStructuresList.type';
import { fetchQuery } from 'Util/Request/Query';
import { getErrorMessage } from 'Util/Request/Error';
import { NetworkError } from 'Type/Common.type';
import { withRouter } from 'react-router-dom';
import { showPopup } from 'Store/Popup/Popup.action';
import { ContractorStructurePopupPayload } from '../ContractorStructureCreate/ContractorStructureCreate.type';
import { fetchMutation } from 'Util/Request/Mutation';
import { GetStructureUsersQuery, StructureOutput } from '../../query/Contractors/Contractors.type';

/** @namespace Pwabb/Component/ContractorStructuresList/Container/mapStateToProps */
export const mapStateToProps = (state: RootState) => ({
    contractorForLoggedInUser: state.ContractorsReducer.contractorForLoggedInUser,
    device: state.ConfigReducer.device,
});

/** @namespace Pwabb/Component/ContractorStructuresList/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch: Dispatch) => ({
    showNotification: (type: NotificationType, message: string) => dispatch(showNotification(type, message)),
    showPopup: (id: string, payload: ContractorStructurePopupPayload) => dispatch(showPopup(id, payload)),
});

/** @namespace Pwabb/Component/ContractorStructuresList/Container */
export class ContractorStructuresListContainer extends PureComponent<ContractorStructuresListContainerProps, ContractorStructuresListContainerState> {
    state = {
        isLoading: false,
        data: [],
        activeRows: [],
        pageInfo: { total_pages: 0 },
    };

    componentDidMount() {
        this.getSubstructuresList()
    }

    componentDidUpdate(prevProps: ContractorStructuresListContainerProps) {
        const { location: prevLocation } = prevProps;
        const { location } = this.props;

        if ((+getQueryParam('page', prevLocation) || 1) !== (+getQueryParam('page', location) || 1)) {
            this.getSubstructuresList();
        }
    }

    containerProps(): Pick<ContractorStructuresListComponentProps, 'activeRows' | 'contractorForLoggedInUser' | 'device' | 'data' | 'pageInfo' | 'isLoading'> {
        const { activeRows, data, pageInfo, isLoading } = this.state;
        const { contractorForLoggedInUser, device } = this.props;

        return ({
            activeRows,
            contractorForLoggedInUser,
            device,
            data,
            pageInfo,
            isLoading,
        });
    }

    containerFunctions() {
      return ({
        onDeleteClick: this.handleOnStructureDeleted.bind(this),
        onRowClick: this.handleRowClicked.bind(this),
        onStructureCreated: this.handleOnStructureCreated.bind(this),
        onStructureUpdated: this.handleOnStructureUpdated.bind(this),
        onStructureDeleted: this.handleOnStructureDeleted.bind(this),
      })
    }

    async getStructureUsers(structureId: number): Promise<[String] | null> {
      const { showNotification } = this.props;

      try {
          const data = await fetchQuery(
              ContractorQuery.getStructureUsersQuery(structureId)
          );

          return data.getStructureUsers;

      } catch (error) {
          showNotification(NotificationType.ERROR, getErrorMessage(error as unknown as NetworkError));
      }

      return null
    }

    async getSubstructuresList() {
        const { location, showNotification } = this.props;

        this.setState({
            isLoading: true,
        });

        try {
            const data = await fetchQuery(
                ContractorQuery.getContractorSubstructuresQuery(+getQueryParam('page', location) || 1, 10)
            );
            const { items, page_info: pageInfo } = data.getSubstructures || {};

            let finalItems: (StructureOutput & { users: String[] })[] = [];
            
            for (const item of items) {
              const users = await this.getStructureUsers(item.id);
              finalItems.push({ ...item, users: users || [] })
            }

            this.setState({
                data: finalItems,
                pageInfo
            });
        } catch (error) {
            showNotification(NotificationType.ERROR, getErrorMessage(error as unknown as NetworkError));
        } finally {
            this.setState({
                isLoading: false,
            });
        }
    }

    async handleOnStructureDeleted() {
      await this.getSubstructuresList();
    }

    async handleOnStructureCreated() {
      await this.getSubstructuresList()
    }

    async handleOnStructureUpdated() {
      await this.getSubstructuresList()
    }

    handleRowClicked(data: StructureOutput) {
      this.toggleRow(data.id);
    }

    toggleRow(id: number): void {
      const { activeRows } = this.state;

      // @ts-ignore
      if (activeRows.includes(id)) {
        this.setState({
          activeRows: activeRows.filter((rowId) => rowId !== id)
        })
      } else {
        this.setState({
          activeRows: [...activeRows, id]
        })
      }
    }

    render() {
        return <ContractorStructuresList {...this.containerProps()} {...this.containerFunctions()} />;
    }
}

// @ts-ignore
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ContractorStructuresListContainer));
