import { Field, Query } from '@tilework/opus';

import { Money, OptimizedProductImage, PriceRange, ProductDiscount, ProductItemFields, ProductLabel, TierPrice } from 'Query/ProductList.type';

import { PriceTax, TaxMoney, WidgetOutput, WidgetOutputItems } from './CatalogWidget.type';
import { ProductListQuery } from './ProductList.query';

/** @namespace PlugAndSell2/Query/CatalogWidget/Query */
export class CatalogWidgetQuery extends ProductListQuery {
    getCatalogWidgetQuery(widgetCode: string, options = {}): Query<'catalogWidget', WidgetOutput> {
        this.options = options;

        return new Query<'catalogWidget', WidgetOutput>('catalogWidget')
            .addArgument('widget_code', 'String!', widgetCode)
            .addFieldList(this._getCatalogWidgetFields());
    }

    _getCatalogWidgetFields(): Array<Field<'title', string> | Field<'widget_code', string> | Field<'items', WidgetOutputItems, true>> {
        return [new Field<'title', string>('title'), new Field<'widget_code', string>('widget_code'), this._getCatalogWidgetItemsField()];
    }

    _getPriceExcludingTaxField(): Field<'price_excluding_tax', PriceTax> {
        return new Field<'price_excluding_tax', PriceTax>('price_excluding_tax').addFieldList([
            this._getFinalPriceField(),
            this._getRegularPriceField(),
        ]);
    }

    _getPriceIncludingTaxField(): Field<'price_including_tax', PriceTax> {
        return new Field<'price_including_tax', PriceTax>('price_including_tax').addFieldList([
            this._getFinalPriceField(),
            this._getRegularPriceField(),
        ]);
    }

    _getFinalPriceField() {
        return new Field<'final_price', TaxMoney>('final_price').addFieldList(this._getPriceField());
    }

    _getRegularPriceField() {
        return new Field<'regular_price', TaxMoney>('regular_price').addFieldList(this._getPriceField());
    }

    _getPriceField(): Array<Field<'currency', string> | Field<'value', number>> {
        return [new Field<'currency', string>('currency'), new Field<'value', number>('value')];
    }

    _getCatalogWidgetItemsField(): Field<'items', WidgetOutputItems, true> {
        return new Field<'items', WidgetOutputItems, true>('items').addFieldList(this._getCatalogWidgetItemsFieldList());
    }

    _getCatalogWidgetItemsFieldList(): Array<
        | Field<'sku', string>
        | Field<'name', string>
        | Field<'type_id', string>
        | Field<'url_key', string>
        | Field<'url', string>
        | Field<'special_to_date', string>
        | Field<'id', number>
        | Field<'alternative_hover_image', string>
        | Field<'small_image', OptimizedProductImage>
        | Field<'labels', ProductLabel, true>
        | Field<'price_range', PriceRange>
        | Field<'price_tiers', TierPrice, true>
        // eslint-disable-next-line prettier/prettier
        > {
        return [
            new Field<'sku', string>('sku'),
            new Field<'name', string>('name'),
            new Field<'type_id', string>('type_id'),
            new Field<'url_key', string>('url_key'),
            new Field<'url', string>('url'),
            new Field<'special_to_date', string>('special_to_date'),
            new Field<'id', number>('id'),
            new Field<'alternative_hover_image', string>('alternative_hover_image'),
            this._getLabelField(),
            this._getProductSmallField(),
            this._getPriceRangeField(),
            this._getTierPricesField(),
        ];
    }

    _getProductInterfaceFields(isVariant: boolean, isForLinkedProducts = false): ProductItemFields {
        const { isPlp = false, noAttributes = false, noVariants = false, noVariantAttributes = false } = this.options;

        const fields: ProductItemFields = [
            new Field<'uid', string>('uid'),
            new Field<'id', number>('id'),
            new Field<'sku', string>('sku'),
            new Field<'name', string>('name'),
            new Field<'type_id', string>('type_id'),
            this._getPriceRangeField(),
        ];

        if (!(isPlp && isVariant)) {
            fields.push(
                this._getProductImageField(),
                this._getProductThumbnailField(),
                this._getProductSmallField(),
                this._getShortDescriptionField(),
                new Field<'special_from_date', string>('special_from_date'),
                new Field<'special_to_date', string>('special_to_date'),
                this._getTierPricesField()
            );
        }

        if ((!isVariant && !noAttributes) || (isVariant && !noVariantAttributes && !noVariants)) {
            fields.push(this._getAttributesField(isVariant, false));
        }

        if (!isVariant) {
            fields.push(
                new Field<'url', string>('url'),
                this._getUrlRewritesFields(),
                this._getReviewCountField(),
                this._getRatingSummaryField(),
                this._getCustomizableProductFragment()
            );

            if (!noVariants) {
                fields.push(this._getConfigurableProductFragment(), this._getBundleProductFragment(), this._getGroupedProductItems());
            }
        }

        if (isForLinkedProducts) {
            fields.push(this._getProductLinksField());
        }

        return fields;
    }

    _getTierPricesFields(): Array<Field<'discount', ProductDiscount> | Field<'final_price', Money>> {
        return [this._getDiscountField(), this._getFinalPriceField()];
    }
}

export default new CatalogWidgetQuery();
