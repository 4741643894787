// import { useEffect, useState } from 'preact/hooks';
import { ComponentType, lazy } from 'react';

import { EV_PRIORITY_LOAD_END } from './Config';
import { waitForPriorityLoad } from './LowPriorityLoad';

/** @namespace PlugAndSell2/Util/Request/LowPriorityRender/lowPriorityLazy */
export const lowPriorityLazy = (callback: () => Promise<{ default: ComponentType<any> }>) =>
    lazy(async () => {
        await waitForPriorityLoad();

        const ev = new Event(EV_PRIORITY_LOAD_END);
        document.dispatchEvent(ev);

        return callback();
    });

/** @namespace PlugAndSell2/Util/Request/LowPriorityRender/AfterPriority */

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function AfterPriority({ children, fallback }: { children: JSX.Element; fallback?: JSX.Element }): JSX.Element {
    // const [isPriorityLoaded, setIsPriorityLoaded] = useState(window.isPriorityLoaded);

    // function onPriorityLoad() {
    //     setIsPriorityLoaded(true);
    // }

    // useEffect(() => {
    //     document.addEventListener(EV_PRIORITY_LOAD_END, onPriorityLoad, { once: true });

    //     return () => {
    //         document.removeEventListener(EV_PRIORITY_LOAD_END, onPriorityLoad);
    //     };
    // }, []);

    // if (!isPriorityLoaded && fallback) {
    //     return fallback;
    // }

    return children;
}
