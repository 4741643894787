import { PureComponent } from 'react';

import Notification from 'Component/Notification';
import { ReactElement } from 'Type/Common.type';

import { NotificationListComponentProps } from './NotificationList.type';

import './NotificationList.style';

/**
 * Notification List
 * @class NotificationList
 * @namespace PlugAndSell2/Component/NotificationList/Component */
export class NotificationListComponent extends PureComponent<NotificationListComponentProps> {
    render(): ReactElement {
        const { onHideNotification, notifications } = this.props;

        return (
            <div block="NotificationList">
                {Object.keys(notifications).map((id) => (
                    <Notification key={id} notificationId={id} notification={notifications[id]} onHideNotification={onHideNotification} />
                ))}
            </div>
        );
    }
}

export default NotificationListComponent;
