import { PureComponent } from 'react';
import ProductTags from './ProductTags.component';
import { ProductTagsComponentProps, ProductTagsContainerProps, ProductTagsContainerState } from './ProductTags.type';
import { fetchQuery } from 'Util/Request/Query';
import ProductTagsQuery from '../../query/ProductTags/ProductTags.query';

export class ProductTagsContainer extends PureComponent<ProductTagsContainerProps, ProductTagsContainerState> {
  state = {
    isLoading: false,
    tags: null
  }

  containerProps(): ProductTagsComponentProps { 
    const { isLoading, tags } = this.state;

    return ({
      isLoading,
      tags
    })
  }

  componentDidMount(): void {
    this.getProductTags()
  }

  async getProductTags(): Promise<void> {
    const { product } = this.props;

    if (!product.id) {
      return;
    }

    try {
      const { productTagsByProductId } = await fetchQuery([ProductTagsQuery.getProductTagsQuery(product.id)])
      this.setState({ tags: productTagsByProductId.items })
    } catch(e) {
      console.log('Error while fetching product tags:', e)
    } finally {
      this.setState({ isLoading: false })
    }
  }

  render() {
    return (
      <ProductTags {...this.containerProps()} />
    );
  }
}

export default ProductTagsContainer;