import PropTypes from 'prop-types';
import { ChangeEvent, PureComponent } from 'react';

import Field from 'Component/Field';
import { FieldType } from 'Component/Field/Field.config';
import { PageSizeComponentProps } from './PageSize.type';

/** @namespace KratkiPwa/Component/PageSize/Component/PageSizeComponent */
export class PageSizeComponent extends PureComponent<PageSizeComponentProps> {
  render() {
    const { defaultValue, options, onPageSizeSelected } = this.props;

    return (
      <Field
        type={FieldType.SELECT}
        options={options}
        attr={{
          id: 'category-sort',
          name: 'category-sort',
          value: defaultValue,
          noPlaceholder: true,
        }}
        events={{
            onChange: (e: ChangeEvent<HTMLInputElement> | string) => { typeof e === 'object' ? onPageSizeSelected(Number(e.target.value)) : onPageSizeSelected(Number(e)) },
        }}
        label={__('List size')}
        isSortSelect
      />
    );
  }
}

export default PageSizeComponent;
