import { PureComponent } from 'react';
import Popup from 'Component/Popup';
import { ReactElement } from 'Type/Common.type';
import { CONTRACTOR_STRUCTURE_UPDATE_POPUP_ID } from './ContractorStructureUpdate.config';
import { ContractorStructureUpdateComponentProps } from './ContractorStructureUpdate.type';
import ContractorStructureUpdateForm from '../ContractorStructureUpdateForm';

class ContractorStructureUpdate extends PureComponent<ContractorStructureUpdateComponentProps> {
  renderPopup(): ReactElement {
    const { onContractorStructureUpdated, structure } = this.props;
    
    return (
      <Popup id={`${CONTRACTOR_STRUCTURE_UPDATE_POPUP_ID}-${structure.id}`}>
        <ContractorStructureUpdateForm structure={structure} onUpdateSuccess={onContractorStructureUpdated} />
      </Popup>
    )
  }

  render(): ReactElement {
    const { handleUpdateStructureClick } = this.props;

    return (
      <div elem="ContractorStructureUpdate">        
        <button onClick={handleUpdateStructureClick}>
          {__('Update')}
        </button>
        {this.renderPopup()}
      </div>
    );
  }
}

export default ContractorStructureUpdate;