/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { MAX_RECENT_CATEGORY_POSTS, MAX_RECENT_POSTS, MAX_RECENT_SIDEBAR_POSTS } from './BlogRecentPosts.config';

import { showNotification } from 'Store/Notification/Notification.action';
import { prepareQuery } from 'Util/Query';
import { executeGet } from 'Util/Request/Request';

import BlogPostsQuery from '../../query/BlogPosts.query';
import BlogRecentPosts from './BlogRecentPosts.component';

/** @namespace PlugAndSell/Blog/Component/BlogRecentPosts/Container/mapStateToProps */
// eslint-disable-next-line no-unused-vars
export const mapStateToProps = (state) => ({});

/** @namespace PlugAndSell/Blog/Component/BlogRecentPosts/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    showNotification: (type, message) => dispatch(showNotification(type, message)),
});

/** @namespace PlugAndSell/Blog/Component/BlogRecentPosts/Container */
export class BlogRecentPostsContainer extends PureComponent {
    state = {}

    options = {}

    componentDidMount() {
        this._isMounted = true;
        this.requestPosts();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    __construct(props) {
        super.__construct(props);

        this.options = this.getOptions()

        this.state = {
            isLoaded: false,
            posts: [],
        };
    }

    getOptions() {
        const { category: { category_id = '' } = {}, recentCategoryPosts = false, isSidebar = false } = this.props; 

        if (recentCategoryPosts) {
            return {
                recentPosts: {
                    pageSize: MAX_RECENT_CATEGORY_POSTS,
                    sort: 'DESC',
                    getMedia: true,
                    filter: { category_id: { eq: `${category_id}` } }
                },
            };
        }

        return {
            recentPosts: {
                pageSize: isSidebar ? MAX_RECENT_SIDEBAR_POSTS : MAX_RECENT_POSTS,
                sort: 'DESC',
                getMedia: true,
            },
        };
    }

    /**
     * Prepares the blog posts query with specified options
     * @returns {[[*]]}
     */
    getQuery() {
        const { recentPosts } = this.options;

        return [BlogPostsQuery.getQuery(recentPosts)];
    }

    requestPosts() {
        executeGet(prepareQuery(this.getQuery()), 'RecentPosts')
            .then(
                /** @namespace PlugAndSell/Blog/Component/BlogRecentPosts/Container/BlogRecentPostsContainer/requestPosts/then/catch/executeGet/then */
                ({ posts: { items } }) => this.updatePostList(items, true)
            )
            .catch(
                /** @namespace PlugAndSell/Blog/Component/BlogRecentPosts/Container/BlogRecentPostsContainer/requestPosts/then/catch */
                (err) => {
                    this.updatePostList([], false);
                    showNotification('error', err);
                }
            );
    }

    /**
     * Maps posts to state and sets isLoaded status
     * @param posts
     * @param isLoaded
     */
    updatePostList(posts, isLoaded) {
        if (!this._isMounted) {
            return;
        }

        this.setState({
            isLoaded,
            posts,
        });
    }

    render() {
        const { posts, isLoaded } = this.state;
        const { category: { title = '' } = {}, recentCategoryPosts = false, isSidebar = false } = this.props; 

        return <BlogRecentPosts posts={posts} isLoaded={isLoaded} isRecentCategoryPosts={recentCategoryPosts} categoryTitle={title} isSidebar={isSidebar} />;
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(BlogRecentPostsContainer);
