import { Field, Query } from '@tilework/opus';
import { ProductTag, ProductTagsByProductIdOutput } from './ProductTags.type';


export class ProductTagsQuery {
  getProductTagsQuery(
    productId: number
  ):Query<'productTagsByProductId', ProductTagsByProductIdOutput> {
    const field = new Query<'productTagsByProductId', ProductTagsByProductIdOutput>('productTagsByProductId')
      .addArgument('product_id', 'Int', productId);

    field.addFieldList([this._getItemsField()]);

    return field;
  }

  _getItemsField(): Field<'items', ProductTag, true> {
    return new Field<'items', ProductTag, true>('items').addFieldList(this._getItemFields());
  }

  _getItemFields(): Array<
      | Field<'show_tag_name', string>
      | Field<'tag_name', string>
      | Field<'tag_code', string>
      | Field<'tag_url', string>
      | Field<'description', string>
      | Field<'sort_order', string>
      | Field<'image_url', string>
      | Field<'attribute_code', string>
  > {
    return ([
      new Field<'show_tag_name', string>('show_tag_name'),
      new Field<'tag_name', string>('tag_name'),
      new Field<'tag_code', string>('tag_code'),
      new Field<'tag_url', string>('tag_url'),
      new Field<'description', string>('description'),
      new Field<'sort_order', string>('sort_order'),
      new Field<'image_url', string>('image_url'),
      new Field<'attribute_code', string>('attribute_code')
    ])
  }
}

export default new ProductTagsQuery();