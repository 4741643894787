import { PureComponent } from 'react';
import SlickSlider from 'react-slick';

import {
    DEFAULT_SLICK_SETTINGS,
    SLIDER_DESKTOP_HEIGHT,
    SLIDER_MOBILE_HEIGHT,
    SLIDER_TABLET_HEIGHT,
} from 'Component/BannerSlider/BannerSlider.config';
import { BannerSliderComponentProps } from 'Component/BannerSlider/BannerSlider.type';
import Button from 'Component/Button';
import { ButtonVariant } from 'Component/Button/Button.type';
import Loader from 'Component/Loader';
import SliderNextArrow from 'Component/SliderNextArrow';
import SliderPrevArrow from 'Component/SliderPrevArrow';
import TypographyHeader from 'Component/TypographyHeader';
import { Variant } from 'Component/TypographyHeader/TypographyHeader.type';
import { Slide, Slider } from 'Query/Slider.type';
import { ReactElement } from 'Type/Common.type';
import { isEmpty } from 'Util/Common';
import media from 'Util/Media';

import './BannerSlider.style';

/** @namespace PlugAndSell2/Component/BannerSlider/Component */
export class BannerSliderComponent extends PureComponent<BannerSliderComponentProps> {
    static defaultProps = {
        settings: {},
    };

    renderSlide({
        body,
        header,
        image,
        tablet_image: tabletImage,
        mobile_image: mobileImage,
        cta_text: ctaText,
        cta_link: ctaLink,
    }: Partial<Slide>): ReactElement {
        return (
            <>
                {image && (
                    <picture>
                        {mobileImage ? <source media="(max-width: 767px)" srcSet={media(mobileImage)} /> : null}
                        {tabletImage ? <source media="(max-width: 1023px)" srcSet={media(tabletImage)} /> : null}
                        <img src={media(image)} alt={header} />
                    </picture>
                )}
                {(body || ctaText) && (
                    <div block="BannerSlider" elem="Content">
                        {body ? (
                            <TypographyHeader variant={Variant.MEDIUM} tag="h2">
                                {body}
                            </TypographyHeader>
                        ) : null}
                        {ctaText ? (
                            <Button asLink variant={ButtonVariant.OUTLINE_DARK} to={ctaLink}>
                                {ctaText}
                            </Button>
                        ) : null}
                    </div>
                )}
            </>
        );
    }

    renderSliderSlides(slider: Partial<Slider>): ReactElement {
        return slider?.slides?.map((attrs, index) => (
            <div key={`slider${index}`}>
                {attrs.cta_link ? (
                    <a block="BannerSlider" elem="Link" href={attrs.cta_link} target="__blank" rel="noreferrer nofollow">
                        {this.renderSlide(attrs)}
                    </a>
                ) : (
                    this.renderSlide(attrs)
                )}
            </div>
        ));
    }

    renderCustomPaging(i: number): JSX.Element {
        const { slider } = this.props;

        return (
            <div block="BannerSlider" elem="Page">
                {slider?.slides?.[i]?.header}
            </div>
        );
    }

    render(): ReactElement {
        const { slider, settings, isMobile, isTablet } = this.props;
        let loaderHeight = SLIDER_DESKTOP_HEIGHT;

        if (isMobile) {
            loaderHeight = SLIDER_MOBILE_HEIGHT;
        }

        if (isTablet) {
            loaderHeight = SLIDER_TABLET_HEIGHT;
        }

        if (!slider || isEmpty(slider?.slides)) {
            return <Loader displayRelative height={loaderHeight} />;
        }

        return (
            <div block="BannerSlider">
                <SlickSlider
                    customPaging={this.renderCustomPaging.bind(this)}
                    prevArrow={<SliderPrevArrow />}
                    nextArrow={<SliderNextArrow />}
                    {...DEFAULT_SLICK_SETTINGS}
                    {...settings}
                >
                    {this.renderSliderSlides(slider)}
                </SlickSlider>
            </div>
        );
    }
}

export default BannerSliderComponent;
