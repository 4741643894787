import { FieldType } from 'Component/Field/Field.config';
import { ValidationInputType } from 'Util/Validator/Config';

/** @namespace PlugAndSell2/Component/MyAccountPasswordForm/Form/myAccountPasswordForm */
export const myAccountPasswordForm = (passwordMinLength: number, passwordClassesLength: number) => [
    {
        label: __('Current password'),
        type: FieldType.PASSWORD,
        attr: {
            id: 'my-account-currentPassword',
            name: 'password',
            placeholder: __('Your current password'),
            'aria-label': __('Current password'),
        },
        addRequiredTag: true,
        validateOn: ['onChange'],
        validationRule: {
            inputType: ValidationInputType.PASSWORD,
            isRequired: true,
        },
    },
    {
        label: __('New password'),
        type: FieldType.PASSWORD,
        attr: {
            id: 'my-account-newPassword',
            name: 'newPassword',
            placeholder: __('Your new password'),
            'aria-label': __('New password'),
        },
        addRequiredTag: true,
        validateOn: ['onChange'],
        validationRule: {
            inputType: ValidationInputType.PASSWORD,
            isRequired: true,
            match: (value: string) => {
                const password = document.getElementById('my-account-currentPassword') as HTMLInputElement;

                return value && password.value !== value;
            },
            matchPassword: {
                passwordClassesLength,
            },
            customErrorMessages: {
                onMatchFail: __("New passwords can't be the same as old password!"),
            },
            range: {
                min: +passwordMinLength,
            },
        },
    },
];

export default myAccountPasswordForm;
