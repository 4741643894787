import { PureComponent } from 'react';

import ClickOutside from 'Component/ClickOutside';
import { FieldReactEvents } from 'Component/Field/Field.type';
import Icons from 'Component/Icons';
import { IconName } from 'Component/Icons/Icons.type';
import { ReactElement } from 'Type/Common.type';
import { Option } from 'Type/Field.type';
import { noopFn } from 'Util/Common';

import { FieldSelectComponentProps } from './FieldSelect.type';

import './FieldSelect.style';

/**
 * Field Select
 * @class FieldSelect
 * @namespace PlugAndSell2/Component/FieldSelect/Component */
export class FieldSelectComponent extends PureComponent<FieldSelectComponentProps> {
    static defaultProps: Partial<FieldSelectComponentProps> = {
        isUpDirection: false,
    };

    renderNativeOption(option: Option): ReactElement {
        const { id, value, disabled, label, selected, subLabel = '', isAvailable = true } = option;

        const { isDisabled } = this.props;

        return (
            <option key={id} id={String(id)} value={value} disabled={disabled || isDisabled || !isAvailable} selected={selected}>
                {`${label} ${subLabel}`}
            </option>
        );
    }

    renderNativeSelect(): ReactElement {
        const { setRef, attr, events, isDisabled, options, handleSelectListOptionClick, isExpanded, isSelectedOptionAvailable } = this.props;

        return (
            <select
                block="FieldSelect"
                elem="Select"
                mods={{ isDisabled: !isSelectedOptionAvailable || isDisabled, isExpanded }}
                ref={(elem) => setRef(elem)}
                disabled={isDisabled}
                // eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction
                {...attr}
                // eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction
                {...(events as FieldReactEvents<HTMLSelectElement>)}
                onChange={handleSelectListOptionClick}
            >
                {options.map(this.renderNativeOption.bind(this))}
            </select>
        );
    }

    renderOption(option: Option): ReactElement {
        const { id, label, subLabel, isPlaceholder = false, isHovered, value } = option;

        const {
            isExpanded,
            handleSelectListOptionClick,
            attr: { value: selectedValue },
        } = this.props;

        return (
            <li
                block="FieldSelect"
                elem="Option"
                mods={{
                    isExpanded,
                    isPlaceholder,
                    isHovered,
                    isSelected: selectedValue === value,
                }}
                key={id}
                /**
                 * Added 'o' as querySelector does not work with
                 * ids, that consist of numbers only
                 */
                id={`o${id}`}
                role="menuitem"
                // eslint-disable-next-line react/jsx-no-bind
                onMouseDown={() => handleSelectListOptionClick(option)}
                // eslint-disable-next-line react/jsx-no-bind
                onTouchStart={() => handleSelectListOptionClick(option)}
                // eslint-disable-next-line react/jsx-no-bind
                onKeyPress={() => handleSelectListOptionClick(option)}
                tabIndex={isExpanded ? 0 : -1}
            >
                {label}
                {subLabel && <strong>{` ${subLabel}`}</strong>}
            </li>
        );
    }

    renderOptions(): ReactElement {
        const { options, isExpanded, isDropdownOpenUpwards, isScrollable } = this.props;

        return (
            <ul
                block="FieldSelect"
                elem="Options"
                role="menu"
                mods={{
                    isExpanded,
                    isDropdownOpenUpwards,
                    isNotScrollable: !isScrollable,
                }}
            >
                <div block="FieldSelect" elem="OptionsWrapper" role="menu" mods={{ isExpanded }}>
                    {options.map(this.renderOption.bind(this))}
                </div>
            </ul>
        );
    }

    render(): ReactElement {
        const {
            attr: { id = '' } = {},
            isExpanded,
            handleSelectExpand,
            handleSelectListKeyPress,
            handleSelectExpandedExpand,
            isDisabled,
        } = this.props;

        return (
            <ClickOutside onClick={handleSelectExpandedExpand} disabledOtherOverlaysOpen>
                <div
                    id={`${id}_wrapper`}
                    block="FieldSelect"
                    mods={{ isExpanded }}
                    onClick={!isDisabled ? handleSelectExpand : noopFn}
                    onKeyPress={!isDisabled ? handleSelectListKeyPress : noopFn}
                    role="button"
                    tabIndex={0}
                    aria-label="Select dropdown"
                    aria-expanded={isExpanded}
                >
                    <div block="FieldSelect" elem="Clickable">
                        {this.renderNativeSelect()}
                        <div block="FieldSelect" elem="Arrow" mods={{ isExpanded, isDisabled }}>
                            <Icons name={IconName.ARROW_DOWN} width="14" height="24" />
                        </div>
                    </div>
                    {this.renderOptions()}
                </div>
            </ClickOutside>
        );
    }
}

export default FieldSelectComponent;
