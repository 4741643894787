import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { ReactElement } from 'Type/Common.type';
import { RootState } from 'Util/Store/Store.type';

import MyAccountOrderItemsTable from './MyAccountOrderItemsTable.component';
import {
    MyAccountOrderItemsTableComponentProps,
    MyAccountOrderItemsTableComponentPropsKeys,
    MyAccountOrderItemsTableContainerMapDispatchProps,
    MyAccountOrderItemsTableContainerMapStateProps,
    MyAccountOrderItemsTableContainerProps,
} from './MyAccountOrderItemsTable.type';

/** @namespace PlugAndSell2/Component/MyAccountOrderItemsTable/Container/mapStateToProps */
export const mapStateToProps = (state: RootState): MyAccountOrderItemsTableContainerMapStateProps => ({
    isMobile: state.ConfigReducer.device.isMobile,
});

/** @namespace PlugAndSell2/Component/MyAccountOrderItemsTable/Container/mapDispatchToProps */
export const mapDispatchToProps = (): MyAccountOrderItemsTableContainerMapDispatchProps => ({});

/** @namespace PlugAndSell2/Component/MyAccountOrderItemsTable/Container */
export class MyAccountOrderItemsTableContainer extends PureComponent<MyAccountOrderItemsTableContainerProps> {
    static defaultProps = {
        isPrintPage: false,
    };

    containerProps(): Pick<MyAccountOrderItemsTableComponentProps, MyAccountOrderItemsTableComponentPropsKeys> {
        const { isMobile, items, activeTab, total, allOrderItems, id, isPrintPage } = this.props;

        return {
            isMobile,
            items,
            activeTab,
            total,
            allOrderItems,
            id,
            isPrintPage,
        };
    }

    render(): ReactElement {
        return <MyAccountOrderItemsTable {...this.containerProps()} />;
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MyAccountOrderItemsTableContainer);
