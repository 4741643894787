import { PureComponent } from 'react';

import { ReactElement } from 'Type/Common.type';

import MyAccountContractorTable from './MyAccountContractorTable.component';
import { MyAccountContractorTableComponentProps, MyAccountContractorTableContainerProps } from './MyAccountContractorTable.type';

/** @namespace PlugAndSell2/Component/MyAccountContractorTable/Container */
export class MyAccountContractorTableContainer extends PureComponent<MyAccountContractorTableContainerProps> {
    static defaultProps: Partial<MyAccountContractorTableContainerProps> = {
        title: '',
    };

    containerFunctions = {};

    containerProps(): Pick<MyAccountContractorTableComponentProps, 'contractor' | 'title'> {
        const { contractor, title } = this.props;

        return {
            contractor,
            title,
        };
    }

    render(): ReactElement {
        return <MyAccountContractorTable {...this.containerProps()} {...this.containerFunctions} />;
    }
}

export default MyAccountContractorTableContainer;
