import { PureComponent } from 'react';

import { AccordionComponentProps } from 'Component/Accordion/Accordion.type';

import './Accordion.style';

/** @namespace PlugAndSell2/Component/Accordion/Component */
export class AccordionComponent extends PureComponent<AccordionComponentProps> {
    render() {
        const { dataDevice, children } = this.props;

        return (
            <div block="Accordion" data-device={dataDevice}>
                {children}
            </div>
        );
    }
}

export default AccordionComponent;
