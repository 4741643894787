import Button from 'Component/Button';
import { ButtonVariant } from 'Component/Button/Button.type';
import Icons from 'Component/Icons';
import { IconName } from 'Component/Icons/Icons.type';
import KeyValueTable from 'Component/KeyValueTable';
import { DataPair } from 'Component/KeyValueTable/KeyValueTable.type';
import Loader from 'Component/Loader';
import { CustomerAddress } from 'Query/MyAccount.type';
import { ReactElement } from 'Type/Common.type';
import { FormattedRegion } from 'Util/Address/Address.type';

import { getAddressTablePairArray } from './MyAccountAddressTable.table';
import { MyAccountAddressTableComponentProps } from './MyAccountAddressTable.type';

import './MyAccountAddressTable.style';

/** @namespace PlugAndSell2/Component/MyAccountAddressTable/Component */
export class MyAccountAddressTableComponent<
    Props extends MyAccountAddressTableComponentProps = MyAccountAddressTableComponentProps
> extends KeyValueTable<Props> {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dataPairArray(): DataPair<CustomerAddress | FormattedRegion>[] {
        return getAddressTablePairArray(this.props);
    }

    renderActions(): ReactElement {
        const { onEditClick, onDeleteClick, showActions, address } = this.props;

        if (!('default_shipping' in address)) {
            return null;
        }

        const { default_billing, default_shipping } = address;

        const isDeleteAllowed = default_shipping || default_billing;

        if (!showActions) {
            return null;
        }

        return (
            <>
                <Button onClick={onEditClick} variant={ButtonVariant.OUTLINE_DARK}>
                    {__('Edit address')}
                </Button>
                <Button
                    onClick={onDeleteClick}
                    variant={ButtonVariant.LIKE_LINK}
                    disabled={isDeleteAllowed}
                    title={isDeleteAllowed ? __('Can not delete - address is set as default.') : 'Delete this address'}
                    iconBefore={<Icons name={IconName.TRASH} fill="#797979" width="16px" />}
                >
                    {__('Delete')}
                </Button>
            </>
        );
    }

    render(): ReactElement {
        const { countries, mix } = this.props;

        return (
            <div block="MyAccountAddressTable" mix={mix}>
                <Loader isLoading={!countries.length} />
                {this.renderTable()}
                {this.renderActions()}
            </div>
        );
    }
}

export default MyAccountAddressTableComponent;
