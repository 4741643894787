import { PureComponent } from 'react';

import Loader from 'Component/Loader';
import MyAccountCustomerForm from 'Component/MyAccountCustomerForm';
import MyAccountPasswordForm from 'Component/MyAccountPasswordForm';
import Popup from 'Component/Popup';
import { ReactElement } from 'Type/Common.type';

import { MyAccountCustomerPopupAction } from './MyAccountCustomerPopup.config';
import { MyAccountCustomerPopupComponentProps } from './MyAccountCustomerPopup.type';

import './MyAccountCustomerPopup.style';

/** @namespace PlugAndSell2/Component/MyAccountCustomerPopup/Component */
export class MyAccountCustomerPopupComponent extends PureComponent<MyAccountCustomerPopupComponentProps> {
    renderChangePasswordForm(): ReactElement {
        const { onPasswordChange } = this.props;

        return <MyAccountPasswordForm onPasswordChange={onPasswordChange} />;
    }

    renderCustomerForm(): ReactElement {
        const {
            payload: { customer } = {},
            onCustomerSave,
            showEmailChangeField,
            showPasswordChangeField,
            handleChangeEmailCheckbox,
            handleChangePasswordCheckbox,
        } = this.props;

        if (!customer) {
            return null;
        }

        return (
            <MyAccountCustomerForm
                customer={customer}
                handleChangeEmailCheckbox={handleChangeEmailCheckbox}
                handleChangePasswordCheckbox={handleChangePasswordCheckbox}
                showEmailChangeField={showEmailChangeField}
                showPasswordChangeField={showPasswordChangeField}
                onSave={onCustomerSave}
            />
        );
    }

    renderContent(): ReactElement {
        const { payload: { action } = {} } = this.props;

        switch (action) {
            case MyAccountCustomerPopupAction.CHANGE_PASSWORD:
                return this.renderChangePasswordForm();
            case MyAccountCustomerPopupAction.EDIT_CUSTOMER:
                return this.renderCustomerForm();
            default:
                return null;
        }
    }

    render(): ReactElement {
        const { isLoading } = this.props;

        return (
            <Popup id={MyAccountCustomerPopupAction.CUSTOMER_POPUP_ID} mix={{ block: 'MyAccountCustomerPopup' }}>
                <Loader isLoading={isLoading} />
                {this.renderContent()}
            </Popup>
        );
    }
}

export default MyAccountCustomerPopupComponent;
