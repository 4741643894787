import { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';

import { ReactElement } from 'Type/Common.type';

import { LabelComponentProps } from './Label.type';

import './Label.style';

/** @namespace PlugAndSell2/Component/Label/Component */
export class LabelComponent extends PureComponent<LabelComponentProps> {
    static defaultProps: Partial<LabelComponentProps> = {
        image: null,
        text: '',
        text_background_color: '#fff',
        text_color: '#000',
        url: null,
    };

    render(): ReactElement {
        const { image, text, text_background_color, text_color, url, history } = this.props;
        const iconOnly = image && !text;
        const color = text_color || '#000';
        let background: string | undefined = text_background_color || '#fff';

        if (iconOnly) {
            background = undefined;
        }

        return (
            <div
                block="Label"
                mods={{ iconOnly }}
                style={{ color, background }}
                onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();

                    if (url) {
                        history?.push(url);
                    }
                }}
            >
                {image ? <img block="Label" elem="Image" mods={{ iconOnly }} src={image} alt={text} /> : null}
                {text}
            </div>
        );
    }
}

export default withRouter(LabelComponent);
