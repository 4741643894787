import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import {
    CONSENTS_CHECKOUT,
    CONSENTS_CONTACT,
    CONSENTS_NEWSLETTER,
    CONSENTS_REGISTER
} from './Consents.config';
import ConsentsQuery from '../../query/Consents.query';
import { showNotification } from 'Store/Notification/Notification.action';
import { prepareQuery } from 'Util/Query';
import { getErrorMessage } from 'Util/Request/Error';
import { executePost } from 'Util/Request/Request';
import { FieldType } from "Component/Field/Field.config";

import Consents from './Consents.component';

/** @namespace Advox/Storefront/Component/Consents/Container/mapStateToProps */
export const mapStateToProps = () => ({});

/** @namespace Advox/Storefront/Component/Consents/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    showNotification: (type, msg, debug = null) => dispatch(showNotification(type, msg, debug))
});

/** @namespace Advox/Storefront/Component/Consents/Container */
export class ConsentsContainer extends PureComponent {
    static propTypes = {
        type: PropTypes.oneOf([CONSENTS_REGISTER, CONSENTS_CONTACT, CONSENTS_NEWSLETTER, CONSENTS_CHECKOUT]),
        consents: PropTypes.arrayOf(
            PropTypes.shape({
                consent_id: PropTypes.string,
                description: PropTypes.string,
                is_required: PropTypes.bool,
                is_checkbox: PropTypes.bool
            })
        ),
        showNotification: PropTypes.func.isRequired
    };

    static defaultProps = {
        consents: []
    };

    static async getConsentsByType(type) {
        if (!type) {
            throw new Error('Consents type is required');
        }

        const query = ConsentsQuery.getConsents(type);

        try {
            const { consents } = await executePost(prepareQuery(query));
            return consents;
        } catch (error) {
            console.error(getErrorMessage(error));
            return [];
        }
    }

    static getAcceptedConsents(fields = {}, consents = []) {
        const checkbox = fields
            .filter((field) =>
                 field.type === FieldType.CHECKBOX && field.value === true && field.field.hasAttribute("data-consent-id")
            )
            .map((consent) => consent.name);
        const text = consents.filter((consent) => !consent.is_checkbox).map((consent) => consent.consent_id);

        return [...checkbox, ...text];
    }

    state = {
        isLoading: true,
        consents: []
    };

    componentDidMount() {
        this.getConsents();
    }

    async getConsents() {
        const { consents, type, showNotification } = this.props;
        const { isLoading } = this.state;

        if (!isLoading) {
            this.setState({ isLoading: true });
        }

        try {
            if (consents.length > 0) {
                this.setState({
                    consents
                });
            } else if (type) {
                const consentsByType = await ConsentsContainer.getConsentsByType(type);
                this.setState({
                    consents: consentsByType
                });
            }
        } catch (error) {
            showNotification('error', getErrorMessage(error), error);
        } finally {
            this.setState({ isLoading: false });
        }
    }

    containerProps() {
        const { isLoading, consents } = this.state;

        return {
            isLoading,
            consents
        };
    }

    render() {
        return <Consents { ...this.containerProps() } />;
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ConsentsContainer);
