/* eslint-disable spaced-comment */
import { createRef, PureComponent, Suspense } from 'react';

import Field from 'Component/Field';
import { FieldType } from 'Component/Field/Field.config';
import ProductPackagingNotice from 'Component/ProductPackagingNotice';
import ProductPrice from 'Component/ProductPrice';
import TextPlaceholder from 'Component/TextPlaceholder';
import { TextPlaceHolderLength } from 'Component/TextPlaceholder/TextPlaceholder.config';
import { CategoryPageLayout } from 'Route/CategoryPage/CategoryPage.config';
import { ReactElement } from 'Type/Common.type';
import { formatPrice } from 'Util/Price';
import { filterConfigurableOptions, getProductUnit } from 'Util/Product';
import { getDrumThreshold, hasCustomPackageSize, hasDrum, isCutToSize } from 'Util/Product/Extract';
import { IndexedBundleItem, IndexedConfigurableOption } from 'Util/Product/Product.type';
import { lowPriorityLazy } from 'Util/Request/LowPriorityRender';
import { ValidationInputTypeNumber } from 'Util/Validator/Config';

import ProductTags from '../../../packages/product-tags/src/component/ProductTags';
import { ProductType } from './Product.config';
import { ProductComponentProps, ProductComponentState } from './Product.type';

export const ProductReviewRating = lowPriorityLazy(
    () => import(/* webpackMode: "lazy", webpackChunkName: "product-misc" */ 'Component/ProductReviewRating')
);
export const ProductConfigurableAttributes = lowPriorityLazy(
    () =>
        import(
            /* webpackMode: "lazy", webpackChunkName: "product-misc" */ 'Component/ProductConfigurableAttributes/ProductConfigurableAttributes.container'
        )
);
export const AddToCart = lowPriorityLazy(() => import(/* webpackMode: "lazy", webpackChunkName: "product-misc" */ 'Component/AddToCart'));
export const FieldContainer = lowPriorityLazy(() => import(/* webpackMode: "lazy", webpackChunkName: "product-misc" */ 'Component/Field'));
export const ProductCustomizableOptions = lowPriorityLazy(
    () => import(/* webpackMode: "lazy", webpackChunkName: "product-misc" */ 'Component/ProductCustomizableOptions')
);
export const ProductBundleOptions = lowPriorityLazy(
    () => import(/* webpackMode: "lazy", webpackChunkName: "product-misc" */ 'Component/ProductBundleOptions')
);
export const GroupedProductList = lowPriorityLazy(
    () => import(/* webpackMode: "lazy", webpackChunkName: "product-misc" */ 'Component/GroupedProductList')
);
export const ProductCompareButton = lowPriorityLazy(
    () => import(/* webpackMode: "lazy", webpackChunkName: "product-misc" */ 'Component/ProductCompareButton')
);
export const ProductDownloadableLinks = lowPriorityLazy(
    () => import(/* webpackMode: "lazy", webpackChunkName: "product-misc" */ 'Component/ProductDownloadableLinks')
);
export const ProductDownloadableSamples = lowPriorityLazy(
    () => import(/* webpackMode: "lazy", webpackChunkName: "product-misc" */ 'Component/ProductDownloadableSamples')
);
export const ProductWishlistButton = lowPriorityLazy(
    () => import(/* webpackMode: "lazy", webpackChunkName: "product-misc" */ 'Component/ProductWishlistButton')
);

/**
 * Product
 * @class Product
 * @namespace PlugAndSell2/Component/Product/Component */
export class ProductComponent<
    P extends ProductComponentProps = ProductComponentProps,
    S extends ProductComponentState = ProductComponentState
> extends PureComponent<P, S> {
    static defaultProps: Partial<ProductComponentProps> = {
        configFormRef: createRef<HTMLFormElement>(),
    };

    className = this.constructor.name.slice(0, -1) || 'Product';

    //#region PLACEHOLDERS
    renderTextPlaceholder(): ReactElement {
        return <TextPlaceholder />;
    }

    renderBlockPlaceholder(): ReactElement {
        return <div block={this.className} mods={{ isLoading: true, isPlaceholder: true }} />;
    }
    //#endregion

    //#region PRODUCT OPTIONS
    renderBundleOptions(): ReactElement {
        const { product: { items = [] } = {}, updateSelectedValues } = this.props;

        return (
            <Suspense fallback={null}>
                <ProductBundleOptions options={items as IndexedBundleItem[]} updateSelectedValues={updateSelectedValues} />
            </Suspense>
        );
    }

    renderCustomizableOptions(): ReactElement {
        const {
            product: { options },
            updateSelectedValues,
        } = this.props;

        return options ? (
            <Suspense fallback={null}>
                <ProductCustomizableOptions updateSelectedValues={updateSelectedValues} options={options} />
            </Suspense>
        ) : null;
    }

    renderDownloadableLinks(): ReactElement {
        const {
            setDownloadableLinks,
            product: { type_id, downloadable_product_links: links, links_title, links_purchased_separately },
        } = this.props;

        if (type_id !== ProductType.DOWNLOADABLE || (Array.isArray(links) && !links.length)) {
            return null;
        }

        const isRequired = links_purchased_separately === 1;

        return (
            <Suspense fallback={null}>
                <ProductDownloadableLinks links={links} setLinkedDownloadables={setDownloadableLinks} title={links_title} isRequired={isRequired} />
            </Suspense>
        );
    }

    renderDownloadableSamples(): ReactElement {
        const {
            product: { type_id, samples_title = '', downloadable_product_samples: samples },
        } = this.props;

        if (type_id !== ProductType.DOWNLOADABLE || !samples || (Array.isArray(samples) && !samples.length)) {
            return null;
        }

        return (
            <Suspense fallback={null}>
                <ProductDownloadableSamples title={samples_title} samples={samples} />
            </Suspense>
        );
    }

    getConfigurableAttributes(): Record<string, IndexedConfigurableOption> {
        const {
            product: { configurable_options: configurableOptions = {}, variants = [] },
        } = this.props;

        return filterConfigurableOptions(configurableOptions, variants);
    }

    renderConfigurableOptions(): ReactElement {
        const {
            setActiveProduct,
            parameters,
            product,
            inStock,
            addToCartTriggeredWithError,
            customPackagingEnabled,
            updateAddToCartTriggeredWithError,
            handleCutToSizeClick,
        } = this.props;

        const { type_id: type, variants = [] } = product;

        if (type !== ProductType.CONFIGURABLE) {
            return null;
        }

        return (
            <div block="ProductActions" elem="AttributesWrapper">
                <Suspense fallback={null}>
                    <ProductConfigurableAttributes
                        numberOfPlaceholders={[2, 4]}
                        updateAddToCartTriggeredWithError={updateAddToCartTriggeredWithError}
                        addToCartTriggeredWithError={addToCartTriggeredWithError}
                        mix={{ block: this.className, elem: 'Attributes' }}
                        parameters={parameters}
                        variants={variants}
                        updateConfigurableVariant={setActiveProduct}
                        configurable_options={this.getConfigurableAttributes()}
                        isContentExpanded
                        inStock={inStock}
                        showProductAttributeAsLink={false}
                        hasCustomPackageSize={hasCustomPackageSize(product)}
                        isCutToSize={isCutToSize(product)}
                        onCutToSizeClick={handleCutToSizeClick}
                        cutToSizeEnabled={customPackagingEnabled}
                    />
                </Suspense>
            </div>
        );
    }

    renderCustomPackageSizeSelect(): ReactElement {
        const { product } = this.props;

        if (!hasCustomPackageSize(product)) {
            return null;
        }

        return (
            <div>
                <Field type={FieldType.SELECT} />
            </div>
        );
    }

    renderGroupedOptions(): ReactElement {
        const {
            product,
            product: { type_id: typeId },
            setQuantity,
            quantity,
        } = this.props;

        if (typeId !== ProductType.GROUPED) {
            return null;
        }

        return (
            <div block={this.className} elem="GroupedItems">
                <Suspense fallback={null}>
                    <GroupedProductList product={product} quantity={quantity} setQuantity={setQuantity} />
                </Suspense>
            </div>
        );
    }

    renderCustomAndBundleOptions(): ReactElement {
        const {
            product: { type_id },
            configFormRef,
        } = this.props;

        return (
            <form ref={configFormRef}>
                {type_id === ProductType.BUNDLE && this.renderBundleOptions()}
                {this.renderCustomizableOptions()}
            </form>
        );
    }
    //#endregion

    //#region BUTTONS
    renderAddToCartButton(layout = CategoryPageLayout.GRID): ReactElement {
        const { addToCart, inStock, quantity, getActiveProduct, updateSelectedValues } = this.props;

        return (
            <Suspense fallback={null}>
                <AddToCart
                    mix={{ block: this.className, elem: 'AddToCart' }}
                    addToCart={addToCart}
                    isDisabled={!inStock}
                    isIconEnabled={false}
                    layout={layout}
                    updateSelectedValues={updateSelectedValues}
                    quantity={quantity}
                    product={getActiveProduct()}
                />
            </Suspense>
        );
    }

    renderWishlistButton(): ReactElement {
        const { magentoProduct, isWishlistButtonEnabled, isWishlistEnabled } = this.props;

        if (magentoProduct.length === 0 || !isWishlistEnabled) {
            return null;
        }

        return (
            <Suspense fallback={null}>
                <ProductWishlistButton
                    magentoProduct={magentoProduct}
                    mix={{
                        block: this.className,
                        elem: 'WishListButton',
                    }}
                    isDisabled={!isWishlistButtonEnabled}
                />
            </Suspense>
        );
    }

    renderCompareButton(): ReactElement {
        const {
            product: { id },
        } = this.props;

        if (!id) {
            return null;
        }

        return (
            <Suspense fallback={null}>
                <ProductCompareButton
                    productId={id}
                    mix={{
                        block: this.className,
                        elem: 'ProductCompareButton',
                        mods: { isGrey: true },
                    }}
                />
            </Suspense>
        );
    }

    renderProductTags(): ReactElement {
        const { product } = this.props;

        return <ProductTags product={{ id: product.id }} />;
    }

    renderUnit(): ReactElement {
        const { product } = this.props;

        const unit = getProductUnit(product);

        if (!unit) {
            return null;
        }

        return (
            <span block={this.className} elem="Unit">
                {unit}
            </span>
        );
    }

    renderQuantityChanger(): ReactElement {
        const { quantity, minQuantity, maxQuantity, setQuantity, qtyStep, inStock, product, customPackagingEnabled } = this.props;

        const { type_id } = product;

        if (type_id === ProductType.GROUPED) {
            return null;
        }

        if (hasCustomPackageSize(product) && !customPackagingEnabled) {
            return null;
        }

        if (hasCustomPackageSize(product) && !isCutToSize(product)) {
            return null;
        }

        return (
            <Suspense fallback={null}>
                <FieldContainer
                    type={FieldType.NUMBER_WITH_CONTROLS}
                    label={customPackagingEnabled ? __('Qty') : null}
                    attr={{
                        id: 'item_qty',
                        name: 'item_qty',
                        defaultValue: quantity as number,
                        max: maxQuantity,
                        min: minQuantity,
                        step: qtyStep,
                    }}
                    validationRule={{
                        inputType: ValidationInputTypeNumber.NUMERIC,
                        isRequired: true,
                        range: {
                            min: minQuantity,
                            max: maxQuantity,
                        },
                    }}
                    isDisabled={!inStock}
                    mix={{ block: this.className, elem: 'Qty' }}
                    events={{ onChange: setQuantity }}
                    validateOn={['onChange']}
                />
                {this.renderUnit()}
            </Suspense>
        );
    }
    //#endregion

    //#region FIELDS
    renderRatingSummary(): ReactElement {
        const {
            product: { review_summary: { rating_summary, review_count } = {} },
        } = this.props;

        if (!rating_summary) {
            return null;
        }

        return (
            <Suspense fallback={null}>
                <ProductReviewRating summary={rating_summary || 0} count={review_count} />
            </Suspense>
        );
    }

    renderBrand(withMeta = false): ReactElement {
        const {
            product: { attributes: { brand: { attribute_value: brand = '' } = {} } = {} },
        } = this.props;

        if (!brand) {
            return null;
        }

        return (
            <>
                {withMeta && <meta itemProp="brand" content={brand} />}
                <h4 block={this.className} elem="Brand" itemProp="brand">
                    <TextPlaceholder content={brand} />
                </h4>
            </>
        );
    }

    renderPrice(isPreview = false): ReactElement {
        const { getActiveProduct, productPrice } = this.props;
        const product = getActiveProduct();

        const { type_id: type, price_tiers: priceTiers } = product;

        if (!productPrice) {
            return null;
        }

        return (
            <div block={this.className} elem="PriceWrapper">
                <ProductPrice
                    price={productPrice}
                    priceType={type as ProductType}
                    tierPrices={priceTiers}
                    unit={getProductUnit(product)}
                    isPreview={isPreview}
                    mix={{ block: this.className, elem: 'Price' }}
                />
            </div>
        );
    }

    renderPackagingPricingInfo(): ReactElement {
        const { getActiveProduct, packagingProduct } = this.props;

        const activeProduct = getActiveProduct();

        if (!hasDrum(activeProduct)) {
            return null;
        }

        return <ProductPackagingNotice product={activeProduct} packaging={{ price: packagingProduct?.price_range }} />;
    }

    renderTotalWithPackaging(): ReactElement {
        const { totalWithPackaging, getActiveProduct, quantity } = this.props;

        const activeProduct = getActiveProduct();

        if (!activeProduct) {
            return;
        }

        if (!hasDrum(activeProduct) || Number(quantity) < Number(getDrumThreshold(activeProduct))) {
            return null;
        }

        return (
            <div block={this.className} elem="TotalWithPackaging">
                <span block={this.className} elem="TotalWithPackagingLabel">
                    {__('Total')}:
                </span>{' '}
                {totalWithPackaging ? (
                    <>
                        <span>{formatPrice(totalWithPackaging.value, totalWithPackaging.currency)}</span> ({__('with drum')})
                    </>
                ) : (
                    <TextPlaceholder length={TextPlaceHolderLength.SHORT} />
                )}
            </div>
        );
    }

    renderStock(): ReactElement {
        const { inStock } = this.props;

        const stockStatusLabel = inStock ? __('In stock') : __('Out of stock');

        return (
            <span block={this.className} elem="Stock">
                {stockStatusLabel}
            </span>
        );
    }

    renderSku(): ReactElement {
        const { getActiveProduct } = this.props;
        const { sku } = getActiveProduct();

        return (
            <span block={this.className} elem="Sku" itemProp="sku">
                {__('SKU: %s', sku)}
            </span>
        );
    }

    /**
     * Renders name if { dynamic } is set to true, then will output
     * name to active product AKA configurable products selected variant
     *
     * @param header If header outputs as H1
     * @param dynamic Name type (false - shows parent product only)
     * @returns {ReactElement}
     */
    renderName(header = true, dynamic = false): ReactElement {
        const {
            product: { name },
            productName,
        } = this.props;
        const nameToRender = dynamic ? productName : name;

        if (!header) {
            return (
                <p block={this.className} elem="Name">
                    <TextPlaceholder content={nameToRender} length={TextPlaceHolderLength.MEDIUM} />
                </p>
            );
        }

        return (
            <h1 block={this.className} elem="Title" itemProp="name">
                <TextPlaceholder content={nameToRender} length={TextPlaceHolderLength.MEDIUM} />
            </h1>
        );
    }

    renderSKU(): ReactElement {
        const {
            product: { sku },
        } = this.props;

        return (
            <h1 block={this.className} elem="SubTitle" itemProp="sku">
                <TextPlaceholder content={sku && `${__('SKU')}: ${sku}`} length={TextPlaceHolderLength.SHORT} />
            </h1>
        );
    }
    //#endregion

    render(): ReactElement {
        return null;
    }
}

export default ProductComponent;
