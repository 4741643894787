import { PureComponent } from 'react';

import { ReactElement } from 'Type/Common.type';

import { MyAccountForgotPasswordSuccessContainerProps } from './MyAccountForgotPasswordSuccess.type';

/** @namespace PlugAndSell2/Component/MyAccountForgotPasswordSuccess/Component */
export class MyAccountForgotPasswordSuccessComponent extends PureComponent<MyAccountForgotPasswordSuccessContainerProps> {
    render(): ReactElement {
        const { state, handleSignIn, submittedEmail } = this.props;

        return (
            <article aria-labelledby="forgot-password-success" block="MyAccountOverlay" elem="Additional" mods={{ state }}>
                <p id="forgot-password-success">
                    {/* eslint-disable-next-line max-len */}
                    {__('If there is an account associated with %s you will receive an email with a link to reset your password', submittedEmail)}
                </p>
                <button block="Button" onClick={handleSignIn}>
                    {__('Got it')}
                </button>
            </article>
        );
    }
}

export default MyAccountForgotPasswordSuccessComponent;
