import { PureComponent } from 'react';
import Image from 'Component/Image';
import Link from 'Component/Link';
import { ReactElement } from 'Type/Common.type';

import { ProductTagsComponentProps } from './ProductTags.type';
import { ProductTag } from '../../query/ProductTags/ProductTags.type';

import './ProductTags.style'
import { ImageRatio } from 'Component/Image/Image.type';
import { isEmpty } from 'Util/Common';

export class ProductTags extends PureComponent<ProductTagsComponentProps> {
  renderTag(tag: ProductTag, idx: number): ReactElement {
    return (
      <li key={`${tag.attribute_code}-${idx}`}>
        <Link to={tag.tag_url}>
          <div block="ProductTags" elem="ListItemContent">
            {tag.tag_name && <span block="ProductTags" elem="ListItemTooltip">{tag.tag_name}</span>}
            <Image ratio={ImageRatio.IMG_SQUARE} src={tag.image_url} alt={tag.tag_name} /> 
          </div>
        </Link>
      </li>
    )
  }
  
  renderTags(): ReactElement {
    const { tags } = this.props;

    if (!tags?.length) {
      return;
    }

    return (
      <ul block="ProductTags" elem="List">
        {tags.map(this.renderTag)}
      </ul>
    )
  }

  render() {
    const { tags } = this.props;

    return (
      <div block="ProductTags" mods={{ isEmpty: isEmpty(tags) }}>
        {this.renderTags()}
      </div>
    );
  }
}

export default ProductTags;