import getStore from "Util/Store";
import { ElementType } from "react";
import ContractorUser from "../component/ContractorUser"

export const CONTRACTOR_USERS_TAB_ID = 'contractor-users'

export const ContractorsDispatcher = import(
  /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
  '../store/Contractors/Contractors.dispatcher'
);

export const MyAccountDispatcher_requestCustomerData = (args: any, callback: Function) => {
  const { dispatch } = getStore();
  ContractorsDispatcher.then(({ default: dispatcher }) => dispatcher.handleData(dispatch, {}));

  return callback(...args);
}

export const MyAccountTabList_containerProps = (originalMember: Object) => {
  return ({
    ...originalMember,
    [CONTRACTOR_USERS_TAB_ID]: {
      url: '/contractor-users/list',
      tabName: 'Users',
      hiddenHeader: true,
      isFullUrl: true,
    }
  })
}

export const MyAccountComponent_renderMap = (originalMember: Record<string, ElementType>) => {
  return ({
    ...originalMember,
    'contractor-users': () => <ContractorUser />,
  })
}

export default {
  'PlugAndSell2/Store/MyAccount/Dispatcher': {
    'member-function': {
      requestCustomerData: MyAccountDispatcher_requestCustomerData,
    }
  },
  'PlugAndSell2/Route/MyAccount/Component': {
    'member-property': {
      renderMap: MyAccountComponent_renderMap
    }
  },
  'PlugAndSell2/Route/MyAccount/Container': {
    'static-member': {
      tabMap: MyAccountTabList_containerProps
    }
  }
};
