import { FilterTypeInput, GQLCurrencyEnum } from "Type/Graphql.type"

export type GetFinancialDocsOutput = Partial<{
  items: FinancialDoc[]
  page_info: PageInfo
  total_count: number
}>

export type FinancialDoc = Partial<{
  id: number
  contractor_id: number
  order_id: number
  type: string
  number: string
  reference: string
  unit: string
  order_number: string
  value: number
  currency: GQLCurrencyEnum
  paid: number
  difference: number
  status: string
  sale_date: string
  payment_date: string
  delay_days: number
  doc_date: string
}>

export type FinancialDocFilterInput = {
  status?: Partial<FilterTypeInput>
  type?: Partial<FilterTypeInput>
  doc_date?: Partial<FilterTypeInput>
  payment_date?: Partial<FilterTypeInput>
}

export type FinancialDocSortInput = {
  status: SortEnum
  type: SortEnum
  doc_date: SortEnum
  sale_date: SortEnum
  payment_date: SortEnum
} | {}

export type PageInfo = {
  current_page: number
  page_size: number
  total_pages: number
}

export enum SortEnum {
  ASC = 'ASC',
  DESC = 'DESC',
}