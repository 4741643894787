import { Settings } from 'react-slick';

export const DEFAULT_CATALOG_WIDGET_SLICK_SETTINGS: Settings = {
    dots: false,
    infinite: false,
    speed: 500,
    autoplay: false,
    autoplaySpeed: 5000,
    swipeToSlide: true,
    responsive: [
        {
            breakpoint: 768,
            settings: {
                arrows: false,
                slidesToShow: 2.5,
            },
        },
        {
            breakpoint: 1023,
            settings: {
                arrows: false,
                swipeToSlide: true,
                slidesToShow: 2,
            },
        },
        {
            breakpoint: 9999,
            settings: {
                slidesToShow: 4,
                slidesToScroll: 4,
            },
        },
    ],
};
