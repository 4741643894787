import { ChangeEvent, PureComponent } from 'react';
import { RmaFormComponentProps, RmaFormFormDataAttachment, RmaFormMode } from './RmaForm.type';
import { ReactElement } from 'Type/Common.type';
import { FieldType } from 'Component/Field/Field.config';
import Field from 'Component/Field';
import FieldGroup from 'Component/FieldGroup';
import Button from 'Component/Button';
import Image from 'Component/Image';
import { ImageRatio } from 'Component/Image/Image.type';
import { formatPrice } from 'Util/Price';
import { RmaDetailsDataSourceItem } from '../RmaDetails/RmaDetails.type';

import './RmaForm.style'
import { FieldVariant } from 'Component/Field/Field.type';
import Loader from 'Component/Loader';
import { ButtonVariant } from 'Component/Button/Button.type';
import Icons from 'Component/Icons';
import { IconName } from 'Component/Icons/Icons.type';
import media from 'Util/Media';

export class RmaForm extends PureComponent<RmaFormComponentProps> {
  renderAttachmentsList(attachments: RmaFormFormDataAttachment[]| undefined, item: RmaDetailsDataSourceItem): ReactElement {
    if (!attachments?.length) {
      return (
        <ul>
          <li>{__('No files chosen')}</li>
        </ul>
      )
    }

    return (
      <ul>
        {attachments.map(attachment => {
          return (
            <li key={`attachments-${item.itemId}-${attachment.name}`}>
              {attachment.name}
            </li>
          )
        })}
        <Button 
            variant={ButtonVariant.LIKE_LINK} 
            onClick={() => {
                const { handleEditProductData } = this.props;
                handleEditProductData(item.itemId, 'attachments', null)
            }}
        >
          {__('Remove all')}
        </Button>
      </ul>
    )
  }

  renderAttachmentsField(item: RmaDetailsDataSourceItem): ReactElement {
    const { formData } = this.props;

    const attachments = formData.products[item.itemId]?.attachments
    
    return (
      <div block="RmaForm" elem="AttachmentsWrapper">
        <h5>{__('Attachments')}</h5>
        <div block="RmaForm" elem="Attachments">
          <label htmlFor={`rma-form-attachments-${item.itemId}`}>
            <Icons name={IconName.CLIP} width='12px'/>
            {__('Add file')}
          </label>
          <input 
            id={`rma-form-attachments-${item.itemId}`}
            type="file" 
            multiple 
            accept="image/jpg,image/png"
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              const { files } = e.target as HTMLInputElement;
              const { handleEditProductData } = this.props;

              if (!files) {
                return;
              }

              const filesArray = Array.from(files);

              const promises = filesArray.map((file) => {
                return new Promise<RmaFormFormDataAttachment>((resolve, reject) => {
                  const reader = new FileReader();
            
                  reader.onload = (event: ProgressEvent<FileReader>) => {
                    const { result } = event.target as FileReader;
                    if (result) {
                      resolve({ name: file.name, base64_encoded_file: result });
                    } else {
                      reject(new Error('File reading failed'));
                    }
                  };
            
                  reader.onerror = () => {
                    reject(new Error('File reading failed'));
                  };
            
                  reader.readAsDataURL(file);
                });
              });
            
              Promise.all(promises)
                .then((results) => {
                  handleEditProductData(item.itemId, 'attachments', results);
                })
                .catch((error) => {
                  console.error('Error reading files:', error);
                });
            }}
          />
          {this.renderAttachmentsList(attachments, item)}
        </div>
      </div>
    )
  }

  renderPaymentsInfo(): ReactElement {
    return (
      <div block="RmaForm" elem="PaymentsInfo">
        <h3>
          {__('Have you chosen COD delivery?')}<br/>
          {__('Be sure to provide the information below.')}
        </h3>
        <p>{__('When using forms of payment other than cash on delivery, the refund will be made automatically using the same method of payment that was selected when placing the order.')}</p>
      </div>
    )
  }

  renderPaymentSection(): ReactElement {
    const { handleEditPaymentData, readOnly } = this.props;

    if (readOnly) {
      return null;
    }
    
    return (
      <div block="RmaForm" elem="PaymentsSection">
        {this.renderPaymentsInfo()}
        <FieldGroup>
          <Field 
            type={FieldType.TEXT}
            label="Bank name"
            attr={{
              placeholder: "Enter bank name"
            }}
            events={{
              onChange: (event: { target: HTMLInputElement }) => {
                handleEditPaymentData('bank_name', event.target.value)
              }
            }}
          />
          <Field 
            type={FieldType.TEXT}
            label="Owner"
            attr={{
              placeholder: "Enter first and last name of account owner"
            }}
            events={{
              onChange: (event: { target: HTMLInputElement }) => {
                handleEditPaymentData('name_surname', event.target.value)
              }
            }}
          />
          <Field 
            type={FieldType.TEXT}
            label="Account number"
            attr={{
              placeholder: "Enter bank account number"
            }}
            events={{
              onChange: (event: { target: HTMLInputElement }) => {
                handleEditPaymentData('account_no', event.target.value)
              }
            }}
          />
        </FieldGroup>
      </div>
    )
  }

  renderProductsSection(): ReactElement {
    const { dataSource, handleEditProductData, readOnly } = this.props;
    
    if (!dataSource) {
      return null;
    }

    return (
      <div block="RmaForm" elem="ProductsSections">
        <h5 block="RmaForm" elem="ProductsSectionsHeader">
          {readOnly ?  __('RMA Items') : __('Select products')}
        </h5>
        <div block="RmaForm" elem="ProductsSectionsList">
          {dataSource.items?.map(item => {
            return (
              <Field
                type={FieldType.CHECKBOX}
                variant={FieldVariant.TERTIARY}
                subLabel={this.renderProductLabel(item)}
                events={{
                  onChange: (e: ChangeEvent<HTMLInputElement>) => { 
                    const { checked } = e.target as HTMLInputElement
                    handleEditProductData(item.itemId, 'selected', checked)

                    if (checked) {
                      handleEditProductData(item.itemId, 'requestQty', 1)
                    }
                  }
                }}
              />
            )
          })}
        </div>
      </div>
    )
  }

  renderProductLabel(item: RmaDetailsDataSourceItem) {
    const { device, handleEditProductData, isSelected, mode, readOnly } = this.props;

    const wrapWithLabel = (label: string, children: ReactElement): ReactElement => {
      if (!device.isMobile) {
        return (children)
      }

      return (
        <div block="RmaForm" elem="ProductLabelWrapper">
          <span>{label}:</span>
          {children}
        </div>
      )
    }

    return (
      <div block="RmaForm" elem="ProductLabel">
        <div block='RmaForm' elem="ProductLabelPhoto">
          <Image src={item.itemThumbnail.url} alt={`product ${item.itemSku}`} ratio={ImageRatio.IMG_CUSTOM} />
        </div>
        <div block="RmaForm" elem="ProductLabelInfo">
          <div block="RmaForm" elem="ProductLabelName">
            {wrapWithLabel(__('Product name'), item.itemName)}
          </div>
          <div block="RmaForm" elem="ProductLabelPrice">
            {wrapWithLabel(__('Price') ,formatPrice(item.itemPrice.value, item.itemPrice.currency))}
          </div>
          {!device.isMobile && 
            <div block="RmaForm" elem="ProductLabelMore">
              {!readOnly && <span>{__('Qty')}: {item.itemQty}</span>}
              <span>{__('SKU')}: {item.itemSku}</span>
            </div>
          }
          {device.isMobile && 
            <>
              <div block="RmaForm" elem="ProductLabelSku">
                {wrapWithLabel(__('SKU'), item.itemSku)}
              </div>
              {!readOnly && 
                <div block="RmaForm" elem="ProductLabelQty">
                  {wrapWithLabel(__('QTY'), item.itemQty)}
                </div>
              }
            </>
          }
        </div>
        {readOnly ?
          <div block="RmaForm" elem="ProductLabelDetails">
            <dl>
              <dt>{__('Quantity')}:</dt>
              <dd>{item.itemQty}</dd>
              <dt>{__('Issue type')}:</dt>
              <dd>{item.itemType}</dd>
              <dt>{__('Description')}:</dt>
              <dd>{item.itemReason}</dd>
              {item.itemImages && item.itemImages.length > 0 && 
                <>
                  <dt>{__('Images')}:</dt>
                  <dd block="RmaForm" elem="ProductLabelDetails" mods={{ images: true }} >
                    {item.itemImages.map(image =>
                      <Image height='45px' width='45px' src={'https://via.placeholder.com/150x200' || media(image.value)} ratio={ImageRatio.IMG_CUSTOM} />
                    )}
                  </dd>
                </>
              } 
            </dl>
          </div>
          :
          <div block="RmaForm" elem="ProductLabelActions">
            {isSelected(item) &&
              <>
                <div block="RmaForm" elem="ProductLabelActionsQty">
                  <Field 
                    type={FieldType.NUMBER_WITH_CONTROLS} 
                    attr={{ 
                      defaultValue: 1, 
                      max: item.itemQty 
                    }} 
                    label={__('Qty')}
                    events={{
                      onChange: (value: number) => {
                        handleEditProductData(item.itemId, 'requestQty', value)
                      }
                    }}
                  />
                  <span>/ {item.itemQty}</span>
                </div>
                {mode === RmaFormMode.complaint && 
                  <>
                    <Field 
                      type={FieldType.TEXTAREA} 
                      label={__("Describe reason for complaint")}
                      attr={{
                        placeholder: __("Describe reason for complaint"),
                      }}
                      events={{
                        onChange: (event: { target: HTMLInputElement }) => {
                          handleEditProductData(item.itemId, 'note', event.target.value)
                        }
                      }}
                    />
                    {this.renderAttachmentsField(item)}
                  </>
                }
              </>
            }
          </div>
        }
      </div>
    )
  }

  renderModeSwitch() {
    const { handleSwitchMode, readOnly } = this.props;

    if (readOnly) {
      return null;
    }

    return (
      <div mix={{ block: 'RmaForm', elem: 'ModeSwitch' }}>
        <label htmlFor='rma-form-switch'>
          {__('Request type')}
        </label>
        <Field
          type={FieldType.SELECT}
          options={[
            { id: 'return', label: 'Return', value: RmaFormMode.RETURN },
            { id: 'complain', label: 'Complaint', value: RmaFormMode.complaint }
          ]}
          attr={{
            id: 'rma-form-switch',
            defaultValue: RmaFormMode.RETURN,
            noPlaceholder: true
          }}
          events={{ 
            onChange: (value: string) => {
              handleSwitchMode(value as RmaFormMode)
            }
          }}
        />
      </div>
    )
  }

  renderActions(): ReactElement {
    const { isSubmitEnabled, onBack, onSubmit, readOnly } = this.props;

    if (readOnly) {
      return null;
    }

    return (
      <div block="RmaForm" elem="Actions">
        <Button disabled={!isSubmitEnabled} onClick={onSubmit}>
          {__('Submit return')}
        </Button>
        <Button variant={ButtonVariant.LIKE_LINK} onClick={onBack} mix={{ block: 'Button', elem: 'Cancel' }}>
          {__('Cancel')}
        </Button>
      </div>
    )
  }

  render() {
    const { isLoading, readOnly } = this.props;

    return (
      <div block="RmaForm" mods={{ readOnly }}>
        {this.renderModeSwitch()}
        {this.renderProductsSection()}
        {this.renderPaymentSection()}
        {this.renderActions()}
        <Loader isLoading={isLoading} />
      </div>
    );
  }
}

export default RmaForm;