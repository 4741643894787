import { FieldType } from 'Component/Field/Field.config';
import { FieldContainerProps, FieldInputCustomEvents } from 'Component/Field/Field.type';
import { FieldOptions } from 'Type/Field.type';
import { ValidationInputType } from 'Util/Validator/Config';

/**
 * Form for contacts
 * @namespace PlugAndSell2/Component/ContractorStructureCreateForm/Form/ContractorStructureCreateForm */
export const contractorStructureCreateForm = (
    { countries, parentStructures, typeOptions }: { countries: FieldOptions[], parentStructures: FieldOptions[], typeOptions: FieldOptions[] },
  ): Partial<FieldContainerProps>[] => [
    {
      type: FieldType.SELECT,
      label: __('Type'),
      attr: {
        id: 'type',
        name: 'type',
        defaultValue: typeOptions[0].value,
      },
      options: typeOptions
    },
    {
      type: FieldType.SELECT,
      label: __('Parent structure'),
      attr: {
        id: 'parentId',
        name: 'parentId',
        defaultValue: parentStructures?.[0]?.value,
      },
      options: parentStructures
    },
    {
        type: FieldType.TEXT,
        label: __('Name'),
        attr: {
            name: 'name',
        },
        addRequiredTag: true,
        validateOn: ['onChange'],
        validationRule: {
            isRequired: true,
        },
    },
    {
        type: FieldType.TEXT,
        label: __('Street'),
        attr: {
            name: 'street',
        },
        addRequiredTag: true,
        validateOn: ['onChange'],
        validationRule: {
            isRequired: true,
        },
    },
    {
        type: FieldType.TEXT,
        label: __('City'),
        attr: {
            name: 'city',
        },
        addRequiredTag: true,
        validateOn: ['onChange'],
        validationRule: {
            isRequired: true,
        },
    },
    {
      type: FieldType.TEXT,
      label: __('Postcode'),
      attr: {
          name: 'postcode',
      },
      addRequiredTag: true,
      validateOn: ['onChange'],
      validationRule: {
          isRequired: true,
      },
    },
    {
      type: FieldType.TEXT,
      label: __('Phone'),
      attr: {
          name: 'phone',
      },
      addRequiredTag: true,
      validateOn: ['onChange'],
      validationRule: {
          isRequired: true,
      },
    },
    {
      type: FieldType.TEXT,
      label: __('Email'),
      attr: {
          name: 'email',
      },
      addRequiredTag: true,
      validateOn: ['onChange'],
      validationRule: {
          isRequired: true,
      },
    },
    {
      type: FieldType.SELECT,
      label: __('Country'),
      attr: {
          id: 'address-country-id',
          name: 'country_id',
          selectPlaceholder: __('Select country...'),
      },
      options: countries,
      addRequiredTag: true,
      validateOn: ['onChange'],
      validationRule: {
          isRequired: true,
      },
      // TODO uncomment and fix
      // variant: FieldVariant.SECONDARY,
      // size: FieldSize.SMALL,
    },
    {
      type: FieldType.TEXT,
      label: __('Province'),
      attr: {
          name: 'province',
      },
      addRequiredTag: true,
      validateOn: ['onChange'],
      validationRule: {
          isRequired: true,
      },
    },
];

export default contractorStructureCreateForm;
