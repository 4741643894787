import { PureComponent } from 'react';

import { ReactElement } from 'Type/Common.type';

import loader from '../../assets/loader/loader.svg';
import { LoaderComponentProps } from './Loader.type';

import './Loader.style';

/**
 * Loader component
 * Loaders overlay to identify loading
 * @class Loader
 * @namespace PlugAndSell2/Component/Loader/Component */
export class LoaderComponent extends PureComponent<LoaderComponentProps> {
    static defaultProps: Partial<LoaderComponentProps> = {
        isLoading: true,
        mix: {},
        height: null,
        displayRelative: false,
    };

    render(): ReactElement {
        const { isLoading, mix, displayRelative, height } = this.props;

        if (!isLoading) {
            return null;
        }

        return (
            <div block="Loader" mods={{ isRelative: displayRelative }} style={height ? { height } : {}}>
                <div block="Loader" mix={mix} elem="Scale">
                    <img src={loader} alt="loader" />
                </div>
            </div>
        );
    }
}

export default LoaderComponent;
