import { FieldContainerProps } from 'Component/Field/Field.type';
import FieldForm from 'Component/FieldForm';
import { ReactElement } from 'Type/Common.type';
import { FieldData } from 'Util/Form/Form.type';
import transformToNameValuePair from 'Util/Form/Transform';

import myAccountPasswordForm from './MyAccountPasswordForm.form';
import { MyAccountPasswordFormComponentProps } from './MyAccountPasswordForm.type';

import './MyAccountPasswordForm.style';

/** @namespace PlugAndSell2/Component/MyAccountPasswordForm/Component */
export class MyAccountPasswordFormComponent extends FieldForm<MyAccountPasswordFormComponentProps> {
    onFormSuccess = (form: HTMLFormElement, fields: FieldData[]): void => {
        const { onPasswordChange } = this.props;
        // @ts-ignore
        onPasswordChange(transformToNameValuePair<Record<string, unknown>>(fields));
    };

    fieldMap(): Partial<FieldContainerProps>[] {
        const { passwordMinLength, passwordClassesLength } = this.props;

        return myAccountPasswordForm(passwordMinLength, passwordClassesLength);
    }

    getFormProps(): object {
        return {
            mix: {
                block: 'MyAccountPasswordForm',
            },
            onSubmit: this.onFormSuccess.bind(this),
        };
    }

    renderActions(): ReactElement {
        return (
            <button block="Button" mix={{ block: 'MyAccount', elem: 'Button' }}>
                {__('Change password')}
            </button>
        );
    }
}

export default MyAccountPasswordFormComponent;
