import { PureComponent } from 'react';

import ExpandableContent from 'Component/ExpandableContent';
import MyAccountTabListItem from 'Component/MyAccountTabListItem';
import { MyAccountTab } from 'Route/MyAccount/MyAccount.type';
import { MyAccountTabs } from 'Type/Account.type';
import { ObjectEntries, ReactElement } from 'Type/Common.type';

import { MyAccountTabListComponentProps } from './MyAccountTabList.type';

import './MyAccountTabList.style';

/** @namespace PlugAndSell2/Component/MyAccountTabList/Component */
export class MyAccountTabListComponent extends PureComponent<MyAccountTabListComponentProps> {
    renderTabListItem(
        tabEntry: ObjectEntries<Record<string, MyAccountTab>>,
        index: number,
        tabArray: ObjectEntries<Record<string, MyAccountTab>>[]
    ): ReactElement {
        const { activeTab, onTabClick } = this.props;
        const [key, tab] = tabEntry;
        const { section } = tab;
        const nextTab = tabArray[index + 1] || [];
        const { section: nextTabSection = section } = nextTab[1] || {};

        return (
            <MyAccountTabListItem key={key} isActive={activeTab === key} changeActiveTab={onTabClick} tabEntry={tabEntry}>
                {nextTabSection !== section ? this.renderLine() : null}
            </MyAccountTabListItem>
        );
    }

    renderLine(): ReactElement {
        return <div block="MyAccountTabList" elem="Separator" />;
    }

    renderLogoutTab(): ReactElement {
        const { handleLogout } = this.props;

        return (
            <li key="logout" block="MyAccountTabListItem">
                <button block="MyAccountTabListItem" elem="Button" onClick={handleLogout} role="link">
                    {__('Logout')}
                </button>
            </li>
        );
    }

    render() {
        const { tabMap, activeTab, isContentExpanded, toggleExpandableContent } = this.props;
        const { tabName = '' } = tabMap[activeTab] || {};
        const isWishlistTab = activeTab === MyAccountTabs.MY_WISHLIST;

        const tabs = [...Object.entries(tabMap).map(this.renderTabListItem.bind(this)), this.renderLogoutTab()];

        return (
            <ExpandableContent
                heading={tabName}
                isContentExpanded={isContentExpanded}
                onClick={toggleExpandableContent}
                mix={{ block: 'MyAccountTabList', mods: { isWishlistTab } }}
            >
                <ul>{tabs}</ul>
            </ExpandableContent>
        );
    }
}

export default MyAccountTabListComponent;
