import { PureComponent } from 'react';

import { DiscountLabelProps } from './DiscountLabel.type';

import './DiscountLabel.style';

/** @namespace PlugAndSell2/Component/DiscountLabel/Component */
export class DiscountLabelComponent extends PureComponent<DiscountLabelProps> {
    render() {
        const { value, ...rest } = this.props;

        return value ? <span block="DiscountLabel" {...rest}>{`-${value}%`}</span> : null;
    }
}

export default DiscountLabelComponent;
