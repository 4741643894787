import React, { PureComponent } from 'react';

import { ReactElement } from 'Type/Common.type';

import { FontWeight, TypographyHeaderProps, Variant } from './TypographyHeader.type';

/** @namespace PlugAndSell2/Component/TypographyHeader/Component */
export class TypographyHeaderComponent extends PureComponent<TypographyHeaderProps> {
    static defaultProps = {
        tag: 'h1',
        variant: Variant.LARGE,
        fontWeight: FontWeight.REGULAR,
    };

    render(): ReactElement {
        const { tag, variant, fontWeight, children, mix, ...rest } = this.props;
        const HeadingTag = tag;

        return (
            <HeadingTag block="TypographyHeader" mods={{ variant, fontWeight }} mix={mix} {...(rest as any)}>
                {children}
            </HeadingTag>
        );
    }
}

export default TypographyHeaderComponent;
