import { PureComponent } from 'react';

import ChevronIcon from 'Component/ChevronIcon';
import { ReactElement } from 'Type/Common.type';

import { MyAccountTabListItemComponentProps } from './MyAccountTabListItem.type';

import './MyAccountTabListItem.style';

/** @namespace PlugAndSell2/Component/MyAccountTabListItem/Component */
export class MyAccountTabListItemComponent extends PureComponent<MyAccountTabListItemComponentProps> {
    static defaultProps: Partial<MyAccountTabListItemComponentProps> = {
        isActive: false,
        children: [],
    };

    __construct(props: MyAccountTabListItemComponentProps): void {
        super.__construct?.(props);

        this.changeActiveTab = this.changeActiveTab.bind(this);
    }

    changeActiveTab(): void {
        const {
            changeActiveTab,
            tabEntry: [key],
        } = this.props;

        changeActiveTab(key);
    }

    render(): ReactElement {
        const {
            children,
            tabEntry: [, { tabName }],
            isActive,
        } = this.props;

        return (
            <li block="MyAccountTabListItem" mods={{ isActive }}>
                <button block="MyAccountTabListItem" elem="Button" onClick={this.changeActiveTab} role="link">
                    {tabName}
                    <ChevronIcon />
                </button>
                {children}
            </li>
        );
    }
}

export default MyAccountTabListItemComponent;
