import Button from 'Component/Button';
import { ButtonVariant } from 'Component/Button/Button.type';
import KeyValueTable from 'Component/KeyValueTable';
import { DataPair } from 'Component/KeyValueTable/KeyValueTable.type';
import { Customer } from 'Query/MyAccount.type';
import { ReactElement } from 'Type/Common.type';

import { MyAccountCustomerTableComponentProps } from './MyAccountCustomerTable.type';

/** @namespace PlugAndSell2/Component/MyAccountCustomerTable/Component */
export class MyAccountCustomerTableComponent extends KeyValueTable<MyAccountCustomerTableComponentProps> {
    // @ts-ignore
    dataPairArray(): DataPair<Partial<Customer>>[] {
        const { customer, isExtendedData } = this.props;

        if (isExtendedData) {
            return [
                {
                    key: 'email',
                    label: __('Email'),
                    source: customer,
                },
                {
                    key: 'firstname',
                    label: __('First name'),
                    source: customer,
                },
                {
                    key: 'lastname',
                    label: __('Last name'),
                    source: customer,
                },
            ];
        }

        return [
            {
                key: 'firstname',
                label: __('First name'),
                source: customer,
            },
            {
                key: 'lastname',
                label: __('Last name'),
                source: customer,
            },
            {
                key: 'taxvat',
                label: __('Tax/VAT Number'),
                source: customer,
            },
            {
                key: 'email',
                label: __('Email'),
                source: customer,
            },
        ];
    }

    renderActions() {
        const { showChangePasswordPopup, showEditPopup, handleOnEditPassword, handleOnEditInformation, isExtendedData, isMobile } = this.props;

        if (isExtendedData) {
            return (
                <>
                    <Button variant={ButtonVariant.SECONDARY} onClick={showEditPopup}>
                        {__('Edit details')}
                    </Button>
                    <Button variant={ButtonVariant.LIKE_LINK} onClick={showChangePasswordPopup}>
                        {__('Change password')}
                    </Button>
                </>
            );
        }

        if (isMobile) {
            return (
                <>
                    <Button variant={ButtonVariant.SECONDARY} onClick={showEditPopup}>
                        {__('Edit details')}
                    </Button>
                    <Button variant={ButtonVariant.LIKE_LINK} onClick={showChangePasswordPopup}>
                        {__('Change password')}
                    </Button>
                </>
            );
        }

        return (
            <>
                <Button variant={ButtonVariant.SECONDARY} onClick={handleOnEditInformation}>
                    {__('Edit details')}
                </Button>
                <Button variant={ButtonVariant.LIKE_LINK} onClick={handleOnEditPassword}>
                    {__('Change password')}
                </Button>
            </>
        );
    }

    render(): ReactElement {
        return (
            <div block="MyAccountCustomerTable">
                {this.renderTable()}
                {this.renderActions()}
            </div>
        );
    }
}

export default MyAccountCustomerTableComponent;
