import FieldForm from 'Component/FieldForm';
import { FieldContainerProps } from 'Component/Field/Field.type';
import { ReactElement } from 'Type/Common.type';

import { ContractorStructureCreateFormComponentProps } from './ContractorStructureCreateForm.type';
import { FormContainerProps } from 'Component/Form/Form.type';
import Loader from 'Component/Loader';
import { FieldOptions } from 'Type/Field.type';

import './ContractorStructureCreateForm.style'
import { contractorStructureCreateForm } from './ContractorStructureCreateForm.form';

class ContractorStructureCreateForm extends FieldForm<ContractorStructureCreateFormComponentProps>  {
  fieldMap(): Partial<FieldContainerProps>[] {
    const { countries, substructures } = this.props;

    const typeOptions: FieldOptions[] = [{ id: 'type-department', label: 'Department', value: 'department' }]
    const parentStructures: FieldOptions[] = (substructures || [])
      .filter(substructure => substructure.type === 'department')
      .map(structure => ({ id: structure.id, label: structure.name, value: structure.id }))

    return contractorStructureCreateForm({ countries, parentStructures, typeOptions });
  }

  getFormProps(): Partial<FormContainerProps> {
    const { onFormError, onFormSubmit } = this.props;

    return {
        onError: onFormError,
        onSubmit: onFormSubmit,
    };
  }

  renderActions(): ReactElement {
    return (<button type='submit'>{__('Submit')}</button>)
  }

  render(): ReactElement {
    const { isLoading } = this.props;

    return (
      <div block="ContractorStructureCreateForm">
        {super.render()}
        <Loader isLoading={isLoading} />
      </div>
    )
  }
}

export default ContractorStructureCreateForm;