import { createRef, PureComponent } from 'react';
import { createPortal } from 'react-dom';

import { ReactElement } from 'Type/Common.type';
import { toggleScroll } from 'Util/Browser';
import { noopFn } from 'Util/Common';

import { OverlayComponentProps } from './Overlay.type';

import './Overlay.style';

/** @namespace PlugAndSell2/Component/Overlay/Component */
export class OverlayComponent<P extends OverlayComponentProps = OverlayComponentProps> extends PureComponent<P> {
    static defaultProps: Partial<OverlayComponentProps> = {
        mix: {},
        contentMix: {},
        children: [],
        onVisible: noopFn,
        isStatic: false,
        onHide: noopFn,
        onClose: noopFn,
        isRenderInPortal: true,
        showBackground: false,
    };

    overlayRef = createRef<HTMLDivElement>();

    YoffsetWhenScrollDisabled = 0;

    componentDidUpdate(prevProps: P): void {
        const prevWasVisible = this.getIsVisible(prevProps);
        const isVisible = this.getIsVisible();

        if (isVisible && !prevWasVisible) {
            this.onVisible();
        }

        if (!isVisible && prevWasVisible) {
            this.onHide();
        }
    }

    onVisible(): void {
        const { onVisible, isStatic, isMobile } = this.props;

        if (isStatic || !onVisible) {
            return;
        }

        if (isMobile) {
            this.freezeScroll();
        }

        this.overlayRef.current?.focus();

        if (onVisible) {
            onVisible();
        }
    }

    onHide(): void {
        const { onHide, isStatic, isMobile } = this.props;

        if (isStatic) {
            return;
        }

        if (isMobile) {
            this.unfreezeScroll();
        }

        if (onHide) {
            onHide();
        }
    }

    getIsVisible(props: P = this.props): boolean {
        const { id, activeOverlay, isStatic } = props;

        return isStatic || id === activeOverlay;
    }

    freezeScroll(): void {
        this.YoffsetWhenScrollDisabled = window.pageYOffset || document.body.scrollTop;
        toggleScroll(false);
        document.body.style.marginTop = `${-this.YoffsetWhenScrollDisabled}px`;
    }

    unfreezeScroll(): void {
        toggleScroll(true);
        document.body.style.marginTop = '0';
        window.scrollTo(0, this.YoffsetWhenScrollDisabled);
    }

    renderInMobilePortal(content: ReactElement): ReactElement {
        const { isStatic, isRenderInPortal, isMobile } = this.props;

        if (!isStatic && isMobile && isRenderInPortal) {
            return createPortal(content, document.body);
        }

        return content;
    }

    handleHideOverlay(): void {
        const { hideActiveOverlay, goToPreviousNavigationState } = this.props;
        const isVisible = this.getIsVisible();

        if (isVisible) {
            hideActiveOverlay();
            goToPreviousNavigationState();
        }
    }

    hideOverlay = () => {
        this.handleHideOverlay();
    };

    renderOverlayBackground(): ReactElement {
        return (
            <a block="Overlay" elem="Background" onClick={this.hideOverlay}>
                &nbsp;
            </a>
        );
    }

    render(): ReactElement {
        const { children, mix, areOtherOverlaysOpen, isStatic, showBackground } = this.props;

        const isVisible = this.getIsVisible();

        return this.renderInMobilePortal(
            <>
                <div
                    block="Overlay"
                    ref={this.overlayRef}
                    mods={{ isVisible, isStatic, isInstant: areOtherOverlaysOpen }}
                    mix={{ ...mix, mods: { ...(mix?.mods || {}), isVisible } }}
                >
                    {children && children}
                </div>
                {showBackground && isVisible && this.renderOverlayBackground()}
            </>
        );
    }
}

export default OverlayComponent;
