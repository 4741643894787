import { ImageState } from 'Component/Image/Image.config';

const MAGENTO_IMAGE_OMEN = '{{media';

const renderMagentoImage = (instance, src) => {
    const { alt, isPlaceholder, style, title } = instance.props;
    const { imageStatus } = instance.state;

    if (isPlaceholder) {
        return null;
    }

    switch (imageStatus) {
        case ImageState.IMAGE_NOT_FOUND:
            return instance.renderImageNotFound();
        case ImageState.IMAGE_NOT_SPECIFIED:
            return (
                <span block="Image" elem="Content">
                    {__('Image not specified')}
                </span>
            );
        case ImageState.IMAGE_LOADED:
        case ImageState.IMAGE_LOADING:
            return (
                <img
                    block="Image"
                    elem="Image"
                    src={`/media/${src}` || ''}
                    alt={alt}
                    mods={{ isLoading: imageStatus === IMAGE_LOADING }}
                    style={style}
                    title={title}
                    onLoad={instance.onLoad}
                    onError={instance.onError}
                />
            );
        default:
            return null;
    }
}

const verifyImage = (src = '') =>  {
    if (src && src.includes(MAGENTO_IMAGE_OMEN)) {
        return src.match(/(?:"[^"]*"|^[^"]*$)/)[0].replace(/"/g, '');
    }

    return false;
}

const renderImage = (args, callback, instance) => {
  const { src } = instance.props;

  const magentoImage = verifyImage(src);

  if (!magentoImage) {
      return <>{callback.apply(instance, args)}</>;
  }

  return renderMagentoImage(instance, magentoImage);
}

export default {
    'PlugAndSell2/Component/Image/Component': {
        'member-function': {
            renderImage,
        },
    },
};
