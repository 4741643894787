import { PureComponent } from 'react';
import SlickSlider from 'react-slick';

import { DEFAULT_SLICK_SETTINGS } from 'Component/FeaturedBrands/FeaturedBrands.config';
import ImageLazyLoading from 'Component/ImageLazyLoading';
import Link from 'Component/Link';
import SliderNextArrow from 'Component/SliderNextArrow';
import SliderPrevArrow from 'Component/SliderPrevArrow';
import TypographyHeader from 'Component/TypographyHeader';
import { Variant } from 'Component/TypographyHeader/TypographyHeader.type';
import { BrandItems } from 'Query/Brands.type';

import { FeaturedBrandsComponentProps } from './FeaturedBrands.type';

import './FeaturedBrands.style';

/** @namespace PlugAndSell2/Component/FeaturedBrands/Component */
export class FeaturedBrandsComponent extends PureComponent<FeaturedBrandsComponentProps> {
    renderSliderSlides(sliderItems: BrandItems[]) {
        return sliderItems.map(({ brand_id, image, url_key, value }) => (
            <div block="FeaturedBrands" elem="Brand" key={`slider${brand_id}`}>
                <Link to={url_key}>
                    <ImageLazyLoading className="FeaturedBrands-Img" src={image} alt={value} lazyLoadingType="brandsSlider" />
                </Link>
            </div>
        ));
    }

    render() {
        const { sliderItems } = this.props;

        if (!sliderItems || !sliderItems.length) {
            return null;
        }

        return (
            <div block="FeaturedBrands">
                <TypographyHeader tag="h4" variant={Variant.MEDIUM} mix={{ block: 'FeaturedBrands', elem: 'Title' }}>
                    {__('Popular brands')}
                </TypographyHeader>
                <SlickSlider prevArrow={<SliderPrevArrow />} nextArrow={<SliderNextArrow />} {...DEFAULT_SLICK_SETTINGS}>
                    {this.renderSliderSlides(sliderItems)}
                </SlickSlider>
            </div>
        );
    }
}

export default FeaturedBrandsComponent;
