import { HideActiveOverlayAction, HideActivePopupAction, OverlayActionType, ToggleOverlayByKeyAction } from './Overlay.type';

/** @namespace PlugAndSell2/Store/Overlay/Action/toggleOverlayByKey */
export const toggleOverlayByKey = (overlayKey: string): ToggleOverlayByKeyAction => ({
    type: OverlayActionType.TOGGLE_OVERLAY,
    overlayKey,
});

/** @namespace PlugAndSell2/Store/Overlay/Action/hideActiveOverlay */
export const hideActiveOverlay = (): HideActiveOverlayAction => ({
    type: OverlayActionType.HIDE_ACTIVE_OVERLAY,
});

/** @namespace PlugAndSell2/Store/Overlay/Action/hideActivePopup */
export const hideActivePopup = (shouldPopupClose = true): HideActivePopupAction => ({
    type: OverlayActionType.HIDE_ACTIVE_POPUP,
    payload: shouldPopupClose,
});
