/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */
import { EVENT_GTM_USER_LOGIN, EVENT_GTM_USER_REGISTER } from '../component/GoogleTagManager/GoogleTagManager.events';
import { event } from '../store/GoogleTagManager/GoogleTagManager.action';

export const createAccount = (args, callback) => {
    const original = callback(...args);
    const [_, dispatch] = args;

    return original.then((signInPromise) => {
        dispatch(event(EVENT_GTM_USER_REGISTER, { email: args[0].customer.email }));

        return signInPromise;
    });
};

export const signIn = (args, callback) => {
    const original = callback(...args);
    const [_, dispatch] = args;

    return original.then((result) => {
        dispatch(event(EVENT_GTM_USER_LOGIN, { email: args[0].email }));

        return result;
    });
};

export default {
    'PlugAndSell2/Store/MyAccount/Dispatcher': {
        'member-function': {
            signIn,
            createAccount,
        },
    },
};
