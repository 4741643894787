import React, { Children, cloneElement, Fragment, isValidElement, PureComponent } from 'react';

import { withPopper } from '../../util/Popper/withPopper';
import { TooltipComponentProps } from './Tooltip.type'
import { ReactElement } from 'Type/Common.type';

export class Tooltip extends PureComponent<TooltipComponentProps> {
    render(): ReactElement {
        const { popper, reference, children, setReferenceElement, setPopperElement } = this.props;
        const { styles } = popper ?? {};

        if (!reference || !isValidElement(reference)) {
            return null;
        }

        return (
            <>
                {/* @ts-ignore */}
                {cloneElement(reference, { ref: setReferenceElement })}
                {Children.map(children, (child) => {
                    // @ts-ignore
                    if (child && (!child.type || child.type !== Fragment) && isValidElement(child)) {
                        // @ts-ignore
                        return cloneElement(child, { ref: setPopperElement, style: styles.popper });
                    }

                    return (
                        <span style={styles?.popper} ref={setPopperElement}>
                            {child}
                        </span>
                    );
                })}
            </>
        );
    }
}

export default withPopper(Tooltip, {
    placement: 'right',
    strategy: 'fixed',
    modifiers: [
        {
            name: 'offset',
            options: {
                offset: [0, 16],
            },
        },
    ],
});
