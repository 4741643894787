/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa-theme
 * @link https://github.com/scandipwa/scandipwa-theme
 */
import { AnyAction } from 'redux';

import { KevinBank } from 'Query/Checkout.type';

export enum CheckoutActionType {
    UPDATE_SHIPPING_FIELDS = 'UPDATE_SHIPPING_FIELDS',
    UPDATE_EMAIL = 'UPDATE_EMAIL',
    UPDATE_EMAIL_AVAILABLE = 'UPDATE_EMAIL_AVAILABLE',
    UPDATE_KEVIN_BANKS_LIST = 'UPDATE_KEVIN_BANKS_LIST',
    UPDATE_KEVIN_SELECTED_BANK = 'UPDATE_KEVIN_SELECTED_BANK',
    UPDATE_ORDER_BUTTON_ENABLE_STATUS = 'UPDATE_ORDER_BUTTON_ENABLE_STATUS',
}

export interface UpdateShippingFieldsAction extends AnyAction {
    type: CheckoutActionType.UPDATE_SHIPPING_FIELDS;
    shippingFields: Record<string, unknown>;
}
export interface UpdateEmailAction extends AnyAction {
    type: CheckoutActionType.UPDATE_EMAIL;
    email: string;
}
export interface UpdateEmailAvailableAction extends AnyAction {
    type: CheckoutActionType.UPDATE_EMAIL_AVAILABLE;
    isEmailAvailable: boolean;
}

export interface UpdateKevinSelectedBankAction extends AnyAction {
    type: CheckoutActionType.UPDATE_KEVIN_SELECTED_BANK;
    bank: KevinBank | null;
}

export interface UpdateIsOrderButtonEnabledAction extends AnyAction {
    type: CheckoutActionType.UPDATE_ORDER_BUTTON_ENABLE_STATUS;
    isOrderButtonEnabled: boolean;
}

export interface UpdateKevinBanksList extends AnyAction {
    type: CheckoutActionType.UPDATE_KEVIN_BANKS_LIST;
    banks: KevinBank[] | null;
}

export type CheckoutAction =
    | UpdateIsOrderButtonEnabledAction
    | UpdateShippingFieldsAction
    | UpdateEmailAction
    | UpdateEmailAvailableAction
    | UpdateKevinSelectedBankAction
    | UpdateKevinBanksList;

export interface CheckoutStore extends KevinCheckoutReducerExtension {
    shippingFields: Record<string, unknown>;
    email: string;
    isEmailAvailable: boolean;
    isOrderButtonEnabled: boolean;
}

declare module 'Util/Store/Store.type' {
    export interface RootState {
        CheckoutReducer: CheckoutStore;
    }
}

export interface CheckoutDispatcherData {
    isEmailAvailable: {
        is_email_available: boolean;
    };
}

export interface KevinCheckoutReducerExtension {
    kevinSelectedBank: KevinBank | null;
    kevinBanksList: KevinBank[] | null;
}
