import { DataPair } from 'Component/KeyValueTable/KeyValueTable.type';
import { CustomerAddress } from 'Query/MyAccount.type';
import { OrderAddress } from 'Query/Order.type';
import { Country } from 'Query/Region.type';
import { getFormattedRegion } from 'Util/Address';
import { FormattedRegion } from 'Util/Address/Address.type';

import { MyAccountAddressTableComponentProps } from './MyAccountAddressTable.type';

/** @namespace PlugAndSell2/Component/MyAccountAddressTable/Table/getAddressAdditionalTableFields */
export const getAddressAdditionalTableFields = (
    address: CustomerAddress | OrderAddress,
    countries: Country[]
): DataPair<CustomerAddress | FormattedRegion>[] => {
    const regionData = getFormattedRegion(address, countries);

    return [
        {
            key: 'country',
            label: __('County'),
            source: regionData,
        },
        {
            key: 'region',
            label: __('State/Province'),
            source: regionData,
        },
        {
            key: 'city',
            label: __('City'),
            source: address,
        },
        {
            key: 'vat_id',
            label: __('VAT Number'),
            source: address,
        },
    ];
};

/** @namespace PlugAndSell2/Component/MyAccountAddressTable/Table/getAddressTablePairArray */
export const getAddressTablePairArray = (
    props: MyAccountAddressTableComponentProps
): DataPair<CustomerAddress | FormattedRegion | Record<string, string>>[] => {
    const { address, countries, showAdditionalFields, showAddressDetails, isExtendedData } = props;
    const regionData = getFormattedRegion(address, countries);
    const fullAddressField = { address: `${address?.city}, ${regionData?.region ? `${regionData?.region},` : ''} ${address?.postcode}` };

    const addressDetailsFields = [
        {
            key: 'address',
            label: __('Address'),
            source: fullAddressField,
        },
        {
            key: 'country',
            label: __('Country'),
            source: regionData,
        },
        {
            key: 'telephone',
            label: __('No. phone'),
            source: address,
        },
    ];

    const additionalFields = [
        {
            key: 'city',
            label: __('City'),
            source: address,
        },
        {
            key: 'postcode',
            label: __('Postal code'),
            source: address,
        },
        {
            key: 'region',
            label: __('State/Province'),
            source: regionData,
        },
        {
            key: 'country',
            label: __('Country'),
            source: regionData,
        },
        {
            key: 'telephone',
            label: __('No. phone'),
            source: address,
        },
        {
            key: 'vat_id',
            label: __('VAT Number'),
            source: address,
        },
    ];

    const fullData = [
        {
            key: 'email',
            label: __('Email'),
            source: props,
        },
        {
            key: 'vat_id',
            label: __('VAT Number'),
            source: address,
        },
        {
            key: 'company',
            label: __('Company name'),
            source: props,
        },
        {
            key: 'firstname',
            label: __('First name'),
            source: address,
        },
        {
            key: 'lastname',
            label: __('Last name'),
            source: address,
        },
        {
            key: 'street',
            label: __('Street'),
            source: address,
        },
        {
            key: 'postcode',
            label: __('Postal code'),
            source: address,
        },
        {
            key: 'city',
            label: __('City'),
            source: address,
        },
        {
            key: 'region',
            label: __('State/Province'),
            source: regionData,
        },
        {
            key: 'country',
            label: __('Country'),
            source: regionData,
        },
    ];

    if (isExtendedData) {
        // @ts-ignore
        return Array.from(fullData);
    }

    return [
        {
            key: 'firstname',
            label: __('First name'),
            source: address,
        },
        {
            key: 'lastname',
            label: __('Last name'),
            source: address,
        },
        {
            key: 'street',
            label: __('Street'),
            source: address,
        },
        ...(showAdditionalFields ? additionalFields : []),
        ...(showAddressDetails ? addressDetailsFields : []),
    ];
};

export default getAddressTablePairArray;
