import { PureComponent } from 'react';

import Link from 'Component/Link';
import { ReactElement } from 'Type/Common.type';

import { PaginationLinkComponentProps } from './PaginationLink.type';

import './PaginationLink.style';

/** @namespace PlugAndSell2/Component/PaginationLink/Component */
export class PaginationLinkComponent extends PureComponent<PaginationLinkComponentProps> {
    static defaultProps: Partial<PaginationLinkComponentProps> = {
        children: [],
    };

    getSearchQueryForPage(): string {
        const { getSearchQueryForPage, pageNumber } = this.props;

        return getSearchQueryForPage(pageNumber);
    }

    render(): ReactElement {
        const { onNavigate, label, pageNumber, url_path: pathname, isCurrent, children } = this.props;

        const search = this.getSearchQueryForPage();

        const { state = {} } = history.state || {};

        return (
            <Link
                to={{
                    search,
                    pathname,
                    state,
                }}
                aria-label={label}
                aria-current={isCurrent ? 'page' : 'false'}
                block="PaginationLink"
                mods={{ isCurrent, isArrow: typeof children !== 'string' }}
                onClick={() => onNavigate && onNavigate(pageNumber)}
            >
                {children}
            </Link>
        );
    }
}

export default PaginationLinkComponent;
