import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { ReactElement } from 'Type/Common.type';
import { RootState } from 'Util/Store/Store.type';

import MyAccountForgotPasswordSuccess from './MyAccountForgotPasswordSuccess.component';
import {
    MyAccountForgotPasswordSuccessContainerMapDispatchProps,
    MyAccountForgotPasswordSuccessContainerMapStateProps,
    MyAccountForgotPasswordSuccessContainerProps,
    MyAccountForgotPasswordSuccessContainerPropsKeys,
} from './MyAccountForgotPasswordSuccess.type';

/** @namespace PlugAndSell2/Component/MyAccountForgotPasswordSuccess/Container/mapStateToProps */
export const mapStateToProps = (state: RootState): MyAccountForgotPasswordSuccessContainerMapStateProps => ({
    submittedEmail: state.MyAccountReducer.email,
});

/** @namespace PlugAndSell2/Component/MyAccountForgotPasswordSuccess/Container/mapDispatchToProps */
export const mapDispatchToProps = (): MyAccountForgotPasswordSuccessContainerMapDispatchProps => ({});

/** @namespace PlugAndSell2/Component/MyAccountForgotPasswordSuccess/Container */
export class MyAccountForgotPasswordSuccessContainer extends PureComponent<MyAccountForgotPasswordSuccessContainerProps> {
    containerProps(): Pick<MyAccountForgotPasswordSuccessContainerProps, MyAccountForgotPasswordSuccessContainerPropsKeys> {
        const { state, handleSignIn, submittedEmail } = this.props;

        return {
            state,
            handleSignIn,
            submittedEmail,
        };
    }

    render(): ReactElement {
        return <MyAccountForgotPasswordSuccess {...this.containerProps()} />;
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MyAccountForgotPasswordSuccessContainer);
