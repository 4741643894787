import get from 'lodash/get';
import has from 'lodash/has';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import Icons from 'Component/Icons';
import Loader from 'Component/Loader';
import { MixType } from 'Type/Common.type';
import { noopFn } from 'Util/Common';

import './Table.style';

/** @namespace Pwabb/Component/Table/getFormattedDate */
export const getFormattedDate = (rawDate = '', separator = '.') => {
  const date = new Date(rawDate.replace(/\s/, 'T'));
  const RADIX = 10;

  const addLeadingZero = (value) => (value < RADIX ? `0${value}` : value);

  const day = addLeadingZero(date.getDate());
  const month = addLeadingZero(date.getMonth() + 1);

  return [day, month, date.getFullYear()].filter(Boolean).join(separator);
};

/** @namespace Pwabb/Component/Table/getFormattedDateTime */
export const getFormattedDateTime = (
  rawDate = '',
  { dateSeparator, timeSeparator } = { dateSeparator: '-', timeSeparator: ':' }
) => {
  const date = new Date(rawDate.replace(/\s/, 'T'));
  const RADIX = 10;

  const addLeadingZero = (value) => (value < RADIX ? `0${value}` : value);

  const hour = addLeadingZero(date.getHours());
  const minutes = addLeadingZero(date.getMinutes());
  const day = addLeadingZero(date.getDate());
  const month = addLeadingZero(date.getMonth() + 1);

  return [
      [day, month, date.getFullYear()].filter(Boolean).join(dateSeparator),
      [hour, minutes].filter(Boolean).join(timeSeparator),
  ]
      .filter(Boolean)
      .join(', ');
};

// TODO: add strong typing later

/** @namespace Pwabb/Component/Table/Component */
export class Table extends PureComponent {
    static propTypes = {
        isLoading: PropTypes.bool,
        isMobile: PropTypes.bool,
        columns: PropTypes.arrayOf(
            PropTypes.shape({
                key: PropTypes.string.isRequired,
                label: PropTypes.string,
                component: PropTypes.func,
                mix: MixType,
                type: PropTypes.oneOf(['component', 'date', 'text']),
                mobile: PropTypes.shape({
                    head: PropTypes.shape({
                        visible: PropTypes.bool,
                        mix: MixType,
                    }),
                    cell: PropTypes.shape({
                        visible: PropTypes.bool,
                        mix: MixType,
                    }),
                }),
            })
        ),
        enableChevron: PropTypes.bool,
        data: PropTypes.array,
        row: PropTypes.shape({
            mix: MixType,
        }),
        loading: PropTypes.shape({
            label: PropTypes.string,
        }),
        empty: PropTypes.shape({
            label: PropTypes.string,
            mix: MixType,
        }),
        table: PropTypes.shape({
            mix: MixType,
        }),
        container: PropTypes.shape({
            mix: MixType,
        }),
        activeRows: PropTypes.array,
    };

    static defaultProps = {
        isMobile: false,
        isLoading: false,
        columns: [],
        data: [],
        empty: null,
        enableChevron: false,
        row: null,
        table: null,
        container: null,
        activeRows: [],
    };

    renderValue(data, options) {
        const { component, type, key } = options ?? {};

        switch (type) {
            case 'component': {
                if (typeof component !== 'function') return '-';
                return component(data);
            }
            case 'datetime': {
                if (!has(data, key)) {
                    return '-';
                }

                try {
                    return getFormattedDateTime(get(data, key));
                } catch {
                    return '-';
                }
            }
            case 'date': {
                if (!has(data, key)) {
                    return '-';
                }

                try {
                    return getFormattedDate(get(data, key));
                } catch {
                    return '-';
                }
            }
            default: {
                if (!has(data, key)) {
                    return '-';
                }

                return get(data, key);
            }
        }
    }

    renderItem(data, column, index) {
        const { enableChevron, isMobile } = this.props;
        const {
            component,
            type = 'text',
            key,
            mobile = {},
            label,
            mix: desktopMix = {},
            format,
            ...desktopProps
        } = column ?? {};
        const { cell } = mobile ?? {};
        const { visible = true, mix: mobileMix = {}, ...mobileProps } = cell ?? {};

        if (isMobile) {
            if (!visible) {
                return null;
            }

            return (
                <td block="Table" elem="Cell" mods={{chevron: enableChevron && index === 0}} mix={mobileMix} {...mobileProps} key={index}>
                    {typeof component === 'function' && type !== 'component'
                        ? component(this.renderValue(data, { component, type, key }), data)
                        : null}
                    {typeof component === 'function' && type === 'component' ? component(data) : null}
                    {typeof component !== 'function' ? this.renderValue(data, { component, type, key, format }) : null}
                </td>
            );
        }

        return (
            <td block="Table" elem="Cell" mods={{chevron: enableChevron && index === 0}} mix={desktopMix} {...desktopProps} key={index}>
                {typeof component === 'function' && type !== 'component'
                    ? component(this.renderValue(data, { component, type, key }), data)
                    : null}
                {typeof component === 'function' && type === 'component' ? component(data) : null}
                {typeof component !== 'function' ? this.renderValue(data, { component, type, key, format }) : null}
            </td>
        );
    }

    renderEmpty() {
        const { columns, empty } = this.props;
        const { label = __('No data') } = empty ?? {};

        return (
            <td block="Table" elem="Cell" colSpan={columns.length}>
                {label}
            </td>
        );
    }

    renderLoading() {
        const { columns, loading } = this.props;
        const { label = __('Loading...') } = loading ?? {};

        return (
            <td block="Table" elem="Cell" colSpan={columns.length}>
                {label}
            </td>
        );
    }

    renderBody(data) {
        const { isLoading, columns, row, activeRows, renderActiveRow} = this.props;
        const { mix, onClick, ...props } = row ?? {};

        if (isEmpty(data)) {
            if (isLoading) {
                return (
                    <tbody>
                        <tr block="Table" elem="Row" mix={typeof mix === 'function' ? mix() : mix} {...props}>
                            {this.renderLoading()}
                        </tr>
                    </tbody>
                );
            }

            return (
                <tbody>
                    <tr block="Table" elem="Row" mix={typeof mix === 'function' ? mix() : mix} {...props}>
                        {this.renderEmpty()}
                    </tr>
                </tbody>
            );
        }

        return (
            <tbody>
                {data.map((item, index) => {
                    const { id } = item;

                    return (
                      <>
                        <tr
                            block="Table"
                            elem="Row"
                            mix={typeof mix === 'function' ? mix(item) : mix}
                            onClick={typeof onClick === 'function' ? () => onClick(item) : noopFn}
                            key={`body${index}`}
                            mods={{ isActive: id && activeRows.includes(id) }}
                            {...props}
                        >
                            {columns.map((column, index) => this.renderItem(item, column, index))}
                        </tr>
                        {activeRows.includes(id) && (
                            <tr block="Table" elem="Row" mods={{ expandable: true }} colSpan={columns.length}>
                                {renderActiveRow(item)}
                            </tr>
                        )}
                      </>
                    );
                })}
            </tbody>
        );
    }

    renderSortArrow() {
        const { tableRowsSortType } = this.props;

        return tableRowsSortType === 'ASC' ? <Icons name="dropdown_arrow" /> : <Icons name="dropdown_arrow_up" />;
    }

    renderHead(column) {
        const { columns, row, isMobileCompanyStructure, sortTableRows } = this.props;
        const { mix: desktopMix = {}, ...desktopProps } = row ?? {};

        if (isMobileCompanyStructure) {
            return null;
        }

        if (column) {
            const { label, mobile } = column ?? {};
            const { head } = mobile ?? {};
            const { visible = true, mix: mobileMix, ...columnProps } = head ?? {};

            if (!visible) {
                return null;
            }

            return (
                <th block="Table" elem="Head" mix={mobileMix} {...columnProps}>
                    {label}
                </th>
            );
        }

        return (
            <thead>
                <tr block="Table" elem="Row" mix={desktopMix} {...desktopProps}>
                    {/* eslint-disable-next-line no-unused-vars */}
                    {columns.map(({ key, label, mix, component, isSortable, ...props }) => (
                        <th block="Table" elem="Head" mix={mix} {...props} onClick={() => sortTableRows(key)}>
                            {label}
                            {isSortable ? this.renderSortArrow() : ''}
                        </th>
                    ))}
                </tr>
            </thead>
        );
    }

    renderTable() {
        const { isLoading, table, data } = this.props;
        const { mix: tableMix } = table ?? {};

        if (isLoading) {
            return (
                <table block="Table" elem="Table" mix={tableMix}>
                    {this.renderHead()}
                    {this.renderBody(data)}
                </table>
            );
        }

        if (isEmpty(data)) {
            return (
                <table block="Table" elem="Table" mix={tableMix}>
                    {this.renderHead()}
                    {this.renderBody(data)}
                </table>
            );
        }

        return (
            <table block="Table" elem="Table" mix={tableMix}>
                {this.renderHead()}
                {this.renderBody(data)}
            </table>
        );
    }

    render() {
        const { isLoading, isMobile, container } = this.props;
        const { mix } = container ?? {};

        return (
            <div block="Table" elem="Wrapper" mods={{ isMobile }} mix={mix}>
                {this.renderTable()}
                <Loader isLoading={isLoading} />
            </div>
        );
    }
}

export default Table;
