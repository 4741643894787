import { PureComponent } from 'react';
import Table from '../Table';
import { RmaMyReturnsTableComponentProps } from './RmaMyReturnsTable.type';
import { ReactElement } from 'Type/Common.type';

import './RmaMyReturnsTable.style'
import Button from 'Component/Button';
import { ButtonVariant } from 'Component/Button/Button.type';
import RmaDetails from '../RmaDetails';
import { RmaDetailsScreenType } from '../RmaDetails/RmaDetails.type';

export class RmaMyReturnsTable extends PureComponent<RmaMyReturnsTableComponentProps> {
  renderActions(id: number): ReactElement {
    const { onViewClicked } = this.props;
    
    return (
      <Button variant={ButtonVariant.LIKE_LINK} onClick={() => onViewClicked(id)}>
        {__('View')}
      </Button>
    )
  }

  renderDetails(): ReactElement {
    return (
      <RmaDetails type={RmaDetailsScreenType.OVERVIEW} />
    )
  }

  renderError(): ReactElement {
    const { error } = this.props
    
    return (
      <div block="RmaMyReturnsTable" elem="Error">
        {error}
      </div>
    )
  }

  renderNoItems(): ReactElement {
    return (
      <div block="RmaMyReturnsTable" elem="NoItems">
        {__('There are no possible returns to display.')}
      </div>
    )
  }

  renderStatusLabel(status: string): ReactElement {
    const { rmaStatuses } = this.props;

    const label = rmaStatuses[Number(status)];

    return (
      <span block="RmaMyReturnsTable" elem="StatusLabel" mods={{ type: label }}>
        {label}
      </span>
    )
  }

  renderMobileTable(): ReactElement {
    const { rmaMyReturnsData } = this.props;

    return (
      <div block="RmaMyReturnsTable" elem="MobileTable">
        {rmaMyReturnsData.map(item => (
          <div block="RmaMyReturnsTable" elem="MobileTableItem">
            <dl>
              <dt>{__('RMA')}:</dt>
              <dd>#{item.increment_id}</dd>
              <dt>{__('Order')}:</dt>
              <dd>{item.order_id}</dd>
              <dt>{__('Date')}:</dt>
              <dd>{item.date_created}</dd>
              <dt>{__('Status')}</dt>
              <dd>{this.renderStatusLabel(item.status || '')}</dd>
              <dt>{__('Actions')}:</dt>
              <dd>{this.renderActions(Number(item.id))}</dd>
            </dl>
          </div>
        ))}
      </div>
    )
  }

  renderTable(): ReactElement {
    const { device, isLoading, rmaMyReturnsData } = this.props;

    if (rmaMyReturnsData.length === 0 && !isLoading) {
      return this.renderNoItems()
    }

    if (device.isMobile) {
      return (this.renderMobileTable())
    }
    
    return (
      <Table
        data={rmaMyReturnsData}
        isLoading={isLoading}
        row={{
            mix: (user = {}) => {
                if (!user) {
                    return {};
                }

                return { block: 'RmaMyReturnsTable', elem: 'Row' };
            },
        }}
        columns={[
            {
                key: 'increment_id',
                label: __('RMA'),
                component: (increment_id: string) => 
                  <span>#{increment_id}</span>,
            },
            {
                key: 'order_id',
                label: __('Order number'),
                component: (order_id: number) => 
                  <span>#{order_id}</span>,
            },
            {
                key: 'date_created',
                label: __('Date'),
                component: (date_created: string) => 
                  <span>{date_created.split(' ')[0]}</span>,
            },
            {
                key: 'status',
                label: __('Status'),
                component: (status: string) => this.renderStatusLabel(status)
            },
            
            {
                key: 'id',
                mix: { block: 'RmaMyReturnsTable', elem: 'Actions' },
                component: (_: any, { id }: { id: number }) => { return this.renderActions(id)},
            },
        ]}
      />
    )
  }

  render() {
    return (
      <div block="RmaMyReturnsTable">
          {this.renderTable()}
          {this.renderError()}
      </div>
    );
  }
}


export default RmaMyReturnsTable;