/* eslint-disable no-magic-numbers */

export enum KeyCode {
    ENTER = 13,
    ESC = 27,
    TAB = 9,
    SHIFT = 16,
    CTRL = 17,
    ALT = 18,
    SPACE = 32,
    BACKSPACE = 8,
    ARROW_UP = 38,
    ARROW_DOWN = 40,
    ARROW_LEFT = 37,
    ARROW_RIGHT = 39,
    A = 65,
    a = 97,
    Z = 90,
    z = 122,
}
