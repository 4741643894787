import LazyLoad from 'react-lazyload';
import { connect } from 'react-redux';

import {
    CatalogWidgetComponentProps,
    CatalogWidgetContainerMapStateState,
    CatalogWidgetContainerProps,
    CatalogWidgetContainerState,
    CatalogWidgetItems,
} from 'Component/CatalogWidget/CatalogWidget.type';
import CatalogWidgetQuery from 'Query/CatalogWidget.query';
import { WidgetOutput, WidgetOutputItems } from 'Query/CatalogWidget.type';
import { ReactElement } from 'Type/Common.type';
import DataContainer from 'Util/Request/DataContainer';
import { RootState } from 'Util/Store/Store.type';

import CatalogWidget from './CatalogWidget.component';

/** @namespace PlugAndSell2/Component/CatalogWidget/Container/mapStateToProps */
export const mapStateToProps = (state: RootState): CatalogWidgetContainerMapStateState => ({
    productUrlSuffix: state.ConfigReducer.product_url_suffix,
});

/** @namespace PlugAndSell2/Component/CatalogWidget/Container/mapDispatchToProps */
export const mapDispatchToProps = () => ({});

/** @namespace PlugAndSell2/Component/CatalogWidget/Container */
export class CatalogWidgetContainer extends DataContainer<CatalogWidgetContainerProps, CatalogWidgetContainerState> {
    static defaultProps = {
        productUrlSuffix: '',
    };

    state: CatalogWidgetContainerState = {
        items: [],
        title: null,
        isLoading: false,
    };

    containerFunctions = {};

    componentDidMount(): void {
        this.getCatalogWidgetData();
    }

    performDataTransformations(data: WidgetOutputItems[]): CatalogWidgetItems[] {
        const { productUrlSuffix } = this.props;

        return data.map((item) => ({
            ...item,
            url: `${item.url_key}${productUrlSuffix}`,
        }));
    }

    getCatalogWidgetData(): void {
        const { code } = this.props;

        this.setState({ isLoading: true });

        this.fetchData(
            [CatalogWidgetQuery.getCatalogWidgetQuery(`${code}`, { isPlp: true, noVariantAttributes: true })],
            ({ catalogWidget }: { catalogWidget: WidgetOutput }) => {
                const { items, title } = catalogWidget;
                this.setState({
                    items: this.performDataTransformations(items),
                    title,
                    isLoading: false,
                });
            },
            () => {
                this.setState({
                    items: [],
                    title: null,
                    isLoading: false,
                });
            }
        );
    }

    containerProps(): Pick<CatalogWidgetComponentProps, 'items' | 'title' | 'isLoading'> {
        const { items, title, isLoading } = this.state;

        return {
            items,
            title,
            isLoading,
        };
    }

    render(): ReactElement {
        return (
            <LazyLoad once offset={100}>
                <CatalogWidget {...this.containerFunctions} {...this.containerProps()} />
            </LazyLoad>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CatalogWidgetContainer);
