import { FormattedStore } from 'Component/StoreSwitcher/StoreSwitcher.type';
import getStore from 'Util/Store';

/** @namespace PlugAndSell2/Util/Store/getCurrentStoreId */
export const getCurrentStoreId = () => {
    const {
        ConfigReducer: { code = null, storeList = [] },
    } = getStore().getState();

    const filteredStore = storeList.filter((store: FormattedStore) => store.code === code);

    return filteredStore[0] ? filteredStore[0].id : null;
};
