import { connect } from 'react-redux';

import SliderQuery from 'Query/Slider.query';
import { Slider } from 'Query/Slider.type';
import DataContainer from 'Util/Request/DataContainer';
import { RootState } from 'Util/Store/Store.type';

import BannerSlider from './BannerSlider.component';
import {
    BannerSliderComponentProps,
    BannerSliderContainerMapStateProps,
    BannerSliderContainerProps,
    BannerSliderContainerState,
} from './BannerSlider.type';

/** @namespace PlugAndSell2/Component/BannerSlider/Container/mapStateToProps */
export const mapStateToProps = (state: RootState): BannerSliderContainerMapStateProps => ({
    isMobile: state.ConfigReducer.device.isMobile,
    isTablet: state.ConfigReducer.device.isTablet,
});

/** @namespace PlugAndSell2/Component/BannerSlider/Container/mapDispatchToProps */
export const mapDispatchToProps = () => ({});

/** @namespace PlugAndSell2/Component/BannerSlider/Container */
export class BannerSliderContainer extends DataContainer<BannerSliderContainerProps, BannerSliderContainerState> {
    state = {
        slider: {},
    };

    settings = {};

    componentDidMount(): void {
        this.getSliderData();
    }

    getSliderData(): void {
        const { position, code } = this.props;

        if (!code) {
            return;
        }

        this.fetchData<{ slider: Slider }>(
            [SliderQuery.getSliderQuery(position, code)],
            ({ slider }) => {
                this.setState({
                    slider,
                });
            },
            () => {
                this.setState({});
            }
        );
    }

    containerProps(): BannerSliderComponentProps {
        const { slider } = this.state;
        const { isMobile, isTablet } = this.props;

        return {
            isMobile,
            isTablet,
            settings: this.settings,
            slider,
        };
    }

    render() {
        return <BannerSlider {...this.containerProps()} />;
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(BannerSliderContainer);
