/**
 * Set of helpers related to CSS
 * @class CSS
 * @namespace PlugAndSell2/Util/CSS */
export class CSS {
    /**
     * Change CSS custom property in referenced node scope
     * @static
     * @param  {HTMLElement} ref React reference to an HTML element (node).
     * @param  {String} name CSS variable name (without `--`).
     * @param  {String} value CSS variable value.
     * @return {void}
     * @memberof CSS
     */
    static setVariable(ref: React.RefObject<HTMLElement>, name: string, value: string | number | null): void {
        if (ref && ref.current) {
            ref.current.style.setProperty(`--${name}`, value === null ? null : String(value));
        }
    }
}

/** @namespace PlugAndSell2/Util/CSS/getElementHeight */
export const getElementHeight = (id: string): number =>
    Array.from(document.getElementsByClassName(id)).reduce((acc, item) => {
        const { offsetHeight } = item as HTMLElement;

        return acc + offsetHeight;
    }, 0);

/** @namespace PlugAndSell2/Util/CSS/getFixedElementHeight */
export const getFixedElementHeight = (): Record<string, number> => {
    const top = getElementHeight('FixedElement-Top');
    const bottom = getElementHeight('FixedElement-Bottom');

    return {
        total: top + bottom,
        top,
        'inset-block-end': bottom,
    };
};

/** @namespace PlugAndSell2/Util/CSS/isRtl */
export const isRtl = (): boolean => document.documentElement.getAttribute('dir') === 'rtl';

export default CSS;
