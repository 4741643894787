import { PureComponent } from 'react';

import { ReactElement } from 'Type/Common.type';

import { ContentWrapperComponentProps } from './ContentWrapper.type';

import './ContentWrapper.style';

/**
 * Content Wrapper
 * @class ContentWrapper
 * @namespace PlugAndSell2/Component/ContentWrapper/Component */
export class ContentWrapperComponent extends PureComponent<ContentWrapperComponentProps> {
    static defaultProps: Partial<ContentWrapperComponentProps> = {
        mix: {},
        wrapperMix: {},
        children: null,
        isNotSection: false,
    };

    renderContentWrapper(): ReactElement {
        const { children, wrapperMix } = this.props;

        return (
            <div block="ContentWrapper" mix={wrapperMix}>
                {children}
            </div>
        );
    }

    render(): ReactElement {
        const { mix, label, isNotSection } = this.props;

        if (isNotSection) {
            return this.renderContentWrapper();
        }

        return (
            <section mix={mix} aria-label={label}>
                {this.renderContentWrapper()}
            </section>
        );
    }
}

export default ContentWrapperComponent;
