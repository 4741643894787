import { PureComponent } from 'react';

import Link from 'Component/Link';

import { ButtonComponentProps, ButtonSize, ButtonVariant } from './Button.type';

/** @namespace PlugAndSell2/Component/Button/Component */
export class ButtonComponent extends PureComponent<ButtonComponentProps> {
    static defaultProps = {
        variant: ButtonVariant.PRIMARY,
        size: ButtonSize.LARGE,
        asLink: false,
    };

    render() {
        const { children, variant, size, asLink, iconBefore, iconAfter, ...rest } = this.props;
        const Tag = asLink ? Link : 'button';

        return (
            <Tag block="Button" mods={{ variant, size }} {...rest}>
                {iconBefore}
                {children}
                {iconAfter}
            </Tag>
        );
    }
}

export default ButtonComponent;
