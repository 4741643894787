import { PureComponent } from 'react';

import { ReactElement } from 'Type/Common.type';

import { IconsComponentProps } from './Icons.type';

/** @namespace PlugAndSell2/Component/Icons/Component */
export class IconsComponent extends PureComponent<IconsComponentProps> {
    render(): ReactElement {
        const { icon } = this.props;

        return icon;
    }
}

export default IconsComponent;
