import { PureComponent } from 'react';
import { connect } from 'react-redux';
import { RmaMyReturnsTableContainerProps, RmaMyReturnsTableContainerState } from './RmaMyReturnsTable.type';
import RmaMyReturnsTable from './RmaMyReturnsTable.component';
import RmaQuery from '../../query/Rma.query';
import { fetchQuery } from 'Util/Request/Query';
import { RmaStatuses } from '../../query/Rma.type';
import { RootState } from 'Util/Store/Store.type';
import { Device } from 'Type/Device.type';


export const mapStateToProps = (state: RootState) => ({
  device: state.ConfigReducer.device
})

export const mapDispatchToProps = () => ({})

class RmaMyReturnsTableContainer extends PureComponent<RmaMyReturnsTableContainerProps, RmaMyReturnsTableContainerState> {
  state = {
    error: null,
    isLoading: false,
    rmaMyReturnsData: [],
    rmaStatuses: {},
    showDetails: false,
  }
  
  containerProps(): Pick<RmaMyReturnsTableContainerState, 'error' | 'isLoading' | 'rmaMyReturnsData' | 'rmaStatuses'> & { device: Device } {
    const { device } = this.props
    const { error, isLoading, rmaMyReturnsData, rmaStatuses } = this.state

    return ({
      device,
      error,
      isLoading,
      rmaMyReturnsData,
      rmaStatuses
    })
  }

  containerFunctions(): { onViewClicked: (id: number) => void } {
    return ({
      onViewClicked: this.onViewClicked.bind(this)
    })
  }

  componentDidMount(): void {
    this.fetchMyReturnsRmas();
    this.fetchRmaStatuses();
  }

  async fetchMyReturnsRmas(): Promise<void> {
    this.setLoading(true);

    try {
      const { getRmaRequestListByCustomerId } = await fetchQuery(RmaQuery.getRequestListQuery());
      this.setState({ rmaMyReturnsData: getRmaRequestListByCustomerId })
    } catch(e: unknown) {
      this.setState({ error: JSON.stringify(e) });
    } finally {
      this.setLoading(false);
    }
  }

  async fetchRmaStatuses(): Promise<void> {
    this.setLoading(true);

    try {
      const { getRmaStatuses: responseJson } = await fetchQuery(RmaQuery.getStatusListQuery());

      const rmaStausesRecord = JSON.parse(responseJson) as RmaStatuses;
      this.setState({ rmaStatuses: rmaStausesRecord })
    } catch(e: unknown) {
      this.setState({ error: JSON.stringify(e) });
    } finally {
      this.setLoading(false);
    }
  }

  onViewClicked(id: number): void {
    const { onViewClicked } = this.props;

    if (onViewClicked) {
      onViewClicked(id)
    }
  }

  setLoading(value: boolean): void {
    this.setState({ isLoading: value })
  }

  render() {
    return (
      <RmaMyReturnsTable {...this.containerProps()} {...this.containerFunctions()} />
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RmaMyReturnsTableContainer);