import { PureComponent } from 'react';

import Loader from 'Component/Loader';
import MyAccountAddressTable from 'Component/MyAccountAddressTable';
import MyAccountContractorTable from 'Component/MyAccountContractorTable';
import MyAccountCustomerForm from 'Component/MyAccountCustomerForm';
import MyAccountCustomerPopup from 'Component/MyAccountCustomerPopup';
import MyAccountCustomerTable from 'Component/MyAccountCustomerTable';
import { CustomerAddress } from 'Query/MyAccount.type';
import { ReactElement } from 'Type/Common.type';
import { getDefaultAddressLabel } from 'Util/Address';

import { MyAccountInformationComponentProps } from './MyAccountInformation.type';

import './MyAccountInformation.style';

/** @namespace PlugAndSell2/Component/MyAccountInformation/Component */
export class MyAccountInformationComponent extends PureComponent<MyAccountInformationComponentProps> {
    renderCustomerForm(): ReactElement {
        const { customer, onCustomerSave, showEmailChangeField, showPasswordChangeField, handleChangeEmailCheckbox, handleChangePasswordCheckbox } =
            this.props;

        return (
            <MyAccountCustomerForm
                customer={customer}
                onSave={onCustomerSave}
                showEmailChangeField={showEmailChangeField}
                showPasswordChangeField={showPasswordChangeField}
                handleChangeEmailCheckbox={handleChangeEmailCheckbox}
                handleChangePasswordCheckbox={handleChangePasswordCheckbox}
            />
        );
    }

    renderCustomerPopup() {
        return <MyAccountCustomerPopup />;
    }

    renderCustomerTable(): ReactElement {
        const { customer, customer: { is_b2b } = {} } = this.props;

        return (
            <div block="MyAccountInformation" elem="CustomerData">
                <MyAccountCustomerTable customer={customer} title={__('My profile')} isExtendedData={is_b2b} />
            </div>
        );
    }

    renderContractorTable(): ReactElement {
        const { contractor = {} } = this.props;

        if (!contractor) {
            return null;
        }

        return (
            <div block="MyAccountInformation" elem="ContractorData">
                <MyAccountContractorTable contractor={contractor} title={__('Company data')} />
            </div>
        );
    }

    renderAddress(address: CustomerAddress, index: number, email: string, company: string): ReactElement {
        const addressNumber = index + 1;
        const postfix = getDefaultAddressLabel(address);

        return (
            <MyAccountAddressTable
                title={__('Address #%s%s', addressNumber, postfix)}
                address={address}
                key={addressNumber}
                showAddressDetails
                isExtendedData
                email={email}
                company={company}
            />
        );
    }

    renderNoAddresses(): ReactElement {
        return (
            <div>
                <p>{__('You have no configured addresses.')}</p>
            </div>
        );
    }

    renderAddressList(): ReactElement {
        const {
            customer: { addresses = [], email = '' },
            contractor,
        } = this.props;

        if (!addresses.length) {
            return this.renderNoAddresses();
        }

        return addresses.map((address, i) => this.renderAddress(address, i, email, contractor?.company || ''));
    }

    renderB2BContent(): ReactElement {
        return (
            <div block="MyAccountInformation" elem="Content" mods={{ is_b2b: true }}>
                <div block="MyAccountInformation" elem="DataWrapper">
                    {this.renderCustomerTable()}
                    {this.renderContractorTable()}
                </div>
                <div block="MyAccountInformation" elem="AdressesWrapper">
                    <h2>{__('Address')}</h2>
                    {this.renderAddressList()}
                </div>
                {this.renderCustomerPopup()}
            </div>
        );
    }

    renderContent(): ReactElement {
        const {
            customer: { is_b2b },
        } = this.props;

        return is_b2b ? this.renderB2BContent() : this.renderCustomerForm();
    }

    render(): ReactElement {
        const { isLoading } = this.props;

        return (
            <div block="MyAccountInformation" elem="Wrapper">
                <Loader isLoading={isLoading} />
                {this.renderContent()}
            </div>
        );
    }
}

export default MyAccountInformationComponent;
