import { PureComponent } from 'react';
import { Dispatch } from 'redux';
import ContractorStructureUpdate from './ContractorStructureUpdate.component'
import { ContractorStructureUpdateContainerFunctions, ContractorStructureUpdateContainerProps, ContractorStructurePopupPayload } from './ContractorStructureUpdate.type';
import { showPopup } from 'Store/Popup/Popup.action';
import { CONTRACTOR_STRUCTURE_UPDATE_POPUP_ID } from './ContractorStructureUpdate.config';
import { connect } from 'react-redux';

export const mapStateToProps = () => ({})

export const mapDispatchToProps = (dispatch: Dispatch) => ({
  showPopup: (id: string, payload: ContractorStructurePopupPayload) => dispatch(showPopup(id, payload)),
})

class ContractorStructureUpdateContainer extends PureComponent<ContractorStructureUpdateContainerProps> {
  containerProps() {
    const { structure } = this.props;

    return ({
      structure
    })
  }
  
  containerFunctions(): ContractorStructureUpdateContainerFunctions {
    return ({
        handleUpdateStructureClick: this.handleUpdateStructureClick.bind(this),
        onContractorStructureUpdated: this.handleOnContractorStructureUpdated.bind(this)
    })
  }

  handleUpdateStructureClick(): void {
    const { showPopup, structure } = this.props;
    showPopup(`${CONTRACTOR_STRUCTURE_UPDATE_POPUP_ID}-${structure.id}`, {});
  }

  handleOnContractorStructureUpdated(): void {
    const { showPopup, onUpdated } = this.props
    showPopup('', {})

    if (onUpdated) {
      onUpdated()
    }
  }

  render() {
    return (
      <ContractorStructureUpdate {...this.containerFunctions()} {...this.containerProps()} />
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ContractorStructureUpdateContainer);