export const IMAGE_HUNDRED_PERCENT = '100%';

export const IMAGE_EAGER_LOADING_POSITION_FROM_TOP = 1000;

export enum ImageState {
    IMAGE_LOADING = 'IMAGE_LOADING',
    IMAGE_LOADED = 'IMAGE_LOADED',
    IMAGE_NOT_FOUND = 'IMAGE_NOT_FOUND',
    IMAGE_NOT_SPECIFIED = 'IMAGE_NOT_SPECIFIED',
}
