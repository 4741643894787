import { EVENT_GTM_PRODUCT_CLICK } from '../component/GoogleTagManager/GoogleTagManager.events';
import { event } from '../store/GoogleTagManager/GoogleTagManager.action';

/** ProductCard */
const handleLinkClick = (args, callback, instance) => {
    const { event, position } = instance.props;
    const { pathname } = window.location;
    const {
        linkTo: {
            state: { prevCategoryId },
        },
    } = instance.props;

    const { currentVariantIndex: configurableVariantIndex, selectedFilters = {}, product } = instance.props;

    const productToPass = Object.keys(selectedFilters).length
        ? {
              ...product,
              configurableVariantIndex,
              prevCategoryId,
              position,
              pathname,
          }
        : {
              ...product,
              prevCategoryId,
              position,
              pathname,
          };

    event(EVENT_GTM_PRODUCT_CLICK, productToPass);

    callback(...args);
};

const containerProps = (args, callback, instance) => {
    const { selectedFilters, event } = instance.props;

    return {
        ...callback(...args),
        selectedFilters,
        event,
    };
};

const mapDispatchToProps = (args, callback) => {
    const [dispatch] = args;

    return {
        ...callback(...args),
        event: (eventName = '', customData) => dispatch(event(eventName, customData)),
    };
};

export default {
    'PlugAndSell2/Component/ProductCard/Component': {
        'member-function': {
          handleLinkClick
        },
    },
    'PlugAndSell2/Component/ProductCard/Container/mapDispatchToProps': {
        function: mapDispatchToProps,
    },
    'PlugAndSell2/Component/ProductCard/Container': {
        'member-function': {
            containerProps,
        },
    },
};
