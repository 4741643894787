import { connect } from 'react-redux';

import BrandsQuery from 'Query/Brands.query';
import { GQLMageplazaBrandsOutput } from 'Query/Brands.type';
import { isEmpty } from 'Util/Common';
import DataContainer from 'Util/Request/DataContainer';
import { getCurrentStoreId } from 'Util/Store/Store';
import { RootState } from 'Util/Store/Store.type';

import FeaturedBrands from './FeaturedBrands.component';
import {
    FeaturedBrandsComponentProps,
    FeaturedBrandsContainerMapDispatchProps,
    FeaturedBrandsContainerMapStateProps,
    FeaturedBrandsContainerProps,
    FeaturedBrandsContainerState,
} from './FeaturedBrands.type';

/** @namespace PlugAndSell2/Component/FeaturedBrands/Container/mapStateToProps */
export const mapStateToProps = (state: RootState): FeaturedBrandsContainerMapStateProps => ({
    storeList: state.ConfigReducer.storeList,
});

/** @namespace PlugAndSell2/Component/FeaturedBrands/Container/mapDispatchToProps */
export const mapDispatchToProps = (): FeaturedBrandsContainerMapDispatchProps => ({});

/** @namespace PlugAndSell2/Component/FeaturedBrands/Container */
export class FeaturedBrandsContainer extends DataContainer<FeaturedBrandsContainerProps, FeaturedBrandsContainerState> {
    state = {
        sliderItems: [],
    };

    componentDidMount() {
        this.getFeaturedBrandsData();
    }

    componentDidUpdate(prevProps: FeaturedBrandsContainerProps): void {
        const { storeList } = this.props;

        if (isEmpty(prevProps.storeList) && !isEmpty(storeList)) {
            this.getFeaturedBrandsData();
        }
    }

    getFeaturedBrandsData(): void {
        const storeId = getCurrentStoreId();

        if (storeId) {
            this.fetchData(
                [BrandsQuery.getBrandsQuery({ is_featured: { eq: '1' } }, storeId, 20)],
                (mpbrand: GQLMageplazaBrandsOutput) => {
                    this.setState({ sliderItems: mpbrand ? mpbrand.items : [] });
                },
                () => {
                    this.setState({ sliderItems: [] });
                }
            );
        }
    }

    containerProps(): Pick<FeaturedBrandsComponentProps, 'sliderItems'> {
        const { sliderItems } = this.state;

        return {
            sliderItems,
        };
    }

    render() {
        return <FeaturedBrands {...this.containerProps()} />;
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FeaturedBrandsContainer);
