import { Field, Mutation, Query } from '@tilework/opus';
import { ContractorStructure, ContractorStructureCreateMutationInput, ContractorStructureDeleteMutationInput, ContractorStructureUpdateMutationInput, CreateStructureOutput, DeleteStructureOutput, GetStructureUsersQuery, GetSubstructuresOutput, PageInfo, UpdateStructureOutput } from './Contractors.type';

/** @namespace ContractorsStructures/Query/Contractors/Query */
export class ContractorsStructuresQuery {
  getContractorStructureCreateMutation(input: ContractorStructureCreateMutationInput): Mutation<'createStructure', CreateStructureOutput> {
    return new Mutation<'createStructure', CreateStructureOutput>('createStructure')
      .addArgument('type', 'String!', input.type)
      .addArgument('parentId', 'Int!', input.parentId)
      .addArgument('address', 'StructureAddressInput!', input.address)
      .addFieldList(this._getMutationResponse());
  }

  getContractorStructureUpdateMutation(input: ContractorStructureUpdateMutationInput): Mutation<'updateStructure', UpdateStructureOutput> {
    return new Mutation<'updateStructure', UpdateStructureOutput>('updateStructure')
      .addArgument('id', 'Int!', input.id)
      .addArgument('parentId', 'Int!', input.parentId)
      .addArgument('address', 'StructureAddressInput!', input.address)
      .addFieldList(this._getMutationResponse());
  }

  getContractorStructureDeleteMutation(input: ContractorStructureDeleteMutationInput): Mutation<'deleteStructure', DeleteStructureOutput> {
    return new Mutation<'deleteStructure', DeleteStructureOutput>('deleteStructure')
      .addArgument('id', 'Int!', input.id)
      .addFieldList([]);
  }

  _getMutationResponse(): Array<Field<'id', string>> {
    return [new Field<'id', string>('id')];
  }
  
  getStructureUsersQuery(id: number): Query<'getStructureUsers', GetStructureUsersQuery> {
    return new Query<'getStructureUsers', GetStructureUsersQuery>('getStructureUsers')
      .addArgument('structure_id', 'Int!', id)
  }

  getContractorSubstructuresQuery(currentPage = 1, pageSize = 10): Query<'getSubstructures', GetSubstructuresOutput> {
    // @ts-ignore
    return new Query<'getSubstructures', GetSubstructuresOutput>('getSubstructures')
        .addArgument('pageSize', 'Int', pageSize)
        .addArgument('currentPage', 'Int', currentPage)
        .addFieldList([this._getContractorSubStructureItemsFields(), this._getPageInfoField(), new Field<'total_count', string>('total_count')]);
  }

  _getContractorSubStructureItemsFields(): Field<'items', ContractorStructure, true> {
      return new Field<'items', ContractorStructure, true>('items').addFieldList(this._getContractorStructureFields());
  }

  _getContractorStructureFields(): Array<
    | Field<'city', string>
    | Field<'country', string>
    | Field<'email', string>
    | Field<'id', string>
    | Field<'name', string>
    | Field<'parent_id', string>
    | Field<'parent_name', string>
    | Field<'phone', string>
    | Field<'postcode', string>
    | Field<'province', string>
    | Field<'street', string>
    | Field<'type', string>
  > {
    return [
        new Field<'city', string>('city'),
        new Field<'country', string>('country'),
        new Field<'email', string>('email'),
        new Field<'id', string>('id'),
        new Field<'name', string>('name'),
        new Field<'parent_id', string>('parent_id'),
        new Field<'parent_name', string>('parent_name'),
        new Field<'phone', string>('phone'),
        new Field<'postcode', string>('postcode'),
        new Field<'province', string>('province'),
        new Field<'street', string>('street'),
        new Field<'type', string>('type'),
    ];
  }

  _getContractorSubstructuresItemsField(): 
    Field<'items', ContractorStructure, true>
  {
    // @ts-ignore
    return (new Field<'items', ContractorSubstructure, true>('items').addFieldList(this._getContractorSubstructuresItemsItem()))
  }

  _getContractorSubstructuresItemsItem(): Array<
    | Field<'id', number>
    | Field<'email', string>
    | Field<'firstname', string>
    | Field<'lastname', string>
    | Field<'is_active', boolean>
    | Field<'role_id', number>
    | Field<'structure_id', number>
  >  {
    return [
      new Field<'id', number>('id'),
      new Field<'email', string>('email'),
      new Field<'firstname', string>('firstname'),
      new Field<'lastname', string>('lastname'),
      new Field<'is_active', boolean>('is_active'),
      new Field<'role_id', number>('role_id'),
      new Field<'structure_id', number>('structure_id'),
    ]
  }

  _getPageInfoField(): Field<'page_info', PageInfo> {
    return new Field('page_info').addFieldList(this._getPageInfoFields())
  }

  _getPageInfoFields(): Array<
    | Field<'current_page', number>
    | Field<'page_size', number>
    | Field<'total_pages', number>
  > {
    return [
      new Field<'current_page', number>('current_page'),
      new Field<'page_size', number>('page_size'),
      new Field<'total_pages', number>('total_pages'),
    ]
  }
}

export default new ContractorsStructuresQuery()