import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { RootState } from 'Util/Store/Store.type';

import MyAccountPasswordForm from './MyAccountPasswordForm.component';
import {
    MyAccountPasswordFormComponentProps,
    MyAccountPasswordFormContainerMapDispatchProps,
    MyAccountPasswordFormContainerMapStateProps,
    MyAccountPasswordFormContainerProps,
} from './MyAccountPasswordForm.type';

/** @namespace PlugAndSell2/Component/MyAccountPasswordForm/Container/mapStateToProps */
export const mapStateToProps = (state: RootState): MyAccountPasswordFormContainerMapStateProps => ({
    passwordMinLength: state.ConfigReducer.minimun_password_length,
    passwordClassesLength: state.ConfigReducer.required_character_classes_number,
});

/** @namespace PlugAndSell2/Component/MyAccountPasswordForm/Container/mapDispatchToProps */
export const mapDispatchToProps = (): MyAccountPasswordFormContainerMapDispatchProps => ({});

/** @namespace PlugAndSell2/Component/MyAccountPasswordForm/Container */
export class MyAccountPasswordFormContainer extends PureComponent<MyAccountPasswordFormContainerProps> {
    containerProps(): Pick<MyAccountPasswordFormComponentProps, 'passwordMinLength' | 'passwordClassesLength' | 'onPasswordChange'> {
        const { passwordMinLength, passwordClassesLength, onPasswordChange } = this.props;

        return { passwordMinLength, passwordClassesLength: Number(passwordClassesLength), onPasswordChange };
    }

    render() {
        return <MyAccountPasswordForm {...this.containerProps()} />;
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MyAccountPasswordFormContainer);
