/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */
import { getPathname } from '../Url';

// eslint-disable-next-line import/prefer-default-export
export function getPostUrl(post, base) {
    const { post_url } = post;

    return getPathname(post_url, base);
}
