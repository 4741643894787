import { PureComponent } from 'react';
import { RmaDetailsComponentProps, RmaDetailsDataSource, RmaDetailsScreenType } from './RmaDetails.type';
import { ReactElement } from 'Type/Common.type';
import Loader from 'Component/Loader';
import RmaForm from '../RmaForm';

import "./RmaDetails.style"
import { formatPrice } from 'Util/Price';
import Icons from 'Component/Icons';
import { IconName } from 'Component/Icons/Icons.type';


class RmaDetails extends PureComponent<RmaDetailsComponentProps> {
  renderFormContent(dataSource: RmaDetailsDataSource): ReactElement {
    return (
      <>
        <div block="RmaDetails" elem="DetailsSection">
            <h4 block="RmaDetails" elem="DetailsSectionHeader">
              {__('Details')}:
            </h4>
            <dl block="RmaDetails" elem="Table">
              
                  <dt>{__('Order')}:</dt>
                  <dd>#{dataSource.orderId}</dd>
                  <dt>{__('Value')}:</dt>
                  <dd>{formatPrice(dataSource.total?.value || NaN, dataSource.total?.currency)}</dd>
                  <dt>{__('Purchaser')}:</dt>
                  <dd>{dataSource.purchaser}</dd>
                  <dt>{__('Email')}:</dt>
                  <dd>{dataSource.email}</dd>
            </dl>
        </div>
        <div block="RmaDetails" elem="DetailsSection">
          <h4 block="RmaDetails" elem="DetailsSectionHeader">
            {__('Shipping address')}:
          </h4>
          <dl block="RmaDetails" elem="Table">
                <dt>{__('Name')}</dt>
                <dd>{dataSource.shippingAddress?.firstname}</dd>
                <dt>{__('Last name')}</dt>
                <dd>{dataSource.shippingAddress?.lastname}</dd>
                <dt>{__('Street')}</dt>
                <dd>{dataSource.shippingAddress?.street}</dd>
                <dt>{__('Postal code')}</dt>
                <dd>{dataSource.shippingAddress?.postcode}</dd>
                <dt>{__('Phone number')}</dt>
                <dd>{dataSource.shippingAddress?.telephone}</dd>
          </dl>
        </div>
      </>
    )
  }
  
  renderOverviewContent(dataSource: RmaDetailsDataSource): ReactElement {
    return (
      <>
        <div block="RmaDetails" elem="DetailsSection">
            <h4 block="RmaDetails" elem="DetailsSectionHeader">
              {__('Details')}:
            </h4>
            <dl block="RmaDetails" elem="Table">
              <dt>{__('RMA number')}:</dt>
              <dd>{dataSource.id}</dd>
              <dt>{__('Order number')}:</dt>
              <dd>#{dataSource.orderId}</dd>
              <dt>{__('Purchaser')}:</dt>
              <dd>{dataSource.purchaser}</dd>
              <dt>{__('Date requested')}:</dt>
              <dd>{dataSource.dateRequested}</dd>
            </dl>
          </div>
          <div block="RmaDetails" elem="DetailsSection">
            <h4 block="RmaDetails" elem="DetailsSectionHeader">
              {__('Return address')}:
            </h4>
            <dl block="RmaDetails" elem="Table">
              <dt>{__('Name')}:</dt>
              <dd>{dataSource.returnAddress?.name}</dd>
              <dt>{__('Bank account number')}:</dt>
              <dd>{dataSource.returnAddress?.account_no}</dd>
              <dt>{__('Phone number')}:</dt>
              <dd>{dataSource.returnAddress?.phone_number}</dd>
            </dl>
          </div>
      </>
    )
  }

  renderCloseButton(): ReactElement {
    const { onClose } = this.props;

    return (
      <button block="RmaDetails" elem="CloseButton" onClick={onClose}>
        <Icons name={IconName.CLOSE} />
      </button>
    )
  }

  renderDetails(): ReactElement {
    const { dataSource, type } = this.props;

    if (!dataSource) {
      return;
    }

    return (
      <div block="RmaDetails" elem="Details">
        {type === RmaDetailsScreenType.OVERVIEW && this.renderOverviewContent(dataSource)}
        {type === RmaDetailsScreenType.FORM && this.renderFormContent(dataSource)}
      </div>
    )
  }

  renderForm(): ReactElement {
    const { dataSource, type } = this.props;

    return (
      <RmaForm dataSource={dataSource} readOnly={type === RmaDetailsScreenType.OVERVIEW} />
    )
  }

  renderStatusLabel(): ReactElement {
    const { dataSource } = this.props;

    if (!dataSource?.status) {
      return;
    }

    return (
      <div block="RmaStatusLabel" mods={{ type: dataSource.status }}>
        {dataSource.status}
      </div>
    )
  }

  render() {
    const { dataSource } = this.props;

    if (!dataSource) {
      return (
        <div block="RmaDetails">
          <Loader isLoading />
        </div>
      )
    }
    
    return (
      <div block="RmaDetails">
        {this.renderCloseButton()}
        {this.renderStatusLabel()}
        {this.renderDetails()}
        {this.renderForm()}
      </div>
    );
  }
}

export default RmaDetails;