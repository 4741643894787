import { Reducer } from 'redux';

import { NotificationAction, NotificationActionType, NotificationStore, NotificationType } from './Notification.type';

/** @namespace PlugAndSell2/Store/Notification/Reducer/getInitialState */
export const getInitialState = (): NotificationStore => ({
    notifications: {},
});

/** @namespace PlugAndSell2/Store/Notification/Reducer/NotificationReducer */
export const NotificationReducer: Reducer<NotificationStore, NotificationAction> = (state = getInitialState(), action) => {
    const notifications = { ...state.notifications };

    switch (action.type) {
        case NotificationActionType.SHOW_NOTIFICATION:
            const { msgType = NotificationType.INFO, msgText = '', msgDebug } = action;
            let alreadyExist = false;

            Object.entries(notifications).forEach(([_, value]) => {
                if (value.msgText.toString() === msgText.toString()) {
                    alreadyExist = true;
                }
            });

            if (!alreadyExist) {
                notifications[Date.now()] = { msgType, msgText, msgDebug };

                return {
                    ...state,
                    notifications,
                };
            }

            return state;

        case NotificationActionType.HIDE_NOTIFICATION: {
            const { id: actionId = '' } = action;
            const { [actionId]: id, ...shownNotifications } = notifications;

            return {
                ...state,
                notifications: shownNotifications,
            };
        }
        default:
            return state;
    }
};

export default NotificationReducer;
