import { Dispatch } from 'redux';
import SourceMyAccountDispatcher from 'Store/MyAccount/MyAccount.dispatcher'
import { CreateAccountB2BOptions } from './MyAccount.type';
import MyAccountQuery from '../../query/MyAccount/MyAccount.query';
import {
  updateIsLoading,
} from 'Store/MyAccount/MyAccount.action';
import { fetchMutation } from 'Util/Request/Mutation';
import { ORDER_ID } from 'Component/MyAccountOrder/MyAccountOrder.config';
import { showNotification } from 'Store/Notification/Notification.action';
import { NotificationType } from 'Store/Notification/Notification.type';
import { NetworkError } from 'Type/Common.type';

export class MyAccountDispatcherExtension {
  /**
   * Create B2B account action
   * @param {{customerInput: Object, contractorInput: Object}} [options={}]
   * @memberof MyAccountDispatcher
   */
  async createAccountB2B(options: CreateAccountB2BOptions, dispatch: Dispatch): Promise<boolean | 'confirmation_required'> {
    const mutation = MyAccountQuery.getCreateContractorMutation(options.customerInput, options.contractorInput);

    dispatch(updateIsLoading(true));

    try {
        await fetchMutation(mutation);
        sessionStorage.setItem(ORDER_ID, '');

        return await SourceMyAccountDispatcher.signIn({ email: options.customerInput.email, password: options.customerInput.password || '---' }, dispatch);
    } catch (errors) {
        dispatch(updateIsLoading(false));
        dispatch(showNotification(NotificationType.ERROR, (errors as NetworkError[])[0].message));

        return false;
    }
  }
}

export default new MyAccountDispatcherExtension()