import { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { MyAccountCustomerPopupAction } from 'Component/MyAccountCustomerPopup/MyAccountCustomerPopup.config';
import { AccountPageUrl } from 'Route/MyAccount/MyAccount.config';
import { showPopup } from 'Store/Popup/Popup.action';
import { ReactElement } from 'Type/Common.type';
import history from 'Util/History';
import { RootState } from 'Util/Store/Store.type';
import { appendWithStoreCode } from 'Util/Url';

import MyAccountCustomerTable from './MyAccountCustomerTable.component';
import {
    MyAccountCustomerTableComponentProps,
    MyAccountCustomerTableContainerFunctions,
    MyAccountCustomerTableContainerMapDispatchProps,
    MyAccountCustomerTableContainerMapStateProps,
    MyAccountCustomerTableContainerProps,
} from './MyAccountCustomerTable.type';

/** @namespace PlugAndSell2/Component/MyAccountCustomerTable/Container/mapStateToProps */

export const mapStateToProps = (state: RootState): MyAccountCustomerTableContainerMapStateProps => ({
    isMobile: state.ConfigReducer.device.isMobile,
});

/** @namespace PlugAndSell2/Component/MyAccountCustomerTable/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch: Dispatch): MyAccountCustomerTableContainerMapDispatchProps => ({
    showPopup: (payload) => dispatch(showPopup(MyAccountCustomerPopupAction.CUSTOMER_POPUP_ID, payload)),
});
/** @namespace PlugAndSell2/Component/MyAccountCustomerTable/Container */
export class MyAccountCustomerTableContainer extends PureComponent<MyAccountCustomerTableContainerProps> {
    static defaultProps: Partial<MyAccountCustomerTableContainerProps> = {
        title: '',
    };

    containerFunctions: MyAccountCustomerTableContainerFunctions = {
        handleOnEditPassword: this.handleOnEditPassword.bind(this),
        handleOnEditInformation: this.handleOnEditInformation.bind(this),
        showEditPopup: this.showEditPopup.bind(this),
        showChangePasswordPopup: this.showChangePasswordPopup.bind(this),
    };

    containerProps(): Pick<MyAccountCustomerTableComponentProps, 'customer' | 'title' | 'isExtendedData' | 'isMobile'> {
        const { customer, title, isExtendedData, isMobile } = this.props;

        return {
            customer,
            title,
            isMobile,
            isExtendedData,
        };
    }

    showEditPopup(): void {
        const { showPopup, customer } = this.props;

        showPopup({
            action: MyAccountCustomerPopupAction.EDIT_CUSTOMER,
            customer,
            title: __('Edit customer details'),
        });
    }

    showChangePasswordPopup(): void {
        const { showPopup, customer } = this.props;

        showPopup({
            action: MyAccountCustomerPopupAction.CHANGE_PASSWORD,
            customer,
            title: __('Change password'),
        });
    }

    handleOnEditPassword(): void {
        history.push({
            pathname: appendWithStoreCode(AccountPageUrl.INFORMATION_EDIT_URL),
            state: { editPassword: true },
        });
    }

    handleOnEditInformation(): void {
        history.push({ pathname: appendWithStoreCode(AccountPageUrl.INFORMATION_EDIT_URL) });
    }

    render(): ReactElement {
        return <MyAccountCustomerTable {...this.containerProps()} {...this.containerFunctions} />;
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MyAccountCustomerTableContainer);
