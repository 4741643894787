import { PureComponent } from 'react';
import RmaAvailable from './RmaAvailable.component';
import { getQueryParam, setQueryParams } from 'Util/Url';
import history from 'Util/History';
import { RmaAvailableContainerFunctions, RmaAvailableContainerProps, RmaAvaliableContainerState } from './RmaAvailable.type';
import { scrollToTop } from 'Util/Browser';
import { OrderItem } from 'Query/Order.type';

class RmaAvailableContainer extends PureComponent<RmaAvailableContainerProps, RmaAvaliableContainerState> {
  state = {
    orderId: null,
    showDetails: false
  }
  
  containerProps(): Pick<RmaAvaliableContainerState, 'showDetails'> {
    const { showDetails } = this.state

    return ({
      showDetails 
    })
  }

  containerFunctions(): RmaAvailableContainerFunctions {
    return ({
      onCreateReturn: this.onCreateReturn.bind(this)
    })
  }

  componentDidMount(): void {
    const urlOrderId = getQueryParam('orderId', history.location)

    if (urlOrderId) {
      this.setState({ orderId: urlOrderId })
    }

    scrollToTop({ behavior: 'smooth' });

    history.listen(() => {
      const { orderId } = this.state
      const { changeTabName } = this.props;

      const newUrlOrderId = getQueryParam('orderId', history.location)

      if (!newUrlOrderId && orderId !== newUrlOrderId) {
        this.setState({ showDetails: false, orderId: null })
        scrollToTop({ behavior: 'smooth' });
        changeTabName(__('Return or complaint'))
      }
    })
  }

  componentDidUpdate(_: any, prevState: RmaAvaliableContainerState): void {
    const { orderId } = this.state; 
    const { changeTabName } = this.props;
    
    if (prevState.orderId !== orderId) {
      if (orderId) {
        this.setState({ showDetails: true })
        changeTabName(`${__('Return for order')} #${getQueryParam('orderName', history.location)}`);
      }
    }
  }

  onCreateReturn(order: Partial<OrderItem>): void {
    this.setState({ orderId: order?.id || '' })

    setQueryParams({ 'orderId': encodeURIComponent(order?.id || ''), orderName: encodeURIComponent(order?.increment_id || '') }, history.location, history)
  }

  render() {
    return (
      <RmaAvailable {...this.containerProps()} {...this.containerFunctions()} />
    );
  }
}

export default RmaAvailableContainer;