import { PureComponent } from 'react';

import AddIcon from 'Component/AddIcon';
import { FieldType } from 'Component/Field/Field.config';
import { FieldReactEvents } from 'Component/Field/Field.type';
import MinusIcon from 'Component/MinusIcon';
import { ReactElement } from 'Type/Common.type';
import { DEFAULT_MAX_PRODUCTS } from 'Util/Product/Product.type';

import { FieldNumberWitControlsComponentProps } from './FieldNumberWithControls.type';

import './FieldNumberWithControls.style.scss';
/**
 * Field Number With Controls
 * @class FieldNumberWithControls
 * @namespace PlugAndSell2/Component/FieldNumberWithControls/Component */
export class FieldNumberWithControlsComponent extends PureComponent<FieldNumberWitControlsComponentProps> {
    render(): ReactElement {
        const {
            attr,
            attr: { min = 1, max = DEFAULT_MAX_PRODUCTS, step = 1 },
            value,
            events,
            setRef,
            stateValue,
            handleValueChange,
            isDisabled,
        } = this.props;

        const numberValue = Number(+value || +stateValue);

        return (
            <>
                <input
                    ref={(elem) => setRef(elem)}
                    {...attr}
                    {...(events as FieldReactEvents<HTMLInputElement>)}
                    value={value}
                    type={FieldType.NUMBER}
                    aria-label={__('Value')}
                    disabled={isDisabled}
                    onChange={(e) => {
                        const { value } = e.target;

                        if (value) {
                            handleValueChange(parseInt(value, 10));
                        }
                    }}
                />
                <button
                    disabled={max === 1 || numberValue >= Number(max) || isDisabled}
                    onClick={() => handleValueChange(numberValue + Number(step))}
                    aria-label={__('Add')}
                    type={FieldType.BUTTON}
                >
                    <AddIcon block="SubtractButton" />
                </button>
                <button
                    disabled={numberValue + 1 === min || numberValue <= Number(min) || isDisabled}
                    onClick={() => handleValueChange(numberValue - Number(step))}
                    aria-label={__('Subtract')}
                    type={FieldType.BUTTON}
                >
                    <MinusIcon block="AddButton" />
                </button>
            </>
        );
    }
}

export default FieldNumberWithControlsComponent;
