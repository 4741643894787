import getStore from "Util/Store";

export const AccountManagerDispatcher = import(
  /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
  '../store/AccountManager/AccountManager.dispatcher'
);

export const MyAccountDispatcher_requestCustomerData = (args: any, callback: Function) => {
  const { dispatch } = getStore();
  AccountManagerDispatcher.then(({ default: dispatcher }) => dispatcher.handleData(dispatch, {}));

  return callback(...args);
}

export default {
  'PlugAndSell2/Store/MyAccount/Dispatcher': {
      'member-function': {
        requestCustomerData: MyAccountDispatcher_requestCustomerData,
      }
  },
};
