import { PureComponent } from 'react';
import { Dispatch } from 'redux';
import ContractorStructureCreate from './ContractorStructureCreate.component'
import { ContractorStructureCreateContainerFunctions, ContractorStructureCreateContainerProps, ContractorStructurePopupPayload } from './ContractorStructureCreate.type';
import { showPopup } from 'Store/Popup/Popup.action';
import { CONTRACTOR_STRUCTURE_CREATE_POPUP_ID } from './ContractorStructureCreate.config';
import { connect } from 'react-redux';

export const mapStateToProps = () => ({})

export const mapDispatchToProps = (dispatch: Dispatch) => ({
  showPopup: (id: string, payload: ContractorStructurePopupPayload) => dispatch(showPopup(id, payload)),
})

class ContractorStructureCreateContainer extends PureComponent<ContractorStructureCreateContainerProps> {
  containerFunctions(): ContractorStructureCreateContainerFunctions {
    return ({
      handleCreateStrctureClick: this.handleCreateStrctureClick.bind(this),
      onContractorStructureCreated: this.handleOnContractorStructureCreated.bind(this)
    })
  }

  handleCreateStrctureClick(): void {
    const { showPopup } = this.props;
    showPopup(CONTRACTOR_STRUCTURE_CREATE_POPUP_ID, {});
  }

  handleOnContractorStructureCreated(): void {
    const { showPopup, onCreated } = this.props
    showPopup('', {})

    if (onCreated) {
      onCreated()
    }
  }

  render() {
    return (
      <ContractorStructureCreate {...this.containerFunctions()} />
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ContractorStructureCreateContainer);