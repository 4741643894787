import { PureComponent } from 'react';
import Link from 'Component/Link';

import './CreateB2BLink.style';

class CreateB2BLink extends PureComponent<{}, {}> {
  render() {
    return (
      <section block="CreateB2BLink">
          <span block="CreateB2BLink" elem="Label">
            {__('Want to shop as a partner?')}
          </span>
          <Link to="/customer/createB2B" block="CreateB2BLink" elem="Link">
            {__('Create a B2B account')}
          </Link>
      </section>
    );
  }
}

export default CreateB2BLink;