import { PureComponent } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import { LAZY_BRANDS_SLIDER, LAZY_PRODUCT_TILE, LAZY_PRODUCT_TILE_RELATED } from 'Component/ImageLazyLoading/ImageLazyLoading.config';
import { ReactElement } from 'Type/Common.type';

import placeholderTile from '../../assets/images/productPlaceholder.png';
import placeholderSmallRelated from '../../assets/images/productPlaceholderSmallRelated.png';
import { ImageLazyLoadingComponentProps } from './ImageLazyLoading.type';

import 'react-lazy-load-image-component/src/effects/opacity.css';
import './ImageLazyLoading.style';

/** @namespace PlugAndSell2/Component/ImageLazyLoading/Component */
export class ImageLazyLoadingComponent extends PureComponent<ImageLazyLoadingComponentProps> {
    renderMap = {
        [LAZY_PRODUCT_TILE]: this.renderProductTileLazy.bind(this),
        [LAZY_PRODUCT_TILE_RELATED]: this.renderProductTileRelatedLazy.bind(this),
        [LAZY_BRANDS_SLIDER]: this.renderBrandsSliderLazy.bind(this),
        default: () => null,
    };

    renderProductTileLazy(): ReactElement {
        const { src, alt } = this.props;

        return <LazyLoadImage effect="opacity" src={src} alt={alt} placeholderSrc={placeholderTile} />;
    }

    renderProductTileRelatedLazy(): ReactElement {
        const { src, alt } = this.props;

        return <LazyLoadImage effect="opacity" src={src} alt={alt} placeholderSrc={placeholderSmallRelated} />;
    }

    renderBrandsSliderLazy(): ReactElement {
        const { src, alt } = this.props;

        return <LazyLoadImage effect="opacity" src={src} alt={alt} placeholderSrc={placeholderSmallRelated} />;
    }

    render(): ReactElement {
        const { lazyLoadingType } = this.props;
        const render = this.renderMap[lazyLoadingType];

        return render ? render() : null;
    }
}

export default ImageLazyLoadingComponent;
