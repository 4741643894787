import { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import ContractorStructureCreateForm from './ContractorStructureCreateForm.component';
import ContractorsDispatcher from '../../store/Contractors/Contractors.dispatcher';
import { ContractorStructureCreateMutationInput } from '../../query/Contractors/Contractors.type';
import { showNotification } from 'Store/Notification/Notification.action';
import { NotificationType } from 'Store/Notification/Notification.type';
import { NetworkError } from 'Type/Common.type';
import { getErrorMessage } from 'Util/Request/Error';
import { fetchQuery } from 'Util/Request/Query';
import ContractorsQuery from '../../query/Contractors/Contractors.query';
import { ContractorStructureCreateFormContainerProps, ContractorStructureCreateFormContainerState } from './ContractorStructureCreateForm.type';
import transformCountriesToOptions from 'Util/Address';
import { RootState } from 'Util/Store/Store.type';
import { getField } from '../../util/Form';
import { FormFields } from 'Component/Form/Form.type';

export const mapStateToProps = (state: RootState) => ({
  countries: transformCountriesToOptions(state.ConfigReducer.countries || []),
})

export const mapDispatchToProps = (dispatch: Dispatch) => ({
  createContractorStructure: (input: ContractorStructureCreateMutationInput) => ContractorsDispatcher.createContractorStructure(input),
  showNotification: (type: NotificationType, msg: string) => dispatch(showNotification(type, msg))
})

class ContractorStructureCreateFormContainer extends PureComponent<ContractorStructureCreateFormContainerProps, ContractorStructureCreateFormContainerState> {
  state = {
    isLoading: false,
    roles: null,
    substructures: null
  }

  containerProps() {
    const { countries } = this.props; 
    const { isLoading, substructures } = this.state;

    return ({
      countries,
      isLoading,
      substructures
    })
  }
  

  containerFunctions() {
    return ({
      onFormError: this.handleFormError.bind(this),
      onFormSubmit: this.handleFormSubmit.bind(this)
    })
  }

  componentDidMount(): void {
    this.getSubstructures()
  }

  async getSubstructures() {
    this.setState({ isLoading: true });

    try {
      const data = await fetchQuery(ContractorsQuery.getContractorSubstructuresQuery(1, 99));
      this.setState({ substructures: data.getSubstructures.items })
    } catch(e) {
      console.log('error fetching substructures', getErrorMessage(e as unknown as NetworkError));
    } finally {
      this.setState({ isLoading: false })
    }
    
  }

  async handleFormSubmit(_form: HTMLFormElement, fields: FormFields) {
    const { createContractorStructure, onCreateSuccess , showNotification } = this.props;

    this.setState({ isLoading: true });

    debugger;
    try {
      await createContractorStructure({
        type: String(getField('type', fields)?.value) as 'department' || 'department',
        parentId: Number(getField('parentId', fields)?.value) || NaN,
        address: {
          name: String(getField('name', fields)?.value) || '',
          street: String(getField('street', fields)?.value) || '',
          city: String(getField('city', fields)?.value) || '',
          postcode: String(getField('postcode', fields)?.value) || '',
          province: String(getField('province', fields)?.value) || '',
          phone: String(getField('phone', fields)?.value) || '',
          country: String(getField('country_id', fields)?.value) || '',
          email: String(getField('email', fields)?.value) || '',
        },
      })
      onCreateSuccess && onCreateSuccess();
    } catch(e) {
      showNotification(NotificationType.ERROR, getErrorMessage(e as unknown as NetworkError | NetworkError[]))
    } finally {
      this.setState({ isLoading: false });
    }
  }

  handleFormError() {}

  render() {
    return (
      <ContractorStructureCreateForm {...this.containerProps()} {...this.containerFunctions()}/>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ContractorStructureCreateFormContainer);

