import { PureComponent } from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { RmaFormContainerProps, RmaFormContainerState, RmaFormDataSource, RmaFormFormData, RmaFormFormDataAttachment, RmaFormMode } from './RmaForm.type';
import { NetworkError, ReactElement } from 'Type/Common.type';
import RmaForm from './RmaForm.component';
import { RmaDetailsDataSourceItem, RmaDetailsScreenType } from '../RmaDetails/RmaDetails.type';
import { fetchMutation } from 'Util/Request/Mutation';
import RmaQuery from '../../query/Rma.query';
import { NotificationType } from 'Store/Notification/Notification.type';
import { showNotification } from 'Store/Notification/Notification.action';
import { RootState } from 'Util/Store/Store.type';
import { appendWithStoreCode, getQueryParam } from 'Util/Url';
import { history } from 'Util/History';
import { RMA_AVAILABLE_RETURNS_ROUTE } from '../../plugin/MyAccount.plugin';
import { Device } from 'Type/Device.type';

export const mapStateToProps = (state: RootState) => ({
  customer: state.MyAccountReducer.customer,
  device: state.ConfigReducer.device
});

export const mapDispatchToProps = (dispatch: Dispatch) => ({
  showNotification: (type: NotificationType, msg: string) => dispatch(showNotification(type, msg))
})

class RmaFormContainer extends PureComponent<RmaFormContainerProps, RmaFormContainerState> {
  state = {
    formData: {
      products: {},
      payment: {
        bank_name: '',
        name_surname: '',
        account_no: '',
      }
    },
    isLoading: false,
    mode: RmaFormMode.RETURN
  }

  containerProps(): { 
    dataSource: RmaFormDataSource | null,
    device: Device,
    formData: RmaFormFormData,
    isLoading: boolean, 
    isSubmitEnabled: boolean, 
    mode: RmaFormMode, 
    readOnly: boolean 
  } {
    const { dataSource, device, readOnly } = this.props;
    const { formData, isLoading, mode } = this.state;

    return ({
      dataSource,
      device,
      formData,
      isLoading,
      isSubmitEnabled: this.isSubmitEnabled(),
      mode,
      readOnly: !!readOnly
    })
  }

  containerFunctions(): {
    handleEditProductData: (productId: string, type: 'selected' | 'requestQty' | 'note' | 'attachments' | null, value: number | string | boolean | RmaFormFormDataAttachment[] | null) => void,
    handleEditPaymentData: (fieldName: 'bank_name' | 'name_surname' | 'account_no', value: string) => void
    handleSwitchMode: (mode: RmaFormMode) => void
    isSelected: (item: RmaDetailsDataSourceItem) => boolean 
    onBack: () => void
    onSubmit: () => void
  } {
    return ({
      handleEditProductData: this.handleEditProductData.bind(this),
      handleEditPaymentData: this.handleEditPaymentData.bind(this),
      handleSwitchMode: this.handleSwitchMode.bind(this),
      isSelected: this.isSelected.bind(this),
      onBack: this.onBack.bind(this),
      onSubmit: this.onSubmit.bind(this),
    })
  }

  onBack() {
    this.goToList()
  }

  isSelected(item: RmaDetailsDataSourceItem): boolean {
    const { formData: { products } } = this.state;

    return (!!products[item.itemId as keyof typeof products]) 
  }

  isSubmitEnabled(): boolean {
    const { formData: { products } } = this.state;

    let errors = []

    if (!Object.keys(products).length) {
      errors.push('No products selected')
    }

    return errors.length === 0 
  }

  goToList() {
    history.push(appendWithStoreCode(RMA_AVAILABLE_RETURNS_ROUTE));
    window.scroll({ top: 0, behavior: 'smooth' })
  }

  handleEditProductData(orderItemId: string, type: 'selected' | 'requestQty' | 'note' | 'attachments' | null, value: number | string | boolean | RmaFormFormDataAttachment[] | null): void {
    if (!orderItemId || !type) {
      return;
    }
    
    if (type === 'selected' && value === false) {
      // remove
      const { formData: { products: oldProducts }} = this.state;
      delete oldProducts[orderItemId as keyof typeof oldProducts];
      
      this.setState(({ formData: oldFormData }) => ({
        formData: { ...oldFormData, products: oldProducts }
      }))

      return;
    }

    this.setState(({ formData: oldFormData }) => ({ 
      formData: { ...oldFormData, products: {...oldFormData.products, [orderItemId]: { ...oldFormData.products[orderItemId], [type]: value }}}
    }))
  }

  handleEditPaymentData(fieldName: 'bank_name' | 'name_surname' | 'account_no', value: string): void {
    this.setState(({ formData: oldFormData }) => ({ 
      formData: { ...oldFormData, payment: { ...oldFormData.payment, [fieldName]: value }}
    }))
  }

  handleSwitchMode(mode: RmaFormMode): void {
    this.setState({ mode })
  }

  onSubmit() {
    this.sendRequest()
  }

  async sendRequest(): Promise<void> {
    const { mode, formData: { products: formProducts, payment }} = this.state;
    const { customer, dataSource, showNotification } = this.props;

    if (!dataSource?.items || !customer) { 
      return;
    }

    this.setLoading(true)

    try {
      const paramId = getQueryParam('orderId', history.location, { noDecode: true }) || '';
      await fetchMutation(RmaQuery.getPlaceRequestMutation({
        order_id: Number(window.atob(decodeURIComponent(paramId))),
        customer_id: Number(customer.id),
        decision: mode,
        note: '',
        name_surname: `${dataSource.shippingAddress?.firstname} ${dataSource.shippingAddress?.lastname}`,
        account_no: payment.account_no,
        phone: String(dataSource.shippingAddress?.telephone),
        request_items: Object.entries(formProducts).map(([key, value]: [string, unknown]) => {
          const product = dataSource.items?.find(item => item.itemId === key);
          const assertedValue = value as { requestQty?: number, note?: string, attachments?: { name: string, base64_encoded_file: string }[] }

          return ({
            sku: String(product?.itemSku),
            request_qty: Number(assertedValue.requestQty),
            reason: String(assertedValue.note),
            images: assertedValue.attachments || []
          })
        })
      }))

      showNotification(NotificationType.SUCCESS, __('Your application has been submitted'));  
      this.goToList()
    } catch (e) {
      showNotification(NotificationType.ERROR, (e as NetworkError[])[0].message);  
    } finally {
      this.setLoading(false)
    }
  }

  setLoading(value: boolean): void {
    this.setState({ isLoading: value })
  }

  render(): ReactElement {
    return (
      <RmaForm {...this.containerProps()} {...this.containerFunctions()} />
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RmaFormContainer);