import { PureComponent } from 'react';

import { ButtonVariant } from 'Component/Button/Button.type';
import Field from 'Component/Field';
import { FieldType } from 'Component/Field/Field.config';
import Form from 'Component/Form';
import Link from 'Component/Link';
import { AccountPageUrl } from 'Route/MyAccount/MyAccount.config';
import { ReactElement } from 'Type/Common.type';
import { ValidationInputType } from 'Util/Validator/Config';

import { MyAccountForgotPasswordComponentProps } from './MyAccountForgotPassword.type';

/** @namespace PlugAndSell2/Component/MyAccountForgotPassword/Component */
export class MyAccountForgotPasswordComponent extends PureComponent<MyAccountForgotPasswordComponentProps> {
    renderForgotPasswordForm(): ReactElement {
        const { displayedAsPage, onForgotPasswordSuccess, onFormError } = this.props;

        return (
            <Form key="forgot-password" onSubmit={onForgotPasswordSuccess} onError={onFormError}>
                <Field
                    type={FieldType.EMAIL}
                    label={__('E-mail')}
                    attr={{
                        id: 'email',
                        name: 'email',
                        placeholder: __('E-mail'),
                        autoComplete: 'email',
                    }}
                    validateOn={['onChange']}
                    validationRule={{
                        isRequired: true,
                        inputType: ValidationInputType.EMAIL,
                    }}
                    addRequiredTag
                />
                <div block="MyAccountOverlay" elem="Buttons">
                    <button
                        block="Button"
                        type="submit"
                        mods={{ variant: ButtonVariant.SECONDARY }}
                        mix={{ block: 'MyAccountOverlay', elem: 'ResetPassword' }}
                    >
                        {__('Send link')}
                    </button>
                    {displayedAsPage && (
                        <span block="MyAccountOverlay" elem="SignUpIncent">
                            {__("Don't have an account?")} <Link to={AccountPageUrl.REGISTRATION_URL}>{__('Sign up')}</Link>
                        </span>
                    )}
                </div>
            </Form>
        );
    }

    renderCreateAccountLabel(): ReactElement {
        const { isCheckout, handleCreateAccount } = this.props;

        if (isCheckout) {
            return null;
        }

        return (
            <section aria-labelledby="create-account-label">
                <span block="MyAccountOverlay" elem="AdditionalLabel">
                    {__("Don't have an account?")}
                </span>
                <button block="Button" mods={{ likeLink: true }} onClick={handleCreateAccount}>
                    {__('Create an account')}
                </button>
            </section>
        );
    }

    renderAdditionalField(): ReactElement {
        const { handleSignIn } = this.props;

        return (
            <article block="MyAccountOverlay" elem="Additional">
                <section aria-labelledby="forgot-password-label">
                    <span block="MyAccountOverlay" elem="AdditionalLabel">
                        {__('Already have an account?')}
                    </span>
                    <button block="Button" mods={{ likeLink: true }} onClick={handleSignIn}>
                        {__('Sign In')}
                    </button>
                </section>
                {this.renderCreateAccountLabel()}
            </article>
        );
    }

    render(): ReactElement {
        return (
            <>
                {this.renderForgotPasswordForm()}
                {this.renderAdditionalField()}
            </>
        );
    }
}

export default MyAccountForgotPasswordComponent;
