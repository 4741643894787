import { FieldData, FieldValue } from './Form.type';

/**
 * Returns name: value pair object for form output.
 * @param fields (Array|Object)
 * @returns {{}}
 * @namespace PlugAndSell2/Util/Form/Transform/transformToNameValuePair */
export const transformToNameValuePair = <T>(fields: Record<string, FieldData> | FieldData[]): T => {
    const filteredFields: Record<string, FieldValue> = {};
    const arrayFormat = !Array.isArray(fields) ? Object.values(fields) : fields;

    arrayFormat.forEach(({ value, name }) => {
        filteredFields[name] = value;
    });

    return filteredFields as unknown as T;
};

export default transformToNameValuePair;
