import { PureComponent } from 'react';
import { connect } from 'react-redux';
import RmaMyReturns from './RmaMyReturns.component';
import { getQueryParam, setQueryParams } from 'Util/Url';
import history from 'Util/History';
import { RmaMyReturnsContainerProps, RmaMyReturnsContainerState } from './RmaMyReturns.type';
import { RootState } from 'Util/Store/Store.type';
import { Device } from 'Type/Device.type';

export const mapStateToProps = (state: RootState) => ({
  device: state.ConfigReducer.device
})

export const mapDispatchToProps = () => ({})

class RmaMyReturnsContainer extends PureComponent<RmaMyReturnsContainerProps, RmaMyReturnsContainerState> {
  state = {
    rmaId: null,
    showDetails: false
  }

  containerProps(): { device: Device, showDetails: boolean } {
    const { device } = this.props;
    const { showDetails } = this.state;

    return ({
      device,
      showDetails
    })
  }

  containerFunctions(): { onViewClicked: (id: number) => void } {
    return ({
      onViewClicked: this.onViewClicked.bind(this)
    })
  }


  componentDidMount(): void {
    const urlRmaId = getQueryParam('rmaId', history.location)

    if (urlRmaId) {
      this.setState({ rmaId: urlRmaId })
    }

    history.listen(() => {
      const { rmaId } = this.state
      const { changeTabName } = this.props;

      const newUrlRmaId = getQueryParam('rmaId', history.location)

      if (!newUrlRmaId && rmaId !== newUrlRmaId) {
        this.setState({ showDetails: false, rmaId: null })
        changeTabName(__('My Returns and complaints'));
      }
    })
  }

  componentDidUpdate(_: any, prevState: RmaMyReturnsContainerState): void {
    const { rmaId } = this.state; 
    const { changeTabName } = this.props;
    
    if (prevState.rmaId !== rmaId) {
      if (rmaId) {
        this.setState({ showDetails: true })
        changeTabName(`${__('Return for order')} #${rmaId}`);
      }
    }
  }

  onViewClicked(rmaId: number) {
    this.setState({ rmaId: String(rmaId) })
    setQueryParams({ 'rmaId': String(rmaId) }, history.location, history)
  }

  render() {
    return (
      <RmaMyReturns {...this.containerProps()} {...this.containerFunctions()} />
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RmaMyReturnsContainer);