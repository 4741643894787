import { PureComponent } from 'react';

import Accordion from './Accordion.component';
import { AccordionComponentProps, AccordionContainerComponentComponentPropKeys, AccordionContainerProps } from './Accordion.type';

/** @namespace PlugAndSell2/Component/Accordion/Container */
export class AccordionContainer extends PureComponent<AccordionContainerProps> {
    componentDidMount(): void {
        const accordionBoxes = document.querySelectorAll('.Accordion-Box');

        accordionBoxes.forEach((accordionBox) => {
            accordionBox.addEventListener('click', (e) => this.handleClickBox(e));
        });
    }

    componentWillUnmount(): void {
        const accordionBoxes = document.querySelectorAll('.Accordion-Box');

        accordionBoxes.forEach((accordionBox) => {
            accordionBox.removeEventListener('click', (e) => this.handleClickBox(e));
        });
    }

    setActiveAccordionItem(clickedItem: Element | null) {
        const boxes = document.querySelectorAll('.Accordion-Box');
        boxes.forEach((box) => {
            if (clickedItem !== box) {
                box.classList.remove('Accordion-Box_isOpen');
            }
        });
        clickedItem?.classList.toggle('Accordion-Box_isOpen');
    }

    handleClickBox(e: Event) {
        const box = (e.target as HTMLElement).closest('.Accordion-Box');
        this.setActiveAccordionItem(box);
    }

    containerProps(): Pick<AccordionComponentProps, AccordionContainerComponentComponentPropKeys> {
        const { children, 'data-device': dataDevice } = this.props;

        return {
            children,
            dataDevice,
        };
    }

    render() {
        return <Accordion {...this.containerProps()} />;
    }
}

export default AccordionContainer;
