import { PureComponent } from 'react';

import Tabs from './Tabs.component';
import { TabHtml, TabsComponentProps, TabsContainerFunctions, TabsContainerProps, TabsContainerState } from './Tabs.type';

/** @namespace PlugAndSell2/Component/Tabs/Container */
export class TabsContainer extends PureComponent<TabsContainerProps, TabsContainerState> {
    containerFunctions: TabsContainerFunctions = {
        handleTabClick: this.handleTabClick.bind(this),
    };

    state: TabsContainerState = {
        activeTab: 1,
    };

    componentDidMount(): void {
        this.changeActiveTab();
    }

    componentDidUpdate(prevProps: TabsContainerProps, prevState: TabsContainerState): void {
        const { activeTab } = this.state;

        if (prevState.activeTab !== activeTab) {
            this.changeActiveTab();
        }
    }

    changeActiveTab(): void {
        const tabs = document.querySelectorAll('.Tabs-Content');
        const { activeTab } = this.state;

        tabs.forEach((contentTab, index) => {
            if (index + 1 === activeTab && !contentTab.classList.contains('Tabs-Content_isActive')) {
                contentTab.classList.add('Tabs-Content_isActive');
            } else {
                contentTab.classList.remove('Tabs-Content_isActive');
            }
        });
    }

    handleTabClick(id: number): void {
        this.setState({ activeTab: id });
    }

    getArrayTabs(): TabHtml[] {
        const { 'data-tabs': dataTabs } = this.props;

        const tabNames = JSON.parse(dataTabs.replace(/'/g, '"'));
        const tabs: TabHtml[] = [];

        tabNames.forEach((tabName: string, index: number) => {
            tabs.push({ name: tabName, id: index + 1 });
        });

        return tabs;
    }

    containerProps(): Pick<TabsComponentProps, 'tabs' | 'children' | 'activeTab'> {
        const { children } = this.props;
        const { activeTab } = this.state;

        return {
            tabs: this.getArrayTabs(),
            children,
            activeTab,
        };
    }

    render() {
        return <Tabs {...this.containerFunctions} {...this.containerProps()} />;
    }
}

export default TabsContainer;
