import { ElementType } from "react";
import RmaAvailableContainer from "../components/RmaAvailable";
import RmaMyReturns from "../components/RmaMyReturns";

export const RMA_AVAILABLE_RETURNS_TAB_ID = 'rma-available-returns-tab'
export const RMA_AVAILABLE_RETURNS_ROUTE = '/rma-available-returns'

export const RMA_MY_RETURNS_TAB_ID = 'rma-my-returns-tab'
export const RMA_MY_RETURNS_ROUTE = '/rma-my-returns'

export const MyAccountTabList_containerProps = (originalMember: Object) => {
  return ({
    ...originalMember,
    [RMA_AVAILABLE_RETURNS_TAB_ID]: {
      url: RMA_AVAILABLE_RETURNS_ROUTE,
      tabName: __('Return or complaint'),
      isFullUrl: true,
    },
    [RMA_MY_RETURNS_TAB_ID]: {
      url: RMA_MY_RETURNS_ROUTE,
      tabName: __('My Returns and complaints'),
      isFullUrl: true,
    },
  })
}

export const MyAccountComponent_renderMap = (originalMember: Record<string, ElementType>) => {
  return ({
    ...originalMember,
    [RMA_AVAILABLE_RETURNS_TAB_ID]: RmaAvailableContainer,
    [RMA_MY_RETURNS_TAB_ID]: RmaMyReturns,
  })
}

export default {
  'PlugAndSell2/Route/MyAccount/Component': {
    'member-property': {
      renderMap: MyAccountComponent_renderMap
    }
  },
  'PlugAndSell2/Route/MyAccount/Container': {
    'static-member': {
      tabMap: MyAccountTabList_containerProps
    }
  }
};
