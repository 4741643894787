import { PureComponent } from 'react';

import { ReactElement } from 'Type/Common.type';

import { MyAccountConfirmEmailComponentProps } from './MyAccountConfirmEmail.type';

/** @namespace PlugAndSell2/Component/MyAccountConfirmEmail/Component */
export class MyAccountConfirmEmailComponent extends PureComponent<MyAccountConfirmEmailComponentProps> {
    render(): ReactElement {
        const { state, handleSignIn } = this.props;

        return (
            <article aria-labelledby="confirm-email-notice" block="MyAccountOverlay" elem="Additional" mods={{ state }}>
                <p id="confirm-email-notice">
                    {/* eslint-disable-next-line max-len */}
                    {__('The email confirmation link has been sent to your email. Please confirm your account to proceed.')}
                </p>
                <button block="Button" onClick={handleSignIn}>
                    {__('Got it')}
                </button>
            </article>
        );
    }
}

export default MyAccountConfirmEmailComponent;
