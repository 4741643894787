import { PureComponent } from 'react';
import SlickSlider from 'react-slick';

import { DEFAULT_CATALOG_WIDGET_SLICK_SETTINGS } from 'Component/CatalogWidget/CatalogWidget.config';
import { CatalogWidgetComponentProps, CatalogWidgetItems } from 'Component/CatalogWidget/CatalogWidget.type';
import Loader from 'Component/Loader';
import ProductCard from 'Component/ProductCard';
import SliderNextArrow from 'Component/SliderNextArrow';
import SliderPrevArrow from 'Component/SliderPrevArrow';
import { ReactElement } from 'Type/Common.type';

import './CatalogWidget.style';

/** @namespace PlugAndSell2/Component/CatalogWidget/Component */
export class CatalogWidgetComponent extends PureComponent<CatalogWidgetComponentProps> {
    static defaultProps = {
        settings: {},
    };

    renderProductCard(item: CatalogWidgetItems, i: number): ReactElement {
        return (
            <div block="CatalogWidget" elem="Item" key={i}>
                <ProductCard
                    product={{
                        id: item.id,
                        sku: item.sku,
                        name: item.name,
                        price_range: item.price_range,
                        small_image: { path: '', url: item.small_image.url },
                        url: item.url,
                    }}
                    sliderMode
                />
            </div>
        );
    }

    render(): ReactElement {
        const { isLoading, items } = this.props;

        if (isLoading) {
            return (
                <div block="CatalogWidget" elem="Loader">
                    <Loader />
                </div>
            );
        }

        if (!items || !items?.length) {
            return (
                <div block="CatalogWidget" elem="NoProducts">
                    {__('No products')}
                </div>
            );
        }

        return (
            <div block="CatalogWidget">
                <SlickSlider prevArrow={<SliderPrevArrow />} nextArrow={<SliderNextArrow />} {...DEFAULT_CATALOG_WIDGET_SLICK_SETTINGS}>
                    {items.map(this.renderProductCard)}
                </SlickSlider>
            </div>
        );
    }
}

export default CatalogWidgetComponent;
