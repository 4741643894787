import { PureComponent } from 'react';

import { ReactElement } from 'Type/Common.type';

import { TabsComponentProps } from './Tabs.type';

import './Tabs.style';

/** @namespace PlugAndSell2/Component/Tabs/Component */
export class TabsComponent extends PureComponent<TabsComponentProps> {
    renderChildren(): ReactElement {
        const { children } = this.props;

        return children;
    }

    renderTabs() {
        const { tabs } = this.props;
        const { activeTab, handleTabClick } = this.props;

        return tabs.map(({ name, id }) => (
            <button
                key={id}
                // eslint-disable-next-line react/jsx-no-bind
                onClick={() => handleTabClick(id)}
                block="Tabs"
                elem="Tab"
                mods={{ isActive: activeTab === id }}
                mix={{ block: 'TypographyHeader', mods: { heading: 'h5' } }}
            >
                <h2>{name}</h2>
            </button>
        ));
    }

    render(): ReactElement {
        return (
            <div block="Tabs">
                <div block="Tabs" elem="Tabs">
                    {this.renderTabs()}
                </div>
                {this.renderChildren()}
            </div>
        );
    }
}

export default TabsComponent;
