import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { RootState } from 'Util/Store/Store.type';

import ClickOutsideComponent from './ClickOutside.component';
import { ClickOutsideComponentProps, ClickOutsideContainerProps } from './ClickOutside.type';

/** @namespace PlugAndSell2/Component/ClickOutside/Container/mapStateToProps */
export const mapStateToProps = (state: RootState) => ({
    areOtherOverlaysOpen: state.OverlayReducer.areOtherOverlaysOpen,
});
/** @namespace PlugAndSell2/Component/ClickOutside/Container/mapDispatchToProps */
export const mapDispatchToProps = () => ({});

/** @namespace PlugAndSell2/Component/ClickOutside/Container */
export class ClickOutsideContainer extends PureComponent<ClickOutsideContainerProps> {
    static defaultProps = {
        disabledOtherOverlaysOpen: false,
    };

    containerProps(): ClickOutsideComponentProps {
        const { disabledOtherOverlaysOpen, areOtherOverlaysOpen, children, onClick } = this.props;

        return {
            areOtherOverlaysOpen: disabledOtherOverlaysOpen ? true : areOtherOverlaysOpen,
            children,
            onClick,
        };
    }

    render() {
        return <ClickOutsideComponent {...this.containerProps()} />;
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ClickOutsideContainer);
