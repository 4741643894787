import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import Field from 'Component/Field';
import Html from 'Component/Html';
import Loader from 'Component/Loader';
import { FieldType } from "Component/Field/Field.config";


import "./Consents.style.scss"

/** @namespace Advox/Storefront/Component/Consents/Component */
export class Consents extends PureComponent {
    static propTypes = {
        isLoading: PropTypes.bool.isRequired,
        consents: PropTypes.arrayOf(
            PropTypes.shape({
                consent_id: PropTypes.string,
                description: PropTypes.string,
                is_required: PropTypes.bool,
                is_checkbox: PropTypes.bool
            })
        ).isRequired
    };

    renderConsent(consent) {
        const {
            consent_id, is_checkbox: isCheckbox, is_required: isRequired, description
        } = consent ?? {};

        if (!isCheckbox) {
            return <Html key={ consent_id } content={ description } />;
        }

        return (
            <Field
                type={FieldType.CHECKBOX}
                attr={{
                    id: consent_id,
                    name: consent_id,
                    "data-consent-id": consent_id
                }}
                label={<Html content={ description } />}
                validationRule={{
                    isRequired
                }}
                withErrorMessage
            />
        );
    }

    render() {
        const { isLoading, consents } = this.props;

        if (isLoading) {
            return <Loader isLoading />;
        }

        if (!consents || !consents.length) {
            return null;
        }

        return <div block="Consents">{ consents.map(this.renderConsent.bind(this)) }</div>;
    }
}

export default Consents;
