import { ComponentType, createRef, PureComponent } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { FIELD_RADIO_NONE, FieldType } from 'Component/Field/Field.config';
import { ATTRIBUTES, ProductType } from 'Component/Product/Product.config';
import ProductListQuery from 'Query/ProductList.query';
import { showNotification } from 'Store/Notification/Notification.action';
import { NotificationType } from 'Store/Notification/Notification.type';
import { NetworkError, ReactElement } from 'Type/Common.type';
import { GQLCurrencyEnum } from 'Type/Graphql.type';
import fromCache from 'Util/Cache/Cache';
import getFieldsData from 'Util/Form/Extract';
import { FieldData } from 'Util/Form/Form.type';
import { ADD_TO_CART, calculatePriceWithCustomPackaging, getNewParameters, getVariantIndex, variantToProduct } from 'Util/Product';
import {
    getAdjustedPrice,
    getCustomVariant,
    getDrumSku,
    getGroupedProductsInStockQuantity,
    getMaxQuantity,
    getMinQuantity,
    getName,
    getPrice,
    getProductInStock,
    getQtyIncrement,
    getQuantity,
    hasCustomPackageSize,
    hasDrum,
} from 'Util/Product/Extract';
import {
    AdjustedPriceMap,
    ConfigurableProductSelectedVariantValue,
    DEFAULT_MAX_PRODUCTS,
    DEFAULT_MIN_PRODUCTS,
    IndexedProduct,
    ProductOption,
    ProductQuantity,
    ProductTransformData,
    QtyFields,
} from 'Util/Product/Product.type';
import { magentoProductTransform, transformParameters } from 'Util/Product/Transform';
import { getErrorMessage } from 'Util/Request/Error';
import { fetchQuery } from 'Util/Request/Query';
import { RootState } from 'Util/Store/Store.type';
import { validateGroup } from 'Util/Validator';

import {
    ProductComponentProps,
    ProductContainerFunctions,
    ProductContainerMapDispatchProps,
    ProductContainerMapStateProps,
    ProductContainerPropKeys,
    ProductContainerProps,
    ProductContainerState,
} from './Product.type';

/** @namespace PlugAndSell2/Component/Product/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch: Dispatch): ProductContainerMapDispatchProps => ({
    addProductToCart: async (options) => {
        import(
            /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
            'Store/Cart/Cart.dispatcher'
        ).then(
            /** @namespace PlugAndSell2/Component/Product/Container/mapDispatchToProps/then */
            ({ default: dispatcher }) => dispatcher.addProductToCart(dispatch, options)
        );
    },
    showError: (message) => dispatch(showNotification(NotificationType.ERROR, message)),
});

/** @namespace PlugAndSell2/Component/Product/Container/mapStateToProps */
export const mapStateToProps = (state: RootState): ProductContainerMapStateProps => ({
    cartId: state.CartReducer.cartTotals.id || '',
    device: state.ConfigReducer.device,
    isWishlistEnabled: state.ConfigReducer.wishlist_general_active,
});

/**
 * Abstract Product class used to hold shared functionality
 * between ProductDetails & ProductCard
 * @class ProductContainer
 * @namespace PlugAndSell2/Component/Product/Container */
export class ProductContainer<
    P extends ProductContainerProps = ProductContainerProps,
    S extends ProductContainerState = ProductContainerState
> extends PureComponent<P, S> {
    static defaultProps: Partial<ProductContainerProps> = {
        configFormRef: createRef<HTMLFormElement>(),
        parameters: {},
        defaultSelectedOptions: [],
        defaultEnteredOptions: [],
        cartId: '',
    };

    containerFunctions: ProductContainerFunctions = {
        addToCart: this.addToCart.bind(this),

        // Used to update entered and selected state values
        updateSelectedValues: this.updateSelectedValues.bind(this),
        setDownloadableLinks: this.setStateOptions.bind(this, 'downloadableLinks'),
        setQuantity: this.setQuantity.bind(this),
        setAdjustedPrice: this.setAdjustedPrice.bind(this),

        getActiveProduct: this.getActiveProduct.bind(this),
        setActiveProduct: this.updateConfigurableVariant.bind(this),
        getMagentoProduct: this.getMagentoProduct.bind(this),
        handleCutToSizeClick: this.handleCutToSizeClick.bind(this),
        setValidator: this.setValidator.bind(this),
        scrollOptionsIntoView: this.scrollOptionsIntoView.bind(this),
        updateAddToCartTriggeredWithError: this.updateAddToCartTriggeredWithError.bind(this),
    };

    validator: HTMLElement | null = null;

    __construct(props: ProductContainerProps): void {
        const { parameters } = props;

        super.__construct?.(props);

        // TODO: There is a strange error when type isn't compatible with the same type.
        // Probably this is related to the fact that the class is generic.
        // Need to investigate this later.
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        this.state = {
            // Used for customizable & bundle options
            enteredOptions: this.setDefaultProductOptions('defaultEnteredOptions', 'enteredOptions'),
            selectedOptions: this.setDefaultProductOptions('defaultSelectedOptions', 'selectedOptions'),
            addToCartTriggeredWithError: false,
            // Used for downloadable
            downloadableLinks: [],

            quantity: 1,

            // Used to add to the base price a selected option prices
            adjustedPrice: {},

            // Used for configurable product - it can be ether parent or variant
            selectedProduct: null,
            parameters,
            unselectedOptions: [],
            currentProductSKU: '',

            // Custom package size for cables
            customPackagingEnabled: false,
            totalWithPackaging: { value: NaN, currency: GQLCurrencyEnum.USD },
            packagingProduct: null,
        };
    }

    static getDerivedStateFromProps(props: ProductContainerProps, state: ProductContainerState): { quantity: ProductQuantity } | null {
        const { quantity: quantityState } = state;
        const quantity = ProductContainer.getDefaultQuantity(props, state);

        if (quantity && typeof quantityState !== 'object') {
            return { quantity };
        }

        return null;
    }

    // eslint-disable-next-line react/sort-comp
    static getDefaultQuantity(props: ProductContainerProps, state: ProductContainerState): ProductQuantity | null {
        const { quantity: stateQuantity, selectedProduct } = state;
        const { product, product: { type_id: typeId } = {} } = props;
        const quantity = Number(stateQuantity);

        if (!product) {
            return null;
        }

        if (typeId === ProductType.GROUPED) {
            return getGroupedProductsInStockQuantity(product);
        }

        const minQty = getMinQuantity(selectedProduct ?? product, -1);

        if (quantity < minQty) {
            return minQty;
        }

        const maxQty = getMaxQuantity(selectedProduct ?? product);

        if (quantity > maxQty) {
            return maxQty;
        }

        return null;
    }

    componentDidMount(): void {
        this.updateSelectedValues();
        this.updateAdjustedPrice();
    }

    componentDidUpdate(prevProps: ProductContainerProps, prevState: ProductContainerState): void {
        const { enteredOptions, selectedOptions, selectedProduct, downloadableLinks } = this.state;
        const {
            enteredOptions: prevEnteredOptions,
            selectedOptions: prevSelectedOptions,
            selectedProduct: prevSelectProduct,
            downloadableLinks: prevDownloadableLinks,
        } = prevState;

        if (enteredOptions !== prevEnteredOptions || selectedOptions !== prevSelectedOptions || downloadableLinks !== prevDownloadableLinks) {
            this.updateAdjustedPrice();
        }

        const { product } = this.props;
        const { product: prevProduct } = prevProps;

        if (product !== prevProduct) {
            const quantity = ProductContainer.getDefaultQuantity(this.props, this.state);

            if (quantity) {
                this.setQuantity(quantity);
            }

            this.fetchPackagingProduct();
            this.updateSelectedValues();
        }

        if (selectedProduct !== prevSelectProduct) {
            this.fetchPackagingProduct();
        }
    }

    containerProps(): Pick<ProductComponentProps, ProductContainerPropKeys> {
        const { quantity, parameters, adjustedPrice, unselectedOptions, addToCartTriggeredWithError, packagingProduct } = this.state;
        const { product, product: { lowest_price, options = [] } = {}, configFormRef, device, isWishlistEnabled } = this.props;

        const activeProduct = this.getActiveProduct();
        const magentoProduct = this.getMagentoProduct();
        const { price_range: priceRange = {}, dynamic_price: dynamicPrice = false, type_id: type } = activeProduct || {};

        const output = {
            inStock: fromCache(getProductInStock, [activeProduct, product]),
            maxQuantity: getQuantity(activeProduct, DEFAULT_MAX_PRODUCTS, QtyFields.MAX_SALE_QTY, -1),
            minQuantity: getQuantity(activeProduct, DEFAULT_MIN_PRODUCTS, QtyFields.MIN_SALE_QTY, -1),
            qtyStep: getQtyIncrement(activeProduct),
            productName: getName(product),
            productPrice: fromCache(getPrice, [priceRange, dynamicPrice, adjustedPrice, lowest_price, type, options]),
        };

        return {
            isWishlistEnabled,
            isWishlistButtonEnabled: this.getIsWishlistButtonEnabled(),
            unselectedOptions,
            quantity,
            product,
            configFormRef,
            parameters,
            device,
            magentoProduct,
            addToCartTriggeredWithError,
            customPackagingEnabled: this._getCustomPackagingEnabled(),
            totalWithPackaging: calculatePriceWithCustomPackaging(activeProduct, packagingProduct, Number(quantity)),
            packagingProduct,
            ...output,
        };
    }

    _getCustomPackagingEnabled(): boolean {
        const { customPackagingEnabled } = this.state;

        return customPackagingEnabled;
    }

    async fetchPackagingProduct(): Promise<void> {
        const { showError } = this.props;
        const activeProduct = this.getActiveProduct();

        this.setState({ packagingProduct: null });

        if (!hasDrum(activeProduct)) {
            return;
        }

        try {
            await fetchQuery(
                ProductListQuery.getQuery({
                    args: { filter: { productSKU: getDrumSku(activeProduct) || '' } },
                })
            ).then(
                /** @namespace PlugAndSell2/Component/Product/Container/ProductContainer/fetchPackagingProduct/fetchQuery/then */
                (data) => {
                    if (data.products?.items?.length > 1) {
                        throw new Error(__('Drum not found'));
                    }
                    this.setState({ packagingProduct: data.products?.items[0] });
                }
            );
        } catch (e) {
            showError(getErrorMessage(e as unknown as NetworkError));
        }
    }

    setValidator(elem: HTMLElement): void {
        if (elem && elem !== this.validator) {
            this.validator = elem;
        }
    }

    setDefaultProductOptions<T>(keyProp: 'defaultEnteredOptions' | 'defaultSelectedOptions', keyState: 'enteredOptions' | 'selectedOptions'): T {
        const { [keyProp]: value } = this.props;

        if (Array.isArray(value) && value.length > 0) {
            this.setState({ [keyState]: value || [] } as unknown as ProductContainerState, () => {
                this.updateAdjustedPrice();
            });
        }

        return (value || []) as unknown as T;
    }

    /**
     * Fetches form data for customizable and bundle options.
     * (Should be called when value is changed)
     */
    updateSelectedValues(data: Partial<ProductOption> = {}): void {
        const { configFormRef: { current } = {} } = this.props;

        if (!current) {
            return;
        }

        const enteredOptions: ProductOption[] = [];
        const selectedOptions: string[] = [];

        const { uid, value } = data;

        if (uid && value) {
            enteredOptions.push({
                uid,
                value,
            });
        }

        const values = getFieldsData(current, true, [FieldType.NUMBER_WITH_CONTROLS]);

        (values as FieldData[])?.forEach(({ field, name, value, type }) => {
            if (type === FieldType.SELECT) {
                selectedOptions.push(String(value));
            } else if (type === FieldType.CHECKBOX || type === FieldType.RADIO) {
                if (value !== FIELD_RADIO_NONE) {
                    selectedOptions.push(String(value));
                }
            } else if (type !== FieldType.NUMBER_WITH_CONTROLS && type !== FieldType.FILE) {
                enteredOptions.push({
                    uid: name,
                    value: String(value),
                });
            } else if (type === FieldType.FILE && field?.value) {
                enteredOptions.push({
                    uid: name,
                    value: String(value),
                });
            }
        });

        this.setState({
            enteredOptions,
            selectedOptions,
        });
    }

    /**
     * Generates adjusted price from entered, selected, link options
     */
    updateAdjustedPrice(): void {
        const { product } = this.props;
        const { downloadableLinks, enteredOptions, selectedOptions } = this.state;

        const adjustedPrice = getAdjustedPrice(product, downloadableLinks, enteredOptions, selectedOptions);

        this.setState({ adjustedPrice });
    }

    setAdjustedPrice(type: keyof AdjustedPriceMap, amount: number): void {
        const { adjustedPrice } = this.state;

        this.setState({
            adjustedPrice: {
                ...adjustedPrice,
                [type]: amount,
            },
        });
    }

    /**
     * checks for unselected options on add to cart event
     * @returns {boolean}
     */
    validateConfigurableProduct(): boolean {
        const { customPackagingEnabled, parameters } = this.state;

        const {
            product: { configurable_options = {} },
        } = this.props;

        let unselectedOptions = Object.keys(configurable_options).reduce((accumulator: string[], value) => {
            if (!parameters[value]) {
                accumulator.push(value);
            }

            return accumulator;
        }, []);

        if (unselectedOptions.find((opt) => opt === ATTRIBUTES.PACKAGE_SIZE) && customPackagingEnabled) {
            unselectedOptions = unselectedOptions.filter((code) => code !== ATTRIBUTES.PACKAGE_SIZE);
        }

        this.setState({ unselectedOptions });

        return unselectedOptions.length > 0;
    }

    updateAddToCartTriggeredWithError(): void {
        this.setState({ addToCartTriggeredWithError: false });
    }

    /**
     * Scrolls Product Options into view on error.
     */
    scrollOptionsIntoView(): void {
        // PLP Products do not have validator so we omit scrolling
        if (this.validator?.classList) {
            const attributes = this.validator.querySelector('[class$=-AttributesWrapper]');

            // For product configurable attributes
            if (attributes) {
                attributes.scrollIntoView({ block: 'center' });

                return;
            }

            this.validator.scrollIntoView();
        }
    }

    /**
     * Event that validates and invokes product adding into cart
     * @returns {*}
     */
    async addToCart(): Promise<void> {
        this.updateSelectedValues();
        const { showError } = this.props;

        if (this.hasError()) {
            return;
        }

        const activeProduct = this.getActiveProduct();

        if (!activeProduct) {
            return;
        }

        const { addProductToCart, cartId } = this.props;
        const products = this.getMagentoProduct(activeProduct);

        await addProductToCart({ products, cartId }).catch(
            /** @namespace PlugAndSell2/Component/Product/Container/ProductContainer/addToCart/addProductToCart/catch */
            (error: string) => {
                if (error) {
                    showError(error);
                }
            }
        );
    }

    /**
     * checks if product has errors before adding to cart
     * @returns {boolean}
     */
    hasError(): boolean {
        if (!this.validator) {
            return false;
        }

        const validationOutput = validateGroup(this.validator);

        const { errorMessages = [], errorFields = [], values = [] } = validationOutput !== true ? validationOutput : {};
        const { showError } = this.props;

        if (errorFields.length || errorMessages.length || this.validateConfigurableProduct() || this.filterAddToCartFileErrors(values)) {
            this.scrollOptionsIntoView();
            this.setState({ addToCartTriggeredWithError: true });
            showError(__('Incorrect or missing options!'));

            return true;
        }

        return false;
    }

    /**
     * filters error messages by non empty file value
     * @param errors
     * @returns {boolean}
     */
    filterAddToCartFileErrors(errors: Array<{ type: string; value: string | boolean }>): boolean {
        return errors ? errors.filter((e) => e.type === 'file' && e.value !== '').length !== 0 : false;
    }

    handleCutToSizeClick(): void {
        const { product } = this.props;
        const { customPackagingEnabled } = this.state;

        this.setState({
            customPackagingEnabled: !customPackagingEnabled,
        });

        this.updateConfigurableVariant(
            ATTRIBUTES.PACKAGE_SIZE,
            getCustomVariant(product)?.attributes[ATTRIBUTES.PACKAGE_SIZE].attribute_value || '',
            true
        );

        this.setQuantity(1);
    }

    /**
     * Updates configurable products selected variant
     * @param key
     * @param value
     */
    updateConfigurableVariant(key: string, value: ConfigurableProductSelectedVariantValue, checkEmptyValue = false): void {
        const { parameters: prevParameters } = this.state;

        const newParameters = getNewParameters(prevParameters, key, value);

        const { [key]: oldValue, ...currentParameters } = newParameters;
        const parameters = oldValue === '' && checkEmptyValue ? currentParameters : newParameters;

        this.setState({ parameters });

        const {
            product: { variants = [], configurable_options = {} },
        } = this.props;
        const { selectedProduct } = this.state;

        const newIndex = Object.keys(parameters).length === Object.keys(configurable_options).length ? getVariantIndex(variants, parameters) : -1;

        const newProduct = newIndex === -1 ? null : variants[newIndex];

        if (newProduct !== selectedProduct) {
            this.setState({
                selectedProduct: newProduct,
                parameters,
            });
        }
    }

    /**
     * Sets quantity, if grouped adds object over old,
     * if any other product updates value
     * @param quantity
     */
    setQuantity(quantity: ProductQuantity): void {
        if (typeof quantity === 'object') {
            const { quantity: oldQuantity = {} } = this.state;

            this.setState({ quantity: { ...(oldQuantity as Record<number, number>), ...quantity } });
        } else {
            this.setState({ quantity: +quantity });
        }
    }

    /**
     * Global state setting function
     * @param type State name
     * @param options State value
     */
    setStateOptions(type: keyof ProductContainerState, options: ProductContainerState[keyof ProductContainerState]): void {
        this.setState({ [type]: options } as unknown as ProductContainerState);
    }

    /**
     * Returns magento graphql compatible product data
     * @returns {*[]}
     */
    getMagentoProduct(paramProduct?: IndexedProduct): ProductTransformData[] {
        const { enteredOptions, selectedOptions, downloadableLinks, parameters } = this.state;

        const { product: propProduct } = this.props;

        const product = !paramProduct || hasCustomPackageSize(propProduct) ? propProduct : paramProduct;
        const { attributes = {} } = product;

        const configurableOptions = transformParameters(parameters, attributes);
        const quantity = this.getMagentoProductQuantity();

        return magentoProductTransform(ADD_TO_CART, product, quantity, enteredOptions, [
            ...selectedOptions,
            ...downloadableLinks,
            ...configurableOptions,
        ]);
    }

    getMagentoProductQuantity(): ProductQuantity {
        const { quantity: stateQuantity } = this.state;

        return stateQuantity;
    }

    /**
     * Returns currently selected product, differs from prop product, for
     * configurable products, as active product can be one of variants.
     * @returns {*}
     */
    getActiveProduct(): IndexedProduct {
        const { customPackagingEnabled, selectedProduct } = this.state;
        const { product } = this.props;

        if (customPackagingEnabled) {
            return variantToProduct(getCustomVariant(product));
        }

        return selectedProduct ?? product;
    }

    getIsWishlistButtonEnabled(): boolean {
        return true;
    }

    render(): ReactElement {
        return null;
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductContainer as unknown as ComponentType<ProductContainerProps>);
