import { PureComponent } from 'react';
import { connect } from 'react-redux';
import { RmaAvailableTableContainerFunctions, RmaAvailableTableContainerProps, RmaAvailableTableContainerState } from './RmaAvailableTable.type';
import RmaQuery from '../../query/Rma.query';
import { fetchQuery } from 'Util/Request/Query';
import RmaAvailableTable from './RmaAvailableTable.component';
import { RootState } from 'Util/Store/Store.type';
import { Device } from 'Type/Device.type';
import { OrderItem } from 'Query/Order.type';

export const mapStateToProps = (state: RootState) => ({
  device: state.ConfigReducer.device
})

export const mapDispatchToProps = () => ({})

class RmaAvailableTableContainer extends PureComponent<RmaAvailableTableContainerProps, RmaAvailableTableContainerState> {
  state = {
    error: null,
    isLoading: false,
    rmaAvailableData: []
  }
  
  containerProps(): Pick<RmaAvailableTableContainerState, 'error' | 'isLoading' | 'rmaAvailableData'> & { device: Device } {
    const { error, isLoading, rmaAvailableData } = this.state
    const { device } = this.props;

    return ({
      device,
      error,
      isLoading,
      rmaAvailableData
    })
  }

  containerFunctions(): RmaAvailableTableContainerFunctions {
    return ({
      onCreateReturnClick: this.onCreateReturnClick.bind(this)
    })
  }

  componentDidMount(): void {
    this.fetchAvailableRmas();
  }

  async fetchAvailableRmas(): Promise<void> {
    this.setLoading(true);

    try {
      const { customer: { orders: { items } } } = await fetchQuery(RmaQuery.getOrderList());
      this.setState({ rmaAvailableData: items })
    } catch(e: unknown) {
      this.setState({ error: JSON.stringify(e) });
    } finally {
      this.setLoading(false);
    }
  }

  onCreateReturnClick(order: Partial<OrderItem>): void {
    const { onCreateReturn } = this.props;
    
    onCreateReturn && onCreateReturn(order)
  }

  setLoading(value: boolean): void {
    this.setState({ isLoading: value })
  }

  render() {
    return (
      <RmaAvailableTable {...this.containerProps()} {...this.containerFunctions()} />
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RmaAvailableTableContainer);