import { OrderAddress, OrderItem } from "Query/Order.type"
import { RmaRequest, RmaRequestItemImage, RmaStatuses } from "../../query/Rma.type"
import { Money, OptimizedProductImage } from "Query/ProductList.type"
import { Customer } from "Query/MyAccount.type"

export enum RmaDetailsScreenType { 
  FORM,
  OVERVIEW 
}

export type RmaDetailsDataSourceItem = {
  itemId: string
  itemSku: string
  itemPrice: Money
  itemQty: number
  itemThumbnail: OptimizedProductImage
  itemName?: string
  itemImages?: RmaRequestItemImage[]
  itemRequestQty?: string
  itemReason?: string
  itemDesc?: string
  itemType?: string
}

export type RmaDetailsDataSource = {
  id: string
  orderId: string;
  purchaser: string;
  items: RmaDetailsDataSourceItem[] | null
  shippingAddress?: OrderAddress
  returnAddress?: {
    name: string
    account_no: string
    phone_number: string
  }
  email?: string
  dateRequested?: string
  status?: string
  total?: Money
}

export type RmaDetailsContainerProps = {
  type: RmaDetailsScreenType
} & RmaDetailsContainerMapStateToProps & RmaDetailsContainerMapDispatchToProps

export type RmaDetailsContainerMapStateToProps = {
  customer: Partial<Customer>
}

export type RmaDetailsContainerMapDispatchToProps = { }

export type RmaDetailsContainerState = {
  order: Partial<OrderItem> | null
  request: Partial<RmaRequest> | null
  requestStatuses: RmaStatuses
}

export type RmaDetailsComponentProps = { 
  dataSource: RmaDetailsDataSource | null
  type: RmaDetailsScreenType
  onClose?: () => void
}