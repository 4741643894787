import { createRef, PureComponent, ReactElement } from 'react';

import Icons from 'Component/Icons';
import { IconName } from 'Component/Icons/Icons.type';
import TextPlaceholder from 'Component/TextPlaceholder';
import { TextPlaceHolderLength } from 'Component/TextPlaceholder/TextPlaceholder.config';
import { isCrawler, isSSR } from 'Util/Browser';
import { getFixedElementHeight } from 'Util/CSS';

import { ExpandableContentComponentProps, ExpandableContentComponentState } from './ExpandableContent.type';

import './ExpandableContent.style';

/** @namespace PlugAndSell2/Component/ExpandableContent/Component */
export class ExpandableContentComponent extends PureComponent<ExpandableContentComponentProps, ExpandableContentComponentState> {
    static defaultProps: Partial<ExpandableContentComponentProps> = {
        heading: '',
        isContentExpanded: false,
        onClick: undefined,
        children: [],
        isArrow: false,
        mods: {},
        isExpandableOnDesktop: false,
        iconVariant: 'primary',
    };

    expandableContentRef = createRef<HTMLElement>();

    renderIconMap = {
        primary: {
            up: () => <Icons name={IconName.ARROW_UP} fill="#8A8989" width="10px" />,
            down: () => <Icons name={IconName.ARROW_DOWN} fill="#8A8989" width="10px" />,
        },
        secondary: {
            up: () => <Icons name={IconName.DROPDOWN_ARROW} fill="#000" width="20px" />,
            down: () => <Icons name={IconName.DROPDOWN_ARROW_UP} fill="#000" width="20px" />,
        },
    };

    __construct(props: ExpandableContentComponentProps): void {
        super.__construct?.(props);
        const { isContentExpanded } = this.props;

        const isForceExpanded = isSSR() || isCrawler();

        this.toggleExpand = this.toggleExpand.bind(this);

        this.state = {
            isContentExpanded: isForceExpanded || isContentExpanded,
            // eslint-disable-next-line react/no-unused-state
            prevIsContentExpanded: isContentExpanded,
        };
    }

    static getDerivedStateFromProps(
        {
            isContentExpanded,
            isExpandableOnDesktop,
            device: { isMobile, isTablet },
        }: Pick<ExpandableContentComponentProps, 'isContentExpanded' | 'isExpandableOnDesktop' | 'device'>,
        { prevIsContentExpanded }: Pick<ExpandableContentComponentState, 'prevIsContentExpanded'>
    ): ExpandableContentComponentState | null {
        const isExpandedOnStart = (!isMobile && !isTablet && isExpandableOnDesktop) || isContentExpanded;

        if (isExpandedOnStart !== prevIsContentExpanded) {
            return {
                prevIsContentExpanded: isExpandedOnStart,
                isContentExpanded: isExpandedOnStart,
            };
        }

        return null;
    }

    scrollToExpandedContent(): void {
        const { isContentExpanded } = this.state;
        const elem = this.expandableContentRef && this.expandableContentRef.current;

        if ((isContentExpanded && !elem) || !elem) {
            return;
        }

        const elemToWindowTopDist: number = elem.getBoundingClientRect().top;
        const windowToPageTopDist: number = document.body.getBoundingClientRect().top;
        const topToElemDistance: number = elemToWindowTopDist - windowToPageTopDist;
        const { total: totalFixedElementHeight, 'inset-block-end': bottomFixedElementHeight } = getFixedElementHeight();

        const elemMaxOffsetHeight =
            screen.height > elem.offsetHeight + bottomFixedElementHeight ? elem.offsetHeight : screen.height - totalFixedElementHeight;
        const scrollTo = topToElemDistance - (screen.height - bottomFixedElementHeight - elemMaxOffsetHeight);

        // checking if button is in a view-port
        if (-windowToPageTopDist >= scrollTo) {
            return;
        }

        window.scrollTo({ behavior: 'smooth', top: scrollTo });
    }

    toggleExpand(): void {
        const { onClick } = this.props;
        const { isContentExpanded: prevIsContentExpanded } = this.state;

        if (onClick) {
            onClick();

            return;
        }
        this.setState(
            () => ({ isContentExpanded: !prevIsContentExpanded }),
            () => !prevIsContentExpanded && this.scrollToExpandedContent()
        );
    }

    renderButton(): ReactElement {
        const { isContentExpanded } = this.state;
        const { heading, mix, iconVariant } = this.props;

        return (
            <div
                role="button"
                tabIndex={0}
                block="ExpandableContent"
                elem="Button"
                mods={{ isContentExpanded, iconVariant }}
                mix={{ ...mix, elem: 'ExpandableContentButton' }}
                onClick={this.toggleExpand}
                onKeyDown={this.toggleExpand}
            >
                <div block="ExpandableContent" elem="Heading" mix={{ ...mix, elem: 'ExpandableContentHeading' }}>
                    {typeof heading === 'string' ? <TextPlaceholder content={heading} length={TextPlaceHolderLength.MEDIUM} /> : heading}
                </div>
                {this.renderButtonIcon()}
            </div>
        );
    }

    renderButtonIcon(): ReactElement | null {
        const {
            isExpandableOnDesktop,
            device: { isMobile, isTablet },
        } = this.props;

        if (!isMobile && !isTablet && !isExpandableOnDesktop) {
            return null;
        }

        return this.renderTogglePlusMinus();
    }

    renderTogglePlusMinus(): ReactElement {
        const { isContentExpanded } = this.state;
        const { iconVariant } = this.props;

        if (isContentExpanded) {
            return this.renderIconMap[iconVariant].up();
        }

        return this.renderIconMap[iconVariant].down();
    }

    renderContent(): ReactElement {
        const { children, mix, isExpandableOnDesktop } = this.props;
        const { isContentExpanded } = this.state;
        const mods = { isContentExpanded, isExpandableOnDesktop };

        return (
            <div block="ExpandableContent" elem="Content" mods={mods} mix={{ ...mix, elem: 'ExpandableContentContent', mods }}>
                {children}
            </div>
        );
    }

    render(): ReactElement {
        const { mix, mods } = this.props;

        return (
            <article block="ExpandableContent" mix={mix} mods={mods} ref={this.expandableContentRef}>
                {this.renderButton()}
                {this.renderContent()}
            </article>
        );
    }
}
export default ExpandableContentComponent;
