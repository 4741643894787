import { PureComponent } from 'react';

import Icons from 'Component/Icons';
import { IconName } from 'Component/Icons/Icons.type';

import { SliderPrevArrowComponentProps } from './SliderPrevArrow.type';

import './SliderPrevArrow.style.scss';

/** @namespace PlugAndSell2/Component/SliderPrevArrow/Component */
export class SliderPrevArrowComponent extends PureComponent<SliderPrevArrowComponentProps> {
    render() {
        const { onClick, className } = this.props;
        const isDisabled = className?.search('disabled') !== -1;

        return (
            <div block="SliderPrevArrow" mods={{ disabled: isDisabled }} onClick={onClick}>
                <Icons name={IconName.ARROW_LEFT} width="25" />
            </div>
        );
    }
}

export default SliderPrevArrowComponent;
