import { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import ContractorStructureUpdateForm from './ContractorStructureUpdateForm.component';
import { FieldData } from 'Util/Form/Form.type';
import ContractorsDispatcher from '../../store/Contractors/Contractors.dispatcher';
import { ContractorStructureUpdateMutationInput } from '../../query/Contractors/Contractors.type';
import { showNotification } from 'Store/Notification/Notification.action';
import { NotificationType } from 'Store/Notification/Notification.type';
import { NetworkError } from 'Type/Common.type';
import { getErrorMessage } from 'Util/Request/Error';
import { fetchQuery } from 'Util/Request/Query';
import ContractorsQuery from '../../query/Contractors/Contractors.query';
import { getField } from '../../util/Form';
import { ContractorStructureUpdateFormContainerProps, ContractorStructureUpdateFormContainerState } from './ContractorStructureUpdateForm.type';
import transformCountriesToOptions from 'Util/Address';
import { RootState } from 'Util/Store/Store.type';

export const mapStateToProps = (state: RootState) => ({
  countries: transformCountriesToOptions(state.ConfigReducer.countries || [])
})

export const mapDispatchToProps = (dispatch: Dispatch) => ({
  updateContractorStructure: (input: ContractorStructureUpdateMutationInput) => ContractorsDispatcher.updateContractorStructure(input),
  showNotification: (type: NotificationType, msg: string) => dispatch(showNotification(type, msg))
})

class ContractorStructureUpdateFormContainer extends PureComponent<ContractorStructureUpdateFormContainerProps, ContractorStructureUpdateFormContainerState> {
  state = {
    isLoading: false,
    substructures: null
  }

  containerProps() {
    const { isLoading, substructures } = this.state;
    const { countries, structure } = this.props;

    return ({
      countries,
      isLoading,
      substructures,
      structure,
    })
  }
  

  async componentDidMount(): Promise<void> {
    this.getSubstructures()
  }

  containerFunctions() {
    return ({
      onFormError: this.handleFormError.bind(this),
      onFormSubmit: this.handleFormSubmit.bind(this)
    })
  }

  async getSubstructures() {
    this.setState({isLoading: true});
    const { getSubstructures } =  await fetchQuery(ContractorsQuery.getContractorSubstructuresQuery(1, 999));
    this.setState({ isLoading: false, substructures: getSubstructures?.items });
  }

  async handleFormSubmit(_form: HTMLFormElement, fields: FieldData[]) {
    const { structure, updateContractorStructure, onUpdateSuccess , showNotification, } = this.props;

    this.setState({ isLoading: true });

    debugger;

    try {
      await updateContractorStructure({
        id: structure.id,
        parentId: Number(getField('parentId', fields)?.value) ?? NaN,
        address: {
          name: String(getField('name', fields)?.value) || '',
          street: String(getField('street', fields)?.value) || '',
          city: String(getField('city', fields)?.value) || '',
          postcode: String(getField('postcode', fields)?.value) || '',
          province: String(getField('province', fields)?.value) || '',
          phone: String(getField('phone', fields)?.value) || '',
          country: String(getField('country_id', fields)?.value) || '',
          email: String(getField('email', fields)?.value) || '',
        },
      })

      onUpdateSuccess && onUpdateSuccess();
    } catch(e) {
      showNotification(NotificationType.ERROR, getErrorMessage(e as unknown as NetworkError | NetworkError[]))
    } finally {
      this.setState({ isLoading: false });
    }
  }

  handleFormError() {}

  render() {
    return (
      <ContractorStructureUpdateForm {...this.containerProps()} {...this.containerFunctions()}/>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ContractorStructureUpdateFormContainer);

