import { PureComponent } from 'react';
import { RmaAvailableComponentProps } from './RmaAvailable.type';
import { ReactElement } from 'Type/Common.type';
import RmaAvailableTable from '../RmaAvailableTable';

import './RmaAvailable.style';
import RmaDetails from '../RmaDetails';
import { RmaDetailsScreenType } from '../RmaDetails/RmaDetails.type';

class RmaAvailable extends PureComponent<RmaAvailableComponentProps> {
  renderDetails(): ReactElement {
    return (
      <RmaDetails type={RmaDetailsScreenType.FORM} />
    )
  }

  renderTable(): ReactElement {
    const { onCreateReturn } = this.props

    return (
      <RmaAvailableTable onCreateReturn={onCreateReturn} />
    )
  }

  render(): ReactElement {
    const { showDetails } = this.props;
    
    if (showDetails) {
      return this.renderDetails();
    }

    return (
      <div block="RmaAvailable">
          <h4 block="RmaAvailable" elem="Subheading">
            {__('Choose order to create a return or complaint')}
          </h4>   
          {this.renderTable()}     
      </div>
    );
  }
}

export default RmaAvailable;