import { PureComponent } from 'react';
import Popup from 'Component/Popup';
import { ReactElement } from 'Type/Common.type';
import { CONTRACTOR_STRUCTURE_DELETE_POPUP_ID } from './ContractorStructureDelete.config';
import { ContractorStructureDeleteComponentProps } from './ContractorStructureDelete.type';

import './ContractorStructureDelete.style'

class ContractorStructureDelete extends PureComponent<ContractorStructureDeleteComponentProps> {
  renderPopup(): ReactElement {
    const { onConfirm, onDismiss, structure } = this.props;
    
    return (
      <Popup id={`${CONTRACTOR_STRUCTURE_DELETE_POPUP_ID}-${structure.id}`}  mix={{ block: 'ContractorStructureDeletePopup' }}>
        {__('You will delete structure')} {structure.name} (ID: {structure.id})
        <div block='ContractorStructureDeletePopup' elem='Actions'>
          <button onClick={() => onDismiss()}>Cancel</button>
          <button onClick={() => onConfirm()} block="ContractorStructureDeletePopup" elem="Delete">Confirm</button>
        </div>
      </Popup>
    )
  }

  render(): ReactElement {
    const { handleDeleteStructureClick } = this.props;

    return (
      <div elem="ContractorStructureDelete">        
        <button onClick={handleDeleteStructureClick}>
          {__('Delete')}
        </button>
        {this.renderPopup()}
      </div>
    );
  }
}

export default ContractorStructureDelete;