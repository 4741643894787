import { format } from 'date-fns';
import { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { withRouter } from 'react-router';

import OrderQuery from 'Query/Order.query';
import { showNotification } from 'Store/Notification/Notification.action';
import { showPopup } from 'Store/Popup/Popup.action';
import { fetchQuery } from 'Util/Request/Query';
import { convertQueryStringToKeyValuePairs, setQueryParams } from 'Util/Url';

import FinancialDocs from './FinancialDocs.component';
import { ORDER_PREVIEW_POPUP } from './FinancialDocs.config';
import FinancialDocsQuery from '../../query/FinancialDocs.query';
import { RootState } from 'Util/Store/Store.type';
import { FinancialDocsContainerProps, FinancialDocsContainerState, FinancialDocsFilterValue, FinancialDocsFilters, OrderPreviewPopupPayload, Sorting } from './FinancialDocs.type';
import { NotificationType } from 'Store/Notification/Notification.type';
import { FilterValues } from '../Filters/Filters.type';
import { FinancialDoc, FinancialDocFilterInput, FinancialDocSortInput, SortEnum } from '../../query/FinancialDocs.type';

/** @namespace PlugAndSell2/Component/FinancialDocs/Container/mapStateToProps */
export const mapStateToProps = (state: RootState) => ({
  // @ts-ignore
  orderPreviewPopupPayload: state.PopupReducer.popupPayload[ORDER_PREVIEW_POPUP] as OrderPreviewPopupPayload,
  isMobile: state.ConfigReducer.device.isMobile,
});

/** @namespace PlugAndSell2/Component/FinancialDocs/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch: Dispatch) => ({
  showNotification: (type: NotificationType, msg: string) => dispatch(showNotification(type, msg)),
  showPopup: (id: string, payload: OrderPreviewPopupPayload | null) => dispatch(showPopup(id, payload)),
});

export const DEFAULT_SORTING = {};

export const DEFAULT_SORTING_TO_REQUEST = {
  doc_date: 'DESC',
};

/** @namespace PlugAndSell2/Component/FinancialDocs/Container/FinancialDocsContainer */
export class FinancialDocsContainer extends PureComponent<FinancialDocsContainerProps, FinancialDocsContainerState> {
  state = {
    financialDocs: {
      items: [],
      page_info: {
        current_page: NaN,
        page_size: NaN,
        total_pages: NaN
      },
      total_count: NaN,
    },
    currentPage: 1,
    selectedPageSize: 10,
    lastFilters: {},
    sorting: {},
    orderPreviews: {},
    isFilteredOrdersLoading: false,
    isLoading: false,
  };

  componentDidMount() {
    const { location } = this.props;
    const { sorting, ...filters } = convertQueryStringToKeyValuePairs(
      location.search
    );
    const { page, ...clearFilters } = filters || {};

    const preparedSorting = sorting ? { [sorting.split(',')[0]]: sorting.split(',')[1] } : DEFAULT_SORTING;

    this.setState({ lastFilters: filters, sorting: preparedSorting }, () =>
      this.fetchFinancialDocs(
        this.prepareFilters(clearFilters),
        preparedSorting,
        Number(page) || 1
      )
    );
  }

  containerFunctions = () => ({
    fetchOrderPreview: this.fetchOrderPreview.bind(this),
    handleColumnSorting: this.handleColumnSorting.bind(this),
    onDetailsClick: this.onDetailsClick.bind(this),
    onFilterChange: this.onFilterChange.bind(this),
    onDownloadClick: this.onDownloadClick.bind(this),
    onPageSelect: this.onPageSelect.bind(this),
    onPageSizeSelected: this.onPageSizeSelected.bind(this),
  });

  fetchFinancialDocs(filters: FinancialDocFilterInput, sorting: FinancialDocSortInput, page: number) {
    const { showNotification } = this.props;
    const { selectedPageSize } = this.state;

    this.setState({ isLoading: true });

    fetchQuery(
      FinancialDocsQuery.getFinancialDocsQuery(
        filters,
        Object.keys(sorting).length ? sorting : DEFAULT_SORTING_TO_REQUEST,
        page,
        selectedPageSize
      )
    )
      .then(
        /** @namespace PlugAndSell2/Component/FinancialDocs/Container/fetchQuery/then */
        ({ getFinancialDocs }) => {
          this.setState({ financialDocs: getFinancialDocs });
        }
      )
      .catch(
        /** @namespace PlugAndSell2/Component/FinancialDocs/Container/fetchQuery/then/catch */
        (e) => {
          showNotification(NotificationType.ERROR, e[0].message);
        }
      )
      .finally(() => this.setState({ isLoading: false }));
  }

  fetchOrderPreview(orderId: number) {
    const { showNotification, showPopup } = this.props;

    fetchQuery(OrderQuery.getOrderListQuery({ orderId }))
      .then(
        /** @namespace PlugAndSell2/Component/FinancialDocs/Container/fetchQuery/then */
        ({ customer: { orders: { items } } }) => {
          this.setState((state) => ({
            orderPreviews: { ...state.orderPreviews, [orderId]: items[0] },
          }));
        }
      )
      .catch(
        /** @namespace PlugAndSell2/Component/FinancialDocs/Container/fetchQuery/then/catch */
        (e) => {
          showNotification(NotificationType.ERROR, e[0].message);
          showPopup('', null);
        }
      )
      .finally(() => this.setState({ isLoading: false }));
  }

  forceClearFilters(): void {
    document.querySelectorAll<HTMLInputElement | HTMLSelectElement>('.FinancialDocs .Filters input,select').forEach((node) => { node.value = '' })
  }

  async handleColumnSorting(columnName: string) {
    const { currentPage, lastFilters } = this.state;

    const preparedSorting = this.prepareSorting(columnName);
    const preparedFilters = this.prepareFilters(lastFilters);

    this.setSorting(preparedSorting, () => {
      this.fetchFinancialDocs(
        preparedFilters,
        preparedSorting,
        currentPage
      );
    });
  }

  onDetailsClick(doc: FinancialDoc) {
    const { showPopup } = this.props;

    if (!doc.order_id) {
      return
    }

    showPopup(ORDER_PREVIEW_POPUP, { doc });
    this.fetchOrderPreview(doc.order_id);
  }

  onDownloadClick(doc: FinancialDoc) {
    if (!doc.reference) {
      return;
    }

    window.open(doc.reference);
  }

  onFilterChange(filters: FilterValues<FinancialDocsFilters> | null) {
    const { sorting } = this.state;

    if (filters === null) {
      this.forceClearFilters()
    }

    this.setPageUrl(1);
    this.setState({ lastFilters: filters || {} });
    this.fetchFinancialDocs(
      this.prepareFilters(filters || {}),
      sorting,
      1
    );
  }

  onPageSelect(currentPage: number) {
    const { location } = this.props;
    const { sorting } = this.state;
    const filters = convertQueryStringToKeyValuePairs(location.search);
    const { page, ...clearFilters } = filters;

    this.fetchFinancialDocs(
      this.prepareFilters(clearFilters),
      sorting,
      Number(currentPage)
    );
  }

  onPageSizeSelected(selectedPageSize: number) {
    const { lastFilters } = this.state;
    this.setPageUrl(1);
    this.setState({ selectedPageSize }, () =>
      this.fetchFinancialDocs(
        this.prepareFilters(lastFilters),
        {},
        1
      )
    );
  }

  setPage = (page: number) => {
    this.setState({ currentPage: page });
    this.setPageUrl(page);
  };

  setPageUrl = (page: number) => {
    const { location, history } = this.props;
    setQueryParams({ page: String(page) }, location, history);
  };

  prepareFilters = ({
    grand_total,
    grand_total_to,
    number,
    doc_date,
    doc_date_to,
    payment_date,
    payment_date_to,
    invoice_numbers
  }: Record<string, FinancialDocsFilterValue> = {}): Partial<FinancialDocFilterInput> => {
    const dateToString = (date: Date): string => format(date, 'yyyy-MM-dd HH:mm:ss');
    const splitDateString = (dateStr: string): string[] => dateStr.split(' - ');
    const nowDate = new Date().toISOString().slice(0, 10);

    // @ts-ignore
    return {
      ...(payment_date && payment_date_to
        ? {
          payment_date: {
            from:
              typeof payment_date === 'string'
                ? splitDateString(payment_date)[0].concat(" 00:00:00")
                : dateToString(payment_date?.min || payment_date),
            to:
              typeof payment_date_to === 'string'
                ? splitDateString(payment_date_to || nowDate)[0].concat(" 23:59:59")
                : dateToString(payment_date_to?.max || nowDate),
          },
        }
        : {}),
      ...(payment_date && !payment_date_to
        ? {
          payment_date: {
            from:
              typeof payment_date === 'string'
                ? splitDateString(payment_date)[0].concat(" 00:00:00")
                : dateToString(payment_date?.min)
          },
        }
        : {}),
      ...(payment_date_to && !payment_date
        ? {
          payment_date: {
            to:
              typeof payment_date_to === 'string'
                ? splitDateString(payment_date_to || nowDate)[0].concat(" 23:59:59")
                : dateToString(payment_date_to?.min || nowDate),
          },
        }
        : {}),
      ...(doc_date && doc_date_to
        ? {
          doc_date: {
            from:
              typeof doc_date === 'string'
                ? splitDateString(doc_date)[0].concat(" 00:00:00")
                : dateToString(doc_date?.min || doc_date),
            to:
              typeof doc_date_to === 'string'
                ? splitDateString(doc_date_to || nowDate)[0].concat(" 23:59:59")
                : dateToString(doc_date_to?.max || nowDate),
          },
        }
        : {}),
      ...(doc_date && !doc_date_to
        ? {
          doc_date: {
            from:
              typeof doc_date === 'string'
                ? splitDateString(doc_date)[0].concat(" 00:00:00")
                : dateToString(doc_date?.min)
          },
        }
        : {}),
      ...(doc_date_to && !doc_date
        ? {
          doc_date: {
            to:
              typeof doc_date_to === 'string'
                ? splitDateString(doc_date_to || nowDate)[0].concat(" 23:59:59")
                : dateToString(doc_date_to?.min || nowDate),
          },
        }
        : {}),
      ...(number ? { number: { like: number } } : {}),
      ...(grand_total || grand_total_to ? { value: { from: grand_total, to: grand_total_to } } : {}),
    };
  }

  prepareSorting = (columnName: string) => {
    const { sorting: prevSorting } = this.state;
    return {
      [columnName]: prevSorting[columnName as keyof object] === SortEnum.DESC ? SortEnum.ASC : SortEnum.DESC,
    };
  };

  setSorting = (sorting: { [x: string]: SortEnum }, onSuccess: () => void) => {
    const { history } = this.props;

    setQueryParams(
      {
        sorting: `${Object.keys(sorting)[0]},${sorting[Object.keys(sorting)[0]]
          }`,
      },
      history.location,
      history
    );
    this.setState({ sorting }, onSuccess);
  };

  render() {
    return (
      <FinancialDocs
        {...this.props}
        {...this.state}
        {...this.containerFunctions()}
      />
    );
  }
}

// @ts-ignore
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(FinancialDocsContainer));
