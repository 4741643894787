import { ReactElement } from 'Type/Common.type';

export interface ButtonComponentProps {
    variant: ButtonVariant;
    size: ButtonSize;
    asLink: boolean;
    iconBefore?: ReactElement;
    iconAfter?: ReactElement;
    children?: ReactElement;
    to?: string;
    onClick?: any;
    disabled?: boolean;
    title?: string;
    type?: 'submit' | 'reset' | 'button';
}

export enum ButtonVariant {
    PRIMARY = 'primary',
    SECONDARY = 'secondary',
    OUTLINE = 'outline',
    OUTLINE_INVERSE = 'outlineInverse',
    OUTLINE_DARK = 'outlineDark',
    OUTLINE_DARK_TRANSPARENT_BG = 'outlineDarkTransparentBg',
    LIKE_LINK = 'likeLink',
    ICON_ONLY = 'iconOnly',
}

export enum ButtonSize {
    LARGE = 'large',
    MEDIUM = 'medium',
    SMALL = 'small',
}
