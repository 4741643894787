import { PureComponent } from 'react';

import { ButtonSize, ButtonVariant } from 'Component/Button/Button.type';
import Field from 'Component/Field';
import { FieldType } from 'Component/Field/Field.config';
import Form from 'Component/Form';
import Loader from 'Component/Loader';
import { ReactElement } from 'Type/Common.type';
import { ValidationInputType } from 'Util/Validator/Config';

import { MyAccountSignInComponentProps } from './MyAccountSignIn.type';

import './MyAccountSignIn.style.scss';

/** @namespace PlugAndSell2/Component/MyAccountSignIn/Component */
export class MyAccountSignInComponent extends PureComponent<MyAccountSignInComponentProps> {
    static defaultProps: Partial<MyAccountSignInComponentProps> = {
        isLoading: false,
    };

    renderSignInForm(): ReactElement {
        const {
            onSignInSuccess,
            onFormError,
            handleForgotPassword,
            emailValue,
            isCheckout,
            handleEmailInput,
            isLoading,
            displayedAsPage,
            withEmailInfo,
        } = this.props;

        return (
            <Form key="sign-in" onSubmit={onSignInSuccess} onError={onFormError}>
                <Field
                    label={__('E-mail')}
                    type={FieldType.EMAIL}
                    attr={{
                        id: 'email',
                        name: 'email',
                        placeholder: displayedAsPage ? __('Enter your email...') : __('E-mail'),
                        defaultValue: emailValue,
                        autoComplete: isCheckout ? 'off' : 'email',
                    }}
                    validateOn={['onChange']}
                    validationRule={{
                        isRequired: true,
                        inputType: ValidationInputType.EMAIL,
                    }}
                    events={{ onChange: handleEmailInput }}
                    addRequiredTag
                />
                {withEmailInfo && (
                    <p block="MyAccountSignIn" elem="EmailInfo">
                        {__('We will send an order confirmation to this address')}
                        <br />
                        {__('After placing your order, you will be able to create an account')}{' '}
                    </p>
                )}
                <Field
                    label={__('Password')}
                    type={FieldType.PASSWORD}
                    attr={{
                        id: 'password',
                        name: 'password',
                        placeholder: displayedAsPage ? __('Enter your password...') : __('Password'),
                        autoComplete: 'current-password',
                    }}
                    validateOn={isCheckout ? ['onSubmit'] : ['onChange']}
                    validationRule={{
                        isRequired: true,
                        inputType: ValidationInputType.PASSWORD,
                    }}
                    addRequiredTag
                />
                <div block="MyAccountSignIn" elem="Actions">
                    <div block="MyAccountSignIn" elem="Link">
                        <button type="button" block="Button" mods={{ likeLink: true }} onClick={handleForgotPassword}>
                            {__('Forgot password?')}
                        </button>
                    </div>
                    <div block="MyAccountSignIn" elem="Button">
                        <button block="Button" mods={{ size: !displayedAsPage && ButtonSize.MEDIUM, variant: ButtonVariant.SECONDARY }}>
                            {__('Sign In')}
                        </button>
                    </div>
                </div>
                <Loader isLoading={isLoading} />
            </Form>
        );
    }

    renderAdditionalField(): ReactElement {
        const { isCheckout, handleCreateAccount, state, displayedAsPage } = this.props;

        if (isCheckout || displayedAsPage) {
            return null;
        }

        return (
            <article block="MyAccountOverlay" elem="Additional" mods={{ state }}>
                <section>
                    <span block="MyAccountOverlay" elem="AdditionalLabel">
                        {__("Don't have an account?")}
                    </span>
                    <button block="Button" mods={{ likeLink: true }} onClick={handleCreateAccount}>
                        {__('Create an Account')}
                    </button>
                </section>
            </article>
        );
    }

    render(): ReactElement {
        return (
            <div block="MyAccountSignIn">
                {this.renderSignInForm()}
                {this.renderAdditionalField()}
            </div>
        );
    }
}

export default MyAccountSignInComponent;
