import { PureComponent } from 'react';
import { connect } from 'react-redux';

import PageSize from './PageSize.component';
import { PageSizeContainerProps } from './PageSize.type';

/** @namespace KratkiPwa/Component/PageSize/Container/mapStateToProps */
export const mapStateToProps = () => ({});

/** @namespace KratkiPwa/Component/PageSize/Container/mapDispatchToProps */
export const mapDispatchToProps = () => ({});

/** @namespace KratkiPwa/Component/PageSize/Container/PageSizeContainer */
export class PageSizeContainer extends PureComponent<PageSizeContainerProps> {
  render() {
    return <PageSize {...this.props} />;
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PageSizeContainer);
