import { PureComponent } from 'react';

import Field from 'Component/Field';
import { FieldType } from 'Component/Field/Field.config';
import { ReactElement } from 'Type/Common.type';

import { DataPair, KeyValueTableComponentProps } from './KeyValueTable.type';

import './KeyValueTable.style';

/** @namespace PlugAndSell2/Component/KeyValueTable/Component */
export class KeyValueTableComponent<T extends KeyValueTableComponentProps = KeyValueTableComponentProps> extends PureComponent<T> {
    static defaultProps: Partial<KeyValueTableComponentProps> = {
        title: '',
        isSelected: false,
    };

    dataPairArray(): DataPair<Record<string, string | string[]>>[] {
        return [
            /**
             * {
             *     key: 'id',
             *     label': 'Identifier',
             *     source: customer
             * }
             */
        ];
    }

    getValueFromSource({ key, source }: DataPair<Record<string, string | string[] | boolean>>): string | number {
        const { [key]: value } = source;

        if (!value) {
            return '';
        }

        if (Array.isArray(value)) {
            return value.join(' ');
        }

        if (typeof value === 'boolean') {
            return Number(value);
        }

        return value;
    }

    renderTableRow(data: DataPair<Record<string, string | string[]>>): ReactElement {
        const { key, label } = data;
        const value = this.getValueFromSource(data);

        if (!value) {
            return null;
        }

        return (
            <tr key={key}>
                <th>{`${label}:`}</th>
                <td>{value}</td>
            </tr>
        );
    }

    renderHeading(): ReactElement {
        const { title, isSelected } = this.props;

        if (!title) {
            return null;
        }

        return (
            <tr>
                <th block="KeyValueTable" elem="Heading" colSpan={2}>
                    <Field type={FieldType.RADIO} attr={{ checked: isSelected }} />
                    {title}
                </th>
            </tr>
        );
    }

    renderTable(): ReactElement {
        return (
            <div block="KeyValueTable" elem="Wrapper">
                <table block="KeyValueTable">
                    <thead>{this.renderHeading()}</thead>
                    <tbody>{this.dataPairArray().map(this.renderTableRow.bind(this))}</tbody>
                </table>
            </div>
        );
    }

    render(): ReactElement {
        return this.renderTable();
    }
}

export default KeyValueTableComponent;
