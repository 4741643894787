import { Field, Query } from '@tilework/opus';

import { Slide, Slider } from './Slider.type';

/**
 * Slider Query
 * @class Slider
 * @namespace PlugAndSell2/Query/Slider/Query */
export class SliderQuery {
    getQuery(options: { sliderId: string }): Query<'slider', Slider> {
        const { sliderId } = options;

        return new Query<'scandiwebSlider', Slider>('scandiwebSlider')
            .addArgument('id', 'ID!', sliderId)
            .addFieldList(this._getSliderFields())
            .setAlias('slider');
    }

    _getSliderFields(): Array<Field<'slides', Slide, true> | Field<'slide_speed', number> | Field<'slider_id', number> | Field<'title', string>> {
        return [this._getSlidesField(), this._getSlideSpeedField(), new Field<'slider_id', number>('slider_id'), new Field<'title', string>('title')];
    }

    _getSlideFields(): Array<
        | Field<'slide_text', string>
        | Field<'slide_id', number>
        | Field<'mobile_image', string>
        | Field<'desktop_image', string>
        | Field<'title', string>
        | Field<'is_active', boolean>
        // eslint-disable-next-line prettier/prettier
        > {
        return [
            new Field<'slide_text', string>('slide_text'),
            new Field<'slide_id', number>('slide_id'),
            new Field<'mobile_image', string>('mobile_image'),
            new Field<'desktop_image', string>('desktop_image'),
            new Field<'title', string>('title'),
            new Field<'is_active', boolean>('is_active'),
        ];
    }

    _getSlidesField(): Field<'slides', Slide, true> {
        return new Field<'slides', Slide, true>('slides', true).addFieldList(this._getSlideFields());
    }

    _getSlideSpeedField(): Field<'slide_speed', number> {
        return new Field<'slide_speed', number>('slide_speed');
    }

    getSliderQuery(position: string, code: string): Query<'slider', Slider> {
        return new Query<'slider', Slider>('slider')
            .addArgument('position', 'String!', position)
            .addArgument('code', 'String!', code)
            .addField(this._getBrandsItemField());
    }

    _getBrandsItemField(): Field<'slides', Slide, true> {
        return new Field<'slides', Slide, true>('slides').addFieldList(this._getSliderItemFields());
    }

    _getSliderItemFields(): Array<
        | Field<'body', string>
        | Field<'cta_color', string>
        | Field<'cta_link', string>
        | Field<'cta_text', string>
        | Field<'header', string>
        | Field<'image', string>
        | Field<'image_height', string>
        | Field<'image_width', string>
        | Field<'mobile_image', string>
        | Field<'tablet_image', string>
        // eslint-disable-next-line prettier/prettier
        > {
        return [
            new Field<'body', string>('body'),
            new Field<'cta_color', string>('cta_color'),
            new Field<'cta_link', string>('cta_link'),
            new Field<'cta_text', string>('cta_text'),
            new Field<'header', string>('header'),
            new Field<'image', string>('image'),
            new Field<'image_height', string>('image_height'),
            new Field<'image_width', string>('image_width'),
            new Field<'mobile_image', string>('mobile_image'),
            new Field<'tablet_image', string>('tablet_image'),
        ];
    }
}

export default new SliderQuery();
