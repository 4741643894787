export function getPathname(url, base) {
    try {
        if (base) {
            return new URL(url, base).toString();
        }

        return new URL(url).pathname;
    } catch {
        return '';
    }
}
