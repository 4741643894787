import FieldForm from 'Component/FieldForm';
import { FieldContainerProps } from 'Component/Field/Field.type';
import { FormContainerProps } from 'Component/Form/Form.type';
import Loader from 'Component/Loader';
import { ReactElement } from 'Type/Common.type';
import { FieldOptions } from 'Type/Field.type';

import contractorStructureUpdateForm from './ContractorStructureUpdateForm.form';
import { ContractorStructureUpdateFormComponentProps } from './ContractorStructureUpdateForm.type';

import './ContractorStructureUpdateForm.style'

class ContractorStructureUpdateForm extends FieldForm<ContractorStructureUpdateFormComponentProps>  {
  fieldMap(): Partial<FieldContainerProps>[] {
    const { countries, substructures, structure } = this.props;

    const parentStructures: FieldOptions[] = (substructures || [])
      .filter(substructure => substructure.type === 'department')
      .map(substructure => ({ id: substructure.id, label: substructure.name, value: substructure.id }))
      .map(substructureOption => Number(substructureOption.value) !== structure.id ? substructureOption : {...substructureOption, selected: true })
    
    return contractorStructureUpdateForm(
      { countries, parentStructures }, 
      {
        name: structure.name ?? "",
        email: structure.email ?? "",
        parentId: structure.parent_id ?? NaN,
        country_id: structure.country ?? "",
        street: structure.street ?? "",
        phone: structure.phone ?? "",
        city: structure.city ?? "",
        postcode: structure.postcode ?? "",
        province: structure.province ?? "",
      }
    );
  }


  getFormProps(): Partial<FormContainerProps> {
    const { onFormError, onFormSubmit } = this.props;

    return {
        onError: onFormError,
        onSubmit: onFormSubmit,
    };
  }


  renderActions(): ReactElement {
    return <button type='submit'>Submit</button>
  }

  render(): ReactElement {
    const { isLoading } = this.props;

    return (
      <div block="ContractorStructureUpdateForm">
        {super.render()}
        <Loader isLoading={isLoading} />
      </div>
    )
  }
}

export default ContractorStructureUpdateForm;