import { FieldType } from 'Component/Field/Field.config';
import { FieldContainerProps, FieldInputCustomEvents } from 'Component/Field/Field.type';
import { FieldOptions } from 'Type/Field.type';
import { CountryOption } from 'Util/Address/Address.type';
import { ValidationInputType } from 'Util/Validator/Config';

/**
 * Form for contacts
 * @namespace PlugAndSell2/Component/ContractorStructureUpdateForm/Form/ContractorStructureUpdateForm */
export const contractorStructureUpdateForm = (
  { countries, parentStructures }: { countries: CountryOption[], parentStructures: FieldOptions[] },
  defaultValues: { 
    parentId: number, 
    name: string, 
    street: string,
    city: string, 
    postcode: string 
    phone: string,
    email: string,
    country_id: string,
    province: string
  }
): Partial<FieldContainerProps>[] => [
  {
    type: FieldType.SELECT,
    label: __('Parent structure'),
    attr: {
      id: 'parentId',
      name: 'parentId',
      defaultValue: parentStructures?.[0]?.value,
    },
    options: parentStructures
  },
  {
      type: FieldType.TEXT,
      label: __('Name'),
      attr: {
          name: 'name',
          defaultValue: defaultValues.name
      },
      addRequiredTag: true,
      validateOn: ['onChange'],
      validationRule: {
          isRequired: true,
      },
  },
  {
      type: FieldType.TEXT,
      label: __('Street'),
      attr: {
          name: 'street',
          defaultValue: defaultValues.street
      },
      addRequiredTag: true,
      validateOn: ['onChange'],
      validationRule: {
          isRequired: true,
      },
  },
  {
      type: FieldType.TEXT,
      label: __('City'),
      attr: {
          name: 'city',
          defaultValue: defaultValues.city
      },
      addRequiredTag: true,
      validateOn: ['onChange'],
      validationRule: {
          isRequired: true,
      },
  },
  {
    type: FieldType.TEXT,
    label: __('Postcode'),
    attr: {
        name: 'postcode',
        defaultValue: defaultValues.postcode
    },
    addRequiredTag: true,
    validateOn: ['onChange'],
    validationRule: {
        isRequired: true,
    },
  },
  {
    type: FieldType.TEXT,
    label: __('Phone'),
    attr: {
        name: 'phone',
        defaultValue: defaultValues.phone
    },
    addRequiredTag: true,
    validateOn: ['onChange'],
    validationRule: {
        isRequired: true,
    },
  },
  {
    type: FieldType.TEXT,
    label: __('Email'),
    attr: {
        name: 'email',
        defaultValue: defaultValues.email
    },
    addRequiredTag: true,
    validateOn: ['onChange'],
    validationRule: {
        isRequired: true,
    },
  },
  {
    type: FieldType.SELECT,
    label: __('Country'),
    attr: {
        id: 'country-id',
        name: 'country_id',
        defaultValue: defaultValues.country_id,
        selectPlaceholder: __('Select country...'),
    },
    options: countries,
    addRequiredTag: true,
    validateOn: ['onChange'],
    validationRule: {
        isRequired: true,
    },
  },
  {
    type: FieldType.TEXT,
    label: __('Province'),
    attr: {
        name: 'province',
        defaultValue: defaultValues.province
    },
    addRequiredTag: true,
    validateOn: ['onChange'],
    validationRule: {
        isRequired: true,
    },
  },
];

export default contractorStructureUpdateForm;
