import { PureComponent } from 'react';
import { RmaMyReturnsComponentProps } from './RmaMyReturns.type';
import { ReactElement } from 'Type/Common.type';
import RmaMyReturnsTable from '../RmaMyReturnsTable';

import './RmaMyReturns.style';
import RmaDetails from '../RmaDetails';
import { RmaDetailsScreenType } from '../RmaDetails/RmaDetails.type';

class RmaMyReturns extends PureComponent<RmaMyReturnsComponentProps> {
  renderDetails(): ReactElement {
    return (
      <RmaDetails type={RmaDetailsScreenType.OVERVIEW} />
    )
  }

  renderTable(): ReactElement {
    const { onViewClicked } = this.props;
    
    return (
      <RmaMyReturnsTable onViewClicked={onViewClicked} />
    )
  }

  render(): ReactElement {
    const { showDetails } = this.props;

    if (showDetails) {
      return this.renderDetails()
    }
    
    return (
      <div block="RmaMyReturns">
        {this.renderTable()}     
      </div>
    );
  }
}

export default RmaMyReturns;