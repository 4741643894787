import { PureComponent } from 'react';
import { Dispatch } from 'redux';
import { ContractorStructureDeleteComponentProps, ContractorStructureDeleteContainerFunctions, ContractorStructureDeleteContainerProps } from './ContractorStructureDelete.type';
import { showPopup } from 'Store/Popup/Popup.action';
import { connect } from 'react-redux';
import { CONTRACTOR_STRUCTURE_DELETE_POPUP_ID } from './ContractorStructureDelete.config';
import { fetchMutation } from 'Util/Request/Mutation';
import ContractorsQuery from '../../query/Contractors/Contractors.query';
import ContractorStructureDelete from './ContractorStructureDelete.component';
import { showNotification } from 'Store/Notification/Notification.action';
import { NotificationType } from 'Store/Notification/Notification.type';
import { getErrorMessage } from 'Util/Request/Error';
import { NetworkError } from 'Type/Common.type';
import { PopupPayloadDefault } from 'Store/Popup/Popup.type';

export const mapStateToProps = () => ({})

export const mapDispatchToProps = (dispatch: Dispatch) => ({
  showNotification: (type: NotificationType, msg: string) => dispatch(showNotification(type, msg)),
  showPopup: (id: string, payload?: PopupPayloadDefault) => dispatch(showPopup(id, payload)),
})

class ContractorStructureDeleteContainer extends PureComponent<ContractorStructureDeleteContainerProps> {
  containerFunctions(): ContractorStructureDeleteContainerFunctions {
    return ({
        handleDeleteStructureClick: this.handleDeleteStructureClick.bind(this),
        onConfirm: this.handleOnConfirm.bind(this),
        onDismiss: this.handleOnDismiss.bind(this)
    })
  }

  containerProps(): Pick<ContractorStructureDeleteComponentProps, 'structure'> {
    const { structure } = this.props

    return ({
      structure
    })
  }
  
  handleDeleteStructureClick(): void {
    const { showPopup, structure } = this.props;

    showPopup(`${CONTRACTOR_STRUCTURE_DELETE_POPUP_ID}-${structure.id}`, { title: 'Delete test' });
  }

  async handleOnConfirm(): Promise<void> {
    const { structure, showNotification, showPopup, onDeleted } = this.props
    
    try {
      await fetchMutation(ContractorsQuery.getContractorStructureDeleteMutation({ id: structure.id }))
      showPopup('')

      if (onDeleted) {
        onDeleted()
      }
    } catch (e) {
      showNotification(NotificationType.ERROR, getErrorMessage(e as NetworkError))
    }
  }

  handleOnDismiss(): void {
    const { showPopup } = this.props;

    showPopup('');
  }

  render() {
    return (
      <ContractorStructureDelete {...this.containerFunctions()} {...this.containerProps()} />
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ContractorStructureDeleteContainer);