import { format } from 'date-fns';
import { ChangeEvent, PureComponent } from 'react';

import Field from 'Component/Field';
import Form from 'Component/Form';
import history from 'Util/History';
import {
  clearQueriesFromUrl,
  convertQueryStringToKeyValuePairs,
  setQueryParams,
} from 'Util/Url';
import Icons from 'Component/Icons';
import { IconName } from 'Component/Icons/Icons.type';

import './Filters.style';
import { Filter, FiltersComponentProps, FiltersComponentState, PageParams } from './Filters.type';
import { FieldType } from 'Component/Field/Field.config';
import { isEmpty } from 'Util/Common';

/** @namespace PlugAndSell2/Component/Filters/Component/Filters */
export class Filters<T> extends PureComponent<FiltersComponentProps<T>, FiltersComponentState<T>> {
  static defaultProps = {
    filters: [],
    formProps: {},
  };

  state = {
    values: {},
    isOpen: true,
  };

  componentDidMount() {
    this.setInitValuesFromUrl();
  }

  __construct() {
    this.handleOnSuccess = this.handleOnSuccess.bind(this);
    this.handleReset = this.handleReset.bind(this);
    this.onFieldChanged = this.onFieldChanged.bind(this);
    this.pushToUrl = this.pushToUrl.bind(this);
    this.renderActions = this.renderActions.bind(this);
    this.renderApply = this.renderApply.bind(this);
    this.renderFilter = this.renderFilter.bind(this);
    this.renderFilters = this.renderFilters.bind(this);
  }

  clearForm() {
    this.setState({ values: {} });
    document.querySelector<HTMLFormElement>('.Filters form')?.reset();
  }

  getValueFromEvent(e: ChangeEvent<HTMLInputElement> | string) {
    return typeof e === "string" ? e : e?.target?.value
  }

  handleOnSuccess() {
    const { onChange } = this.props;
    const { values } = this.state;
    onChange(values);

    this.pushToUrl(values);
  }

  handleReset() {
    const { onChange } = this.props;

    clearQueriesFromUrl(history);
    onChange(null);
    this.clearForm();
  }

  onFieldChanged(key: string, value: string | number | { min: string, max: string }) {
    this.setState((state) => ({ values: { ...state.values, [key]: value } }));
  }

  pushToUrl(data: Record<string, string | { min: Date | number, max: Date | number }>) {
    const { filters } = this.props;

    Object.entries(data).forEach(([key, value]) => {
      if (
        filters.some(
          (filter) => filter.attr?.name === key && filter.type === FieldType.DATE
        )
      ) {
        if (typeof value === 'string') {
          data[key] = value;
        } else {
          const dateToString = (date: Date | number) => format(date, 'yyyy-MM-dd');
          data[key] = `${dateToString(value.min)} - ${dateToString(value.max)}`;
        }
      }
    });

    setQueryParams(data as Record<string, string>, history.location, history);
  }

  renderActions() {
    return (
      <div block="Filters" elem="Actions">
        {this.renderApply()}
      </div>
    );
  }

  renderApply() {
    return (
      <div block="Filters" elem="Confirm">
        <button className="Button" type="submit">
          {__('Apply filters')}
        </button>
      </div>
    );
  }

  renderFilter(filter: Partial<Filter>, key: number) {
    const { isMobile } = this.props;

    const { attr: { id = '' } = {} } = filter;
    const calendarIconVisible = ((id === 'doc_date' || id === 'payment_date') && !isMobile) || ((id === 'doc_date_to' || id === 'payment_date_to') && isMobile);


    if (isEmpty(filter)) {
      return null;
    }

    return (
      <Field
        key={`filter-input-${key}`}
        events={{
          onChange: (e: ChangeEvent<HTMLInputElement> | string) => { return this.onFieldChanged(filter?.attr?.id || '', this.getValueFromEvent(e)) }
        }}
        {...filter}
        calendarIconVisible={calendarIconVisible}
      />
    );
  }

  onToggle(value: boolean) {
    this.setState((prevState) => ({
      ...prevState,
      isOpen: value ?? !prevState.isOpen,
    }));
  }

  renderContent() {
    const { filters } = this.props;
    const { isOpen } = this.state;

    return (
      <>
        <div block="Filters" elem="Toggle">
          <button onClick={() => this.onToggle(!isOpen)} type="button">
            {isOpen ? __('Hide filters') : __('Show filters')}
            {isOpen ? <Icons name={IconName.DROPDOWN_ARROW_UP} /> : <Icons name={IconName.DROPDOWN_ARROW} />}
          </button>
        </div>
        <div block="Filters" elem="Collapse" mods={{ isOpen }}>
          <fieldset block="Filters" elem="Filter">
            {filters.map(this.renderFilter)}
          </fieldset>
        </div>
      </>
    );
  }

  renderFilters() {
    const { formProps, isMobile = false } = this.props;
    const { isOpen } = this.state;

    return (
      <Form
        key="filter-form"
        onSubmit={this.handleOnSuccess}
        {...formProps}
        mix={{ block: 'Filters', elem: 'Form' }}
      >
        <div block="Filters">
          <div block="Filters" elem="Content">
            {this.renderContent()}
          </div>
          {!isMobile || (isMobile && isOpen) ? (
            <div block="Filters" elem="Action">
              {this.renderActions()}
            </div>
          ) : null}
        </div>
      </Form>
    );
  }

  renderReset = () => (
    <span
      block="Button"
      elem="Reset"
      mods={{ likeLink: true }}
      onClick={this.handleReset}
    >
      <span />
      {__('Clear')}
    </span>
  );

  setInitValuesFromUrl = () => {
    const paramValue = convertQueryStringToKeyValuePairs(
      window.location.search
    ) as PageParams<T>;

    delete paramValue.page;
    this.setState({ values: paramValue });
  };

  render() {
    return <div block="Filters">{this.renderFilters()}</div>;
  }
}

export default Filters;
