import { Dispatch } from 'redux';

import WishlistQuery from 'Query/Wishlist.query';
import { Wishlist } from 'Query/Wishlist.type';
import CartDispatcher from 'Store/Cart/Cart.dispatcher';
import { showNotification } from 'Store/Notification/Notification.action';
import { NotificationType, ShowNotificationAction } from 'Store/Notification/Notification.type';
import { clearWishlist, removeItemFromWishlist, updateAllProductsInWishlist, updateIsLoading } from 'Store/Wishlist/Wishlist.action';
import { NetworkError } from 'Type/Common.type';
import { GQLWishlistItemInput, GQLWishlistItemUpdateInput } from 'Type/Graphql.type';
import { isSignedIn } from 'Util/Auth/IsSignedIn';
import { getAuthorizationToken } from 'Util/Auth/Token';
import { getErrorMessage } from 'Util/Request/Error';
import { fetchMutation } from 'Util/Request/Mutation';
import { fetchQuery } from 'Util/Request/Query';
import { getStoreState } from 'Util/Store';
import { getPriceRange } from 'Util/Wishlist';

import { ClearWishlistAction, WishlistProduct } from './Wishlist.type';

/**
 * Get wishlist setting.
 * @namespace PlugAndSell2/Store/Wishlist/Dispatcher/isWishlistEnabled */
export const isWishlistEnabled = (): boolean => {
    const state = getStoreState();
    const { wishlist_general_active = false } = state.ConfigReducer || {};

    return wishlist_general_active;
};

/**
 * Product Wishlist Dispatcher
 * @class WishlistDispatcher
 * @namespace PlugAndSell2/Store/Wishlist/Dispatcher */
export class WishlistDispatcher {
    updateInitialWishlistData(dispatch: Dispatch): void {
        if (isSignedIn() && isWishlistEnabled()) {
            this._syncWishlistWithBE(dispatch);
        } else {
            dispatch(updateAllProductsInWishlist({}));
        }
    }

    _syncWishlistWithBE(dispatch: Dispatch): Promise<void> {
        // Need to get current wishlist from BE, update wishlist
        return fetchQuery<'wishlist', Wishlist>(WishlistQuery.getWishlistQuery()).then(
            /** @namespace PlugAndSell2/Store/Wishlist/Dispatcher/WishlistDispatcher/_syncWishlistWithBE/fetchQuery/then */
            (data: { wishlist: Wishlist }) => {
                if (!getAuthorizationToken()) {
                    return;
                }

                if (data && data.wishlist) {
                    const { wishlist } = data;
                    const productsToAdd = wishlist?.items.reduce((prev: Record<string, WishlistProduct>, wishlistItem) => {
                        const { id, sku, product, description, price, price_without_tax, buy_request, options, qty: quantity } = wishlistItem;

                        const { price_range: { minimum_price: { discount = 0 } = {} } = {} } = product;

                        const priceRange = getPriceRange(product, price, price_without_tax, discount);

                        const result: WishlistProduct = {
                            ...product,
                            ...priceRange,
                            quantity,
                            wishlist: {
                                id,
                                sku,
                                quantity,
                                description,
                                buy_request,
                                options,
                            },
                        };

                        return {
                            ...prev,
                            [id]: result,
                        };
                    }, {});

                    dispatch(updateAllProductsInWishlist(productsToAdd));
                } else {
                    dispatch(updateIsLoading(false));
                }
            },
            /** @namespace PlugAndSell2/Store/Wishlist/Dispatcher/WishlistDispatcher/_syncWishlistWithBE/fetchQuery/then/catch */
            () => {
                dispatch(updateIsLoading(false));
            }
        );
    }

    async addItemToWishlist(dispatch: Dispatch, options: { items: GQLWishlistItemInput[]; wishlistId: string }): Promise<void> {
        if (!isSignedIn()) {
            return;
        }

        try {
            const { items = [], wishlistId = '' } = options;

            dispatch(updateIsLoading(true));
            const {
                addProductsToWishlist: { user_errors },
            } = await fetchMutation(WishlistQuery.addProductsToWishlist(wishlistId, items));

            if (user_errors.length > 0) {
                user_errors.map(({ message }: NetworkError) =>
                    dispatch(showNotification(NotificationType.ERROR, __('We can`t add the item to Wishlist right now: %s', message).toString()))
                );
            } else {
                dispatch(showNotification(NotificationType.SUCCESS, __('Product added to wish-list!')));
                await this._syncWishlistWithBE(dispatch);
            }
        } catch {
            dispatch(showNotification(NotificationType.ERROR, __('Error updating wish list!')));
        } finally {
            dispatch(updateIsLoading(false));
        }
    }

    updateWishlistItem(dispatch: Dispatch, options: { wishlistItems: GQLWishlistItemUpdateInput[]; wishlistId: string }): Promise<void> {
        if (!isSignedIn()) {
            return Promise.reject();
        }

        const { wishlistItems = [], wishlistId = '' } = options;

        return fetchMutation(WishlistQuery.updateProductsInWishlist(wishlistId, wishlistItems)).then(
            /** @namespace PlugAndSell2/Store/Wishlist/Dispatcher/WishlistDispatcher/updateWishlistItem/fetchMutation/then */
            () => {
                this._syncWishlistWithBE(dispatch);
            }
        );
    }

    clearWishlist(dispatch: Dispatch): Promise<ClearWishlistAction | ShowNotificationAction> {
        if (!isSignedIn()) {
            return Promise.reject();
        }

        return fetchMutation<'clearWishlist', boolean>(WishlistQuery.getClearWishlist())
            .then(
                /** @namespace PlugAndSell2/Store/Wishlist/Dispatcher/WishlistDispatcher/clearWishlist/then/catch/fetchMutation/then/dispatch */
                () => dispatch(clearWishlist())
            )
            .catch(
                /** @namespace PlugAndSell2/Store/Wishlist/Dispatcher/WishlistDispatcher/clearWishlist/then/catch/dispatch */
                () => dispatch(showNotification(NotificationType.ERROR, __('Error clearing wish list!')))
            );
    }

    async moveWishlistToCart(dispatch: Dispatch, sharingCode = ''): Promise<void> {
        if (!isSignedIn()) {
            await Promise.reject();
        }

        try {
            await fetchMutation<'moveWishlistToCart', boolean>(WishlistQuery.getMoveWishlistToCart(sharingCode));
        } finally {
            await this._syncWishlistWithBE(dispatch);
            CartDispatcher.updateInitialCartData(dispatch, !!getAuthorizationToken());
            dispatch(showNotification(NotificationType.SUCCESS, __('Available items moved to cart')));
        }
    }

    async removeItemFromWishlist(dispatch: Dispatch, { item_id, noMessages }: { item_id: string; noMessages?: boolean }): Promise<void> {
        if (!item_id || !isSignedIn()) {
            return Promise.reject();
        }

        dispatch(updateIsLoading(true));

        try {
            await fetchMutation(WishlistQuery.getRemoveProductFromWishlistMutation(item_id));
        } catch (e) {
            if (!noMessages) {
                dispatch(showNotification(NotificationType.ERROR, __('Error updating wish list!')));
            }

            return Promise.reject();
        }

        dispatch(removeItemFromWishlist(item_id));

        if (!noMessages) {
            dispatch(showNotification(NotificationType.SUCCESS, __('Product has been removed from your Wish List!')));
        }

        return Promise.resolve();
    }

    // TODO: Need to make it in one request
    removeItemsFromWishlist(dispatch: Dispatch, itemIdMap: string[]): Promise<never> | Promise<void>[] {
        if (!itemIdMap.length || !isSignedIn()) {
            return Promise.reject();
        }

        return itemIdMap.map((id) =>
            fetchMutation<'removeProductFromWishlist', boolean>(WishlistQuery.getRemoveProductFromWishlistMutation(id)).then(
                /** @namespace PlugAndSell2/Store/Wishlist/Dispatcher/WishlistDispatcher/removeItemsFromWishlist/itemIdMap/map/fetchMutation/then */
                () => {
                    dispatch(removeItemFromWishlist(id));
                    dispatch(showNotification(NotificationType.SUCCESS, __('Product has been removed from your Wish List!')));
                },
                /** @namespace PlugAndSell2/Store/Wishlist/Dispatcher/WishlistDispatcher/removeItemsFromWishlist/itemIdMap/map/fetchMutation/then/catch */
                (error) => {
                    dispatch(showNotification(NotificationType.ERROR, getErrorMessage(error, __('Error updating wishlist!'))));
                }
            )
        );
    }

    resetWishlist(dispatch: Dispatch): void {
        dispatch(clearWishlist());
    }
}

export default new WishlistDispatcher();
