/* eslint-disable no-magic-numbers */

export const AMPM_FORMAT = {
    AM: __('AM'),
    PM: __('PM'),
};

export enum HourFormat {
    H12 = 12,
    H24 = 24,
}

export enum DateFieldAttr {
    NAME = 'data-field',
    TYPE = 'data-type',
}

export const DEFAULT_MONTH_DAYS = 31;
export const MONTHS_COUNT = 12;
export const MINUTES_COUNT = 60;
