import Field from 'Component/Field';
import { FieldType } from 'Component/Field/Field.config';
import { CountryOption } from 'Util/Address/Address.type';
import { PureComponent } from 'react';
 
export class CreateAccountAddressFields extends PureComponent<{ countriesOptions: CountryOption[] }> {
  render () {
    const { countriesOptions } = this.props;

    return (
        <fieldset block="MyAccountCreateAccount" elem="Legend" mods={{ address: true }}>
            <Field
                type={FieldType.TEXT}
                label={__('Postal code')}
                attr={{
                    id: 'postal-code',
                    name: 'postal-code',
                    placeholder: __('Postal code'),
                }}
                validateOn={['onChange']}
                validationRule={{
                    isRequired: true,
                }}
                addRequiredTag
            />
            <Field
                type={FieldType.TEXT}
                label={__('City')}
                attr={{
                    id: 'city',
                    name: 'city',
                    placeholder: __('City'),
                }}
                validateOn={['onChange']}
                validationRule={{
                    isRequired: true,
                }}
                addRequiredTag
            />
            <Field
                type={FieldType.TEXT}
                label={__('Street address')}
                attr={{
                    id: 'street-address',
                    name: 'street-address',
                    placeholder: __('Street address'),
                }}
                validateOn={['onChange']}
                validationRule={{
                    isRequired: true,
                }}
                addRequiredTag
                wrapperMix={{ mods: { street_address: true } }}
            />
            <Field
                type={FieldType.TEXT}
                label={__('Street address 2')}
                attr={{
                    id: 'street-address-2',
                    name: 'street-address-2',
                    placeholder: __('Street address 2'),
                }}
                validateOn={['onChange']}
            />
            <Field
                type={FieldType.TEXT}
                label={__('Street address 3')}
                attr={{
                    id: 'street-address-3',
                    name: 'street-address-3',
                    placeholder: __('Street address 3'),
                }}
                validateOn={['onChange']}
            />
            <Field
                type={FieldType.SELECT}
                label={__('Country')}
                options={countriesOptions}
                attr={{
                    id: 'country',
                    name: 'country',
                    placeholder: __('Country'),
                }}
                validateOn={['onChange']}
                validationRule={{
                    isRequired: true,
                }}
                addRequiredTag
                wrapperMix={{ mods: { country: true } }}
            />
            <Field
                type={FieldType.TEXT}
                label={__('Phone number')}
                attr={{
                    id: 'phone-number',
                    name: 'phone-number',
                    placeholder: __('Phone number'),
                }}
                validateOn={['onChange']}
                validationRule={{
                    isRequired: true,
                }}
                addRequiredTag
                wrapperMix={{ mods: { phone: true } }}
            />
        </fieldset>
    );
  }
}

export default {
  CreateAccountAddressFields
}