import { PureComponent } from 'react';

import DiscountLabel from 'Component/DiscountLabel';
import { ProductType } from 'Component/Product/Product.config';
import TextPlaceholder from 'Component/TextPlaceholder';
import { TextPlaceHolderLength } from 'Component/TextPlaceholder/TextPlaceholder.config';
import { ReactElement } from 'Type/Common.type';
import { GQLCurrencyEnum } from 'Type/Graphql.type';
import { roundPercentage } from 'Util/Percentage/Percentage';
import { FormattedMoney } from 'Util/Product/Product.type';

import { DisplayProductPricesInCatalog } from './ProductPrice.config';
import { CurrencySchema, PriceSchema, ProductPriceComponentProps } from './ProductPrice.type';

import './ProductPrice.style';

/**
 * Product price
 * @class ProductPrice
 * @namespace PlugAndSell2/Component/ProductPrice/Component */
export class ProductPriceComponent extends PureComponent<ProductPriceComponentProps> {
    static defaultProps: Partial<ProductPriceComponentProps> = {
        price: {},
        priceType: ProductType.SIMPLE,
        originalPrice: {},
        priceCurrency: GQLCurrencyEnum.USD,
        discountPercentage: 0,
        isPreview: false,
        isSchemaRequired: false,
        variantsCount: 0,
        mix: {},
        tierPrice: {
            currency: GQLCurrencyEnum.USD,
            value: '',
            valueFormatted: '',
        },
        label: '',
        configuration: {},
        displayTaxInPrice: DisplayProductPricesInCatalog.INCL_TAX,
    };

    pricePreviewRenderMap = {
        [ProductType.SIMPLE]: this.renderDefaultPrice.bind(this),
        [ProductType.VIRTUAL]: this.renderDefaultPrice.bind(this),
        [ProductType.BUNDLE]: this.renderBundlePrice.bind(this),
        [ProductType.GROUPED]: this.renderGroupedPrice.bind(this),
        [ProductType.DOWNLOADABLE]: this.renderDefaultPrice.bind(this),
        [ProductType.CONFIGURABLE]: this.renderConfigurablePrice.bind(this),
    };

    priceLabelTypeMap = {
        [ProductType.SIMPLE]: __('From'),
        [ProductType.VIRTUAL]: __('From'),
        [ProductType.BUNDLE]: __('From'),
        [ProductType.GROUPED]: __('From'),
        [ProductType.DOWNLOADABLE]: __('From'),
        [ProductType.CONFIGURABLE]: __('From'),
    };

    renderPriceWithTax(basePrice: Partial<FormattedMoney>, taxPrice: Partial<FormattedMoney>, label?: string | ReactElement): ReactElement {
        return <>{this.renderPrice(basePrice, label)}</>;
    }

    renderPlaceholder(): ReactElement {
        const { mix } = this.props;

        return (
            <p block="ProductPrice" aria-label="Product Price" mix={mix}>
                <TextPlaceholder mix={{ block: 'ProductPrice', elem: 'Placeholder' }} length={TextPlaceHolderLength.CUSTOM} />
            </p>
        );
    }

    getCurrencySchema(): Partial<CurrencySchema> {
        const { isSchemaRequired, priceCurrency } = this.props;

        return isSchemaRequired ? { itemProp: 'priceCurrency', content: priceCurrency } : {};
    }

    getCurrentPriceSchema(): Partial<PriceSchema> {
        const { isSchemaRequired, variantsCount, price: { finalPrice: { value: contentPrice = 0 } = {} } = {} } = this.props;

        if (variantsCount > 1) {
            return isSchemaRequired ? { itemProp: 'lowPrice', content: contentPrice } : {};
        }

        return isSchemaRequired ? { itemProp: 'price', content: contentPrice } : {};
    }

    renderLowestPrice(): ReactElement {
        const {
            price: { lowestPrice: { omnibus_text, value_incl_tax: formattedValueInclTax } = {} },
        } = this.props;

        if (!omnibus_text || !formattedValueInclTax) {
            return null;
        }

        return (
            <div block="ProductPrice" elem="LowestPrice">
                <span block="ProductPrice" elem="LowestPriceText">
                    {omnibus_text}
                </span>
                <span block="ProductPrice" elem="LowestPriceValue">
                    {' '}
                    {formattedValueInclTax}
                </span>
            </div>
        );
    }

    renderPrice(price: Partial<FormattedMoney>, label: string | ReactElement): ReactElement {
        const { discountPercentage, unit } = this.props;

        const { value: priceValue, valueFormatted: priceFormatted = 0 } = price;

        const { itemProp, content } = this.getCurrentPriceSchema();

        if (!priceValue && priceValue !== 0) {
            return null;
        }

        return (
            <span block="ProductPrice" elem="Price">
                {this.renderPriceBadge(label)}
                <span itemProp={itemProp} block="ProductPrice" elem="PriceValue" mods={{ isSpecialPrice: !!discountPercentage }}>
                    <meta itemProp={itemProp} content={String(content)} />
                    {priceFormatted}
                    {unit ? <span>{` / ${unit}`}</span> : null}
                </span>
                {this.renderLowestPrice()}
            </span>
        );
    }

    renderPriceBadge(label: string | ReactElement): ReactElement {
        if (!label) {
            return null;
        }

        return <span mix={{ block: 'ProductPrice', elem: 'PriceBadge' }}>{label}</span>;
    }

    renderSubPrice(price: Partial<FormattedMoney>): ReactElement {
        const { value: priceExclTax = 0, valueFormatted: priceExclTaxFormatted = 0 } = price;

        if (!priceExclTax && priceExclTax !== 0) {
            return null;
        }

        return (
            <span aria-label={__('Current product price excl. tax')} block="ProductPrice" elem="SubPrice">
                {__('Excl. tax: %s', priceExclTaxFormatted)}
            </span>
        );
    }

    renderOldPrice(): ReactElement {
        const {
            price: { originalPrice: { value: originalPriceValue, valueFormatted: originalPriceFormatted } = {} } = {},
            discountPercentage,
            isSchemaRequired,
            variantsCount,
        } = this.props;

        if (discountPercentage === 0 || originalPriceValue === 0) {
            return null;
        }

        return (
            <>
                {this.renderDiscountLabel()}
                <span
                    block="ProductPrice"
                    elem="HighPrice"
                    aria-label={__('Old product price')}
                    itemProp={isSchemaRequired && variantsCount > 1 ? 'highPrice' : undefined}
                >
                    {originalPriceFormatted}
                </span>
            </>
        );
    }

    renderSchema(): ReactElement {
        const { isSchemaRequired } = this.props;

        if (isSchemaRequired) {
            const { itemProp, content } = this.getCurrencySchema();

            return <meta itemProp={itemProp} content={content} />;
        }

        return null;
    }

    renderRequiredWithChangePrice(): ReactElement {
        const { configuration: { containsRequiredOptionsWithPrice = false } = {}, priceType } = this.props;

        const { [priceType]: label } = this.priceLabelTypeMap;

        return (
            <>
                {label && containsRequiredOptionsWithPrice && this.renderPriceBadge(label)}
                {this.renderDefaultPrice()}
            </>
        );
    }

    renderBundlePrice(): ReactElement {
        const {
            originalPrice: {
                minFinalPrice = {} as FormattedMoney,
                minFinalPrice: { value: minValue = 0 } = {},
                minFinalPriceExclTax = {} as FormattedMoney,
                minRegularPrice = {} as FormattedMoney,
                minRegularPrice: { value: minRegularValue = 0 } = {},
            },
        } = this.props;

        const renderer = minValue === 0 ? this.renderDefaultPrice() : this.renderPriceWithOrWithoutTax(minFinalPrice, minFinalPriceExclTax);

        return (
            <>
                {minValue < minRegularValue && this.renderRegularPrice(minRegularPrice)}
                {renderer}
            </>
        );
    }

    renderRegularPrice(price: FormattedMoney): ReactElement {
        const { value, valueFormatted } = price;

        if (!value || value <= 0 || !valueFormatted) {
            return null;
        }

        return (
            <span block="ProductPrice" elem="HighPrice">
                {valueFormatted}
            </span>
        );
    }

    renderDiscountLabel(): ReactElement {
        const { discountPercentage, noLabel } = this.props;

        if (noLabel) {
            return null;
        }

        return <DiscountLabel value={roundPercentage(discountPercentage)} />;
    }

    renderGroupedPrice(): ReactElement {
        const {
            originalPrice: { minFinalPrice = {}, minFinalPriceExclTax = {} },
            priceType,
        } = this.props;
        const { [priceType]: label } = this.priceLabelTypeMap;

        return this.renderPriceWithOrWithoutTax(minFinalPrice, minFinalPriceExclTax, label);
    }

    renderCustomisablePrice(): ReactElement {
        return this.renderDefaultPrice();
    }

    renderConfigurablePrice(): ReactElement {
        const {
            configuration: { containsOptions = false } = {},
            price: { finalPriceExclTax = {}, finalPrice = {} },
            priceType,
        } = this.props;

        if (!containsOptions) {
            return this.renderDefaultPrice();
        }

        const { [priceType]: label } = this.priceLabelTypeMap;

        return this.renderPriceWithOrWithoutTax(finalPrice, finalPriceExclTax, label);
    }

    renderDefaultPrice(defaultLabel: string | null = null): ReactElement {
        const { price: { finalPrice = {}, finalPriceExclTax = {} } = {}, label } = this.props;

        return (
            <>
                {this.renderOldPrice()}
                {this.renderPriceWithOrWithoutTax(finalPrice, finalPriceExclTax, defaultLabel || label)}
                {this.renderSchema()}
            </>
        );
    }

    renderPriceWithOrWithoutTax(basePrice: Partial<FormattedMoney>, taxPrice: Partial<FormattedMoney>, label?: string | ReactElement): ReactElement {
        const { displayTaxInPrice } = this.props;

        if (displayTaxInPrice === DisplayProductPricesInCatalog.INCL_TAX) {
            return this.renderPrice(basePrice, label);
        }

        if (displayTaxInPrice === DisplayProductPricesInCatalog.EXCL_TAX) {
            return this.renderPrice(taxPrice, label);
        }

        return (
            <>
                {this.renderPrice(basePrice, label)}
                {this.renderSubPrice(taxPrice)}
            </>
        );
    }

    renderTierPrice(): ReactElement {
        const {
            tierPrice: { valueFormatted: tierPriceFormatted, value: tierPriceValue },
            price: { finalPrice: { value } = {} } = {},
        } = this.props;

        if (!tierPriceFormatted || tierPriceValue >= (value || 0)) {
            return null;
        }

        return (
            <p block="ProductPrice" elem="TierPrice">
                {__('As low as')}
                <strong>{` ${tierPriceFormatted}`}</strong>
            </p>
        );
    }

    render(): ReactElement {
        const {
            price: { finalPrice, originalPrice, finalPrice: { value: finalPriceValue = 0 } = {} } = {},
            priceType,
            isPreview,
            discountPercentage,
            mix,
        } = this.props;

        if (!finalPrice || !originalPrice) {
            return this.renderPlaceholder();
        }

        const { [priceType]: renderer } = this.pricePreviewRenderMap;

        return (
            <p
                block="ProductPrice"
                mods={{ hasDiscount: discountPercentage !== 0, isPreview }}
                mix={mix}
                aria-label={`Product price: ${finalPriceValue}`}
            >
                {isPreview && renderer && renderer()}
                {(!isPreview || !renderer) && this.renderDefaultPrice()}
                {priceType !== ProductType.BUNDLE && this.renderTierPrice()}
            </p>
        );
    }
}

export default ProductPriceComponent;
