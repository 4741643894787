/* eslint-disable spaced-comment,@scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
import { ButtonHTMLAttributes, InputHTMLAttributes, PureComponent, SelectHTMLAttributes, TextareaHTMLAttributes } from 'react';

import FieldFile from 'Component/FieldFile';
import { FieldNumberWithControlsContainer } from 'Component/FieldNumberWithControls/FieldNumberWithControls.container';
import FieldSelect from 'Component/FieldSelect';
import Icons from 'Component/Icons';
import { IconName } from 'Component/Icons/Icons.type';
import { ReactElement } from 'Type/Common.type';
import { noopFn } from 'Util/Common';

import { FieldType } from './Field.config';
import {
    FieldComponentProps,
    FieldEvents,
    FieldInputCustomEvents,
    FieldNumberCustomEvents,
    FieldReactEvents,
    FieldSelectCustomEvents,
    FieldSize,
    FieldVariant,
} from './Field.type';

import './Field.style';

/**
 * Field
 * @class Field
 * @namespace PlugAndSell2/Component/Field/Component */
export class FieldComponent extends PureComponent<FieldComponentProps> {
    static defaultProps: Partial<FieldComponentProps> = {
        validationResponse: null,
        changeValueOnDoubleClick: false,
        isSortSelect: false,
        withErrorMessage: false,
        variant: FieldVariant.PRIMARY,
        size: FieldSize.NORMAL,
    };

    renderMap = {
        // Checkboxes & Radio
        [FieldType.RADIO]: this.renderCheckboxOrRadio.bind(this),
        [FieldType.CHECKBOX]: this.renderCheckboxOrRadio.bind(this),
        [FieldType.MULTI]: this.renderCheckboxOrRadio.bind(this),

        // Default input
        [FieldType.EMAIL]: this.renderDefaultInput.bind(this),
        [FieldType.TEXT]: this.renderDefaultInput.bind(this),
        [FieldType.TIME]: this.renderDefaultInput.bind(this),
        [FieldType.DATETIME]: this.renderDefaultInput.bind(this),
        [FieldType.DATE]: this.renderDefaultInput.bind(this),
        [FieldType.PASSWORD]: this.renderDefaultInput.bind(this),
        [FieldType.SUBMIT]: this.renderDefaultInput.bind(this),
        [FieldType.TEL]: this.renderDefaultInput.bind(this),
        [FieldType.NUMBER]: this.renderDefaultInput.bind(this),

        // Custom fields
        [FieldType.FILE]: this.renderFile.bind(this),
        [FieldType.SELECT]: this.renderSelect.bind(this),
        [FieldType.TEXTAREA]: this.renderTextArea.bind(this),
        [FieldType.BUTTON]: this.renderButton.bind(this),
        [FieldType.NUMBER_WITH_CONTROLS]: this.renderNumberWithControls.bind(this),
    };

    //#region INPUT TYPE RENDER
    renderDefaultInput(): ReactElement {
        const { type, setRef, attr, events, isDisabled } = this.props;

        return (
            <>
                {this.renderCalendarIcon()}
                <input
                    ref={(elem) => setRef(elem)}
                    disabled={isDisabled}
                    type={type}
                    {...(attr as InputHTMLAttributes<HTMLInputElement>)}
                    {...(events as FieldReactEvents<HTMLInputElement>)}
                />
            </>
        );
    }

    renderCalendarIcon() {
        const { calendarIconVisible } = this.props;

        if (!calendarIconVisible) {
            return null;
        }

        return <Icons name={IconName.CALENDAR_DAY} />;
    }

    renderFile(): ReactElement {
        const { attr, events, setRef, validate, resetFieldValue, resetFieldDisabled } = this.props;

        return (
            <FieldFile
                attr={attr as InputHTMLAttributes<HTMLInputElement>}
                events={events as Omit<FieldEvents, 'onChange'> & FieldInputCustomEvents}
                setRef={setRef}
                validate={validate}
                resetFieldValue={resetFieldValue}
                resetFieldDisabled={resetFieldDisabled}
            />
        );
    }

    renderNumberWithControls(): ReactElement {
        const { attr, events, setRef, value, isDisabled = false } = this.props;

        return (
            <FieldNumberWithControlsContainer
                value={value}
                attr={attr as InputHTMLAttributes<HTMLInputElement>}
                events={events as Omit<FieldEvents, 'onChange'> & FieldNumberCustomEvents}
                setRef={setRef}
                isDisabled={isDisabled}
            />
        );
    }

    renderSelect(): ReactElement {
        const { attr, events, setRef, options, isDisabled = false, changeValueOnDoubleClick, isSortSelect } = this.props;

        return (
            <FieldSelect
                attr={attr as SelectHTMLAttributes<HTMLSelectElement>}
                events={events as Omit<FieldEvents, 'onChange'> & FieldSelectCustomEvents}
                options={options}
                setRef={setRef}
                isDisabled={isDisabled}
                isSortSelect={isSortSelect}
                changeValueOnDoubleClick={changeValueOnDoubleClick}
            />
        );
    }

    renderButton(): ReactElement {
        const { setRef, attr, events, isDisabled } = this.props;
        const { value = __('Submit') } = attr;

        return (
            <button
                ref={(elem) => setRef(elem)}
                disabled={isDisabled}
                {...(attr as ButtonHTMLAttributes<HTMLButtonElement>)}
                {...(events as FieldReactEvents<HTMLButtonElement>)}
            >
                {value}
            </button>
        );
    }

    renderCheckboxOrRadio(): ReactElement {
        const {
            type,
            setRef,
            attr: { defaultChecked = false, ...newAttr } = {},
            events: { onChange },
            events,
            isDisabled,
            isSwitch,
            label,
        } = this.props;

        const { id = '', checked, value = '' } = newAttr as InputHTMLAttributes<HTMLInputElement>;
        const elem = type.charAt(0).toUpperCase() + type.slice(1);
        const inputEvents = {
            ...events,
            onChange: onChange || noopFn,
        };
        // if button value is "none" do not disable
        const isButtonDisabled = !String(value).match('none') && isDisabled;
        const isChecked = checked || (isButtonDisabled || defaultChecked ? !isDisabled : null);

        if (isSwitch) {
            return (
                <label htmlFor={id} block="Field" elem={`${elem}Label`}>
                    <span>{label}</span>
                    <input
                        // @ts-ignore
                        ref={(elem) => setRef(elem)}
                        disabled={isDisabled}
                        type={type}
                        {...newAttr}
                        {...inputEvents}
                        defaultChecked={!!isChecked}
                    />
                    <div block="Field" elem="Slider" />
                </label>
            );
        }

        return (
            <label htmlFor={id} block="Field" elem={`${elem}Label`} mods={{ disabled: isDisabled, checked }}>
                <input
                    ref={(elem) => setRef(elem)}
                    disabled={isButtonDisabled ? isDisabled : false}
                    type={type}
                    {...(newAttr as InputHTMLAttributes<HTMLInputElement>)}
                    {...(inputEvents as FieldReactEvents<HTMLInputElement>)}
                    // shipping options have checked attr assigned so prioritize its value
                    defaultChecked={!!isChecked}
                />
                <div block="input-control" mods={{ disabled: isButtonDisabled ? isDisabled : false }} />
                <div block="Field" elem={`${elem}LabelContent`}>
                    {label}
                </div>
            </label>
        );
    }

    renderTextArea(): ReactElement {
        const { setRef, attr, events, isDisabled } = this.props;

        return (
            <textarea
                ref={(elem) => setRef(elem)}
                {...(attr as TextareaHTMLAttributes<HTMLTextAreaElement>)}
                disabled={isDisabled}
                {...(events as FieldReactEvents<HTMLTextAreaElement>)}
            />
        );
    }
    //#endregion

    //#region LABEL/TEXT RENDER
    // Renders validation error messages under field
    renderErrorMessage(message: string, key: string): ReactElement {
        return (
            <div block="Field" elem="ErrorMessage" key={key}>
                {message}
            </div>
        );
    }

    renderErrorMessages(): ReactElement {
        const {
            showErrorAsLabel,
            validationResponse,
            attr: { name },
        } = this.props;

        if (!showErrorAsLabel || !validationResponse || validationResponse === true) {
            return null;
        }

        const { errorMessages = [] } = validationResponse;

        if (!errorMessages || !name) {
            return null;
        }

        return (
            <div block="Field" elem="ErrorMessages">
                {errorMessages.map((message: string, index: number) => this.renderError.call(this, message, name + index))}
            </div>
        );
    }

    // Renders fields label above field
    renderLabel(): ReactElement {
        const { type, label, attr: { name = '' } = {} } = this.props;

        if (!label) {
            return null;
        }

        return (
            <div block="Field" elem="LabelContainer">
                <label block="Field" elem="Label" htmlFor={name || `input-${type}`}>
                    {`${label}:`}
                    {this.renderRequiredTag()}
                </label>
            </div>
        );
    }

    // Renders * for required fields
    renderRequiredTag(): ReactElement {
        const { addRequiredTag } = this.props;

        if (!addRequiredTag) {
            return null;
        }

        return (
            <span block="Field" elem="Label" mods={{ isRequired: true }}>
                {' *'}
            </span>
        );
    }

    // Renders fields label under field
    renderSubLabel(): ReactElement {
        const { subLabel } = this.props;

        if (!subLabel) {
            return null;
        }

        return (
            <div block="Field" elem="SubLabelContainer">
                <div block="Field" elem="SubLabel">
                    {subLabel}
                </div>
            </div>
        );
    }
    //#endregion

    renderError(message: string, key: string): ReactElement {
        return (
            <div block="Field" elem="ErrorMessage" key={key}>
                {message}
            </div>
        );
    }

    render(): ReactElement {
        const { type, validationResponse, mix, variant, size, withErrorMessage, wrapperMix } = this.props;
        const inputRenderer = this.renderMap[type] ?? this.renderDefaultInput.bind(this);
        const { mods: { hasError = false } = {} } = mix;

        return (
            <div block="Field" elem="Wrapper" mods={{ type, ...wrapperMix?.mods }}>
                <div
                    block="Field"
                    mods={{
                        type,
                        isValid: !hasError && validationResponse === true,
                        hasError: validationResponse !== true && Object.keys(validationResponse || {}).length !== 0,
                        variant,
                        size,
                    }}
                    mix={mix}
                >
                    {type !== FieldType.CHECKBOX && type !== FieldType.RADIO && this.renderLabel()}
                    {inputRenderer && inputRenderer()}
                    {withErrorMessage ? this.renderErrorMessages() : null}
                </div>
                {this.renderSubLabel()}
            </div>
        );
    }
}

export default FieldComponent;
