import { ElementType } from "react"
import FinancialDocs from "../components/FinancialDocs"

export const CONTRACTOR_DOCS_TAB_ID = 'contractor-docs'

export const MyAccountTabList_containerProps = (originalMember: Object) => {
  return ({
    ...originalMember,
    [CONTRACTOR_DOCS_TAB_ID]: {
      url: '/contractor-docs',
      tabName: __('Billing'),
      isFullUrl: true,
    }
  })
}

export const MyAccountComponent_renderMap = (originalMember: Record<string, ElementType>) => {
  return ({
    ...originalMember,
    'contractor-docs': () => <FinancialDocs />,
  })
}

export default {
  'PlugAndSell2/Route/MyAccount/Container': {
    'static-member': {
      tabMap: MyAccountTabList_containerProps
    }
  },
  'PlugAndSell2/Route/MyAccount/Component': {
    'member-property': {
      renderMap: MyAccountComponent_renderMap
    }
  },
};
